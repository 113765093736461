import React, { useMemo } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { isMobileAtom } from '../../atoms/events';
import styles from './style';

const Terms = React.memo(() => {
  const [isMobile] = useAtom(isMobileAtom);
  const { hostname } = window.location;
  const { t } = useTranslation();

  const termsContentTitle = t('termsContent.title').replace('soskills.io', hostname);
  const termsContentArticle1Title = t('termsContent.article1.title');
  const termsContentArticle1Content1 = t('termsContent.article1.content1');
  const termsContentArticle1Content2 = t('termsContent.article1.content2');
  const termsContentArticle2Title = t('termsContent.article2.title');
  const termsContentArticle2Content1 = t('termsContent.article2.content1').replace('soskills.io', hostname);
  const termsContentArticle2Content2 = t('termsContent.article2.content2').replace('soskills.io', hostname);
  const termsContentArticle2Content3 = t('termsContent.article2.content3').replace('soskills.io', hostname);
  const termsContentArticle2Ul1 = t('termsContent.article2.ul1');
  const termsContentArticle2Ul2 = t('termsContent.article2.ul2');
  const termsContentArticle3Title = t('termsContent.article3.title');
  const termsContentArticle3Content1 = t('termsContent.article3.content1').replace('soskills.io', hostname);
  const termsContentArticle3Content2 = t('termsContent.article3.content2');
  const termsContentArticle3Content3 = t('termsContent.article3.content3');
  const termsContentArticle3Content4 = t('termsContent.article3.content4');
  const termsContentArticle3_1Title = t('termsContent.article3#1.title').replace('soskills.io', hostname);
  const termsContentArticle3_1Content1 = t('termsContent.article3#1.content1').replace('soskills.io', hostname);
  const termsContentArticle3_1Ul1 = t('termsContent.article3#1.ul1');
  const termsContentArticle3_1Ul2 = t('termsContent.article3#1.ul2');
  const termsContentArticle3_1Ul3 = t('termsContent.article3#1.ul3');
  const termsContentArticle3_1Ul4 = t('termsContent.article3#1.ul4');
  const termsContentArticle3_1Ul5 = t('termsContent.article3#1.ul5');
  const termsContentArticle3_1Ul6 = t('termsContent.article3#1.ul6');
  const termsContentArticle3_2Title = t('termsContent.article3#2.title');
  const termsContentArticle3_2Content1 = t('termsContent.article3#2.content1');
  const termsContentArticle3_2Ul1 = t('termsContent.article3#2.ul1');
  const termsContentArticle3_2Ul2 = t('termsContent.article3#2.ul2');
  const termsContentArticle3_2Ul3 = t('termsContent.article3#2.ul3');
  const termsContentArticle3_2Ul4 = t('termsContent.article3#2.ul4');
  const termsContentArticle3_2Ul5 = t('termsContent.article3#2.ul5');
  const termsContentArticle3_2Ul6 = t('termsContent.article3#2.ul6');
  const termsContentArticle3_2Ul7 = t('termsContent.article3#2.ul7');
  const termsContentArticle3_2Ul8 = t('termsContent.article3#2.ul8');
  const termsContentArticle3_2Ul9 = t('termsContent.article3#2.ul9');
  const termsContentArticle3_2Ul10 = t('termsContent.article3#2.ul10');
  const termsContentArticle3_2Ul11 = t('termsContent.article3#2.ul11');
  const termsContentArticle3_2Ul12 = t('termsContent.article3#2.ul12');
  const termsContentArticle3_2Ul13 = t('termsContent.article3#2.ul13');
  const termsContentArticle3_3Title = t('termsContent.article3#3.title');
  const termsContentArticle4Title = t('termsContent.article4.title');
  const termsContentArticle4Content1 = t('termsContent.article4.content1');
  const termsContentArticle5Title = t('termsContent.article5.title');
  const termsContentArticle5_1Title = t('termsContent.article5#1.title');
  const termsContentArticle5_1Content1 = t('termsContent.article5#1.content1');
  const termsContentArticle5_1Content2 = t('termsContent.article5#1.content2');
  const termsContentArticle5_1Content3 = t('termsContent.article5#1.content3');
  const termsContentArticle5_2Title = t('termsContent.article5#2.title');
  const termsContentArticle5_2Content1 = t('termsContent.article5#2.content1');
  const termsContentArticle5_2Ul1 = t('termsContent.article5#2.ul1');
  const termsContentArticle5_2Ul2 = t('termsContent.article5#2.ul2');
  const termsContentArticle5_2Ul3 = t('termsContent.article5#2.ul3');
  const termsContentArticle5_2Content2 = t('termsContent.article5#2.content2');
  const termsContentArticle6Title = t('termsContent.article6.title');
  const termsContentArticle6Content1 = t('termsContent.article6.content1');
  const termsContentArticle7Title = t('termsContent.article7.title');
  const termsContentArticle7Content1 = t('termsContent.article7.content1');

  const variant = useMemo(() => isMobile ? 'h5' : 'h4', [isMobile]);

  return (
    <Container
      maxWidth="md"
      disableGutters={isMobile}
      sx={styles.container}
    >
      <Grid container alignItems="center" pt={5} pb={2}>
        <Grid
          container
          rowSpacing={2}
          flexDirection="row"
        >
          <Grid py={4} item xs={12} display="flex" justifyContent="center">
            <Typography variant={variant} color="white" sx={styles.typoTitle}>
              {termsContentTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle1Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle1Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle1Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle2Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle2Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle2Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle2Content3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              - {termsContentArticle2Ul1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              - {termsContentArticle2Ul2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle3Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3Content3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3Content4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle3_1Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3_1Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={styles.ulMargin}>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul1}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul2}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul3}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul4}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul5}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_1Ul6}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle3_2Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle3_2Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={styles.ulMargin}>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul1}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul2}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul3}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul4}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul5}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul6}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul7}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul8}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul9}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul10}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul11}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul12}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul13}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} display="flex">
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle3_3Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={styles.ulMargin}>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul1}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul2}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle3_2Ul3}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle4Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle4Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle5Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle5_1Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle5_1Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle5_1Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle5_1Content3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle5_2Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle5_2Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={styles.ulMargin}>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle5_2Ul1}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle5_2Ul2}
                </Typography>
              </li>
              <li>
                <Typography color="white" variant="body2">
                  {termsContentArticle5_2Ul3}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle5_2Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle6Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle6Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {termsContentArticle7Title}
            </Typography>
          </Grid>
          <Grid pb={4} item xs={12}>
            <Typography color="white" variant="body2">
              {termsContentArticle7Content1}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
})

export default Terms;
