import { atom } from 'jotai';
import mobile from 'is-mobile';

const isMobileAtom = atom(mobile());
const isVideoUploadedAtom = atom(false);
const isVideoSuspendedAtom = atom(false);
const isReportUpdatedAtom = atom(false);
const isWelcomeModalVisibleAtom = atom(false);

export {
  isMobileAtom,
  isVideoUploadedAtom,
  isVideoSuspendedAtom,
  isReportUpdatedAtom,
  isWelcomeModalVisibleAtom,
}
