const styles = {
  button: {
    textTransform: 'none',
  },
  input: {
    display: 'none',
  },
};

export default styles;
