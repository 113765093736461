const styles = {
  container: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  reactPlayer: {
    zIndex: -1,
  },
};

export default styles;
