import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { isMobileAtom } from '../../atoms';
import styles from './style';

const Privacy = React.memo(() => {
  const [isMobile] = useAtom(isMobileAtom);
  const { t } = useTranslation();

  const privacyContentTitle = t('privacyContent.title');
  const privacyContentArticle1Title = t('privacyContent.article1.title');
  const privacyContentArticle1Content1 = t('privacyContent.article1.content1');
  const privacyContentArticle1Content2 = t('privacyContent.article1.content2');
  const privacyContentArticle2Title = t('privacyContent.article2.title');
  const privacyContentArticle2Content1 = t('privacyContent.article2.content1');
  const privacyContentArticle2Content2 = t('privacyContent.article2.content2');
  const privacyContentArticle3Title = t('privacyContent.article3.title');
  const privacyContentArticle3Content1 = t('privacyContent.article3.content1');
  const privacyContentArticle3Content2 = t('privacyContent.article3.content2');
  const privacyContentArticle3Content3 = t('privacyContent.article3.content3');
  const privacyContentArticle3Content4 = t('privacyContent.article3.content4');
  const privacyContentArticle3Content5 = t('privacyContent.article3.content5');
  const privacyContentArticle4Title = t('privacyContent.article4.title');
  const privacyContentArticle4Content1 = t('privacyContent.article4.content1');
  const privacyContentArticle5Title = t('privacyContent.article5.title');
  const privacyContentArticle5Content1 = t('privacyContent.article5.content1');
  const privacyContentArticle5Content2 = t('privacyContent.article5.content2');

  return (
    <Container
      maxWidth="md"
      disableGutters={isMobile}
      sx={styles.container}
    >
      <Grid container alignItems="center" py={5}>
        <Grid
          container
          rowSpacing={2}
          flexDirection="row"
        >
          <Grid py={4} item xs={12} display="flex" justifyContent="center">
            <Typography variant="h3" color="white" sx={styles.title}>
              {privacyContentTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {privacyContentArticle1Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle1Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle1Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {privacyContentArticle2Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle2Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle2Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {privacyContentArticle3Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle3Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle3Content2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle3Content3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle3Content4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle3Content5}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {privacyContentArticle4Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle4Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" fontWeight="bold" variant="body2">
              {privacyContentArticle5Title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle5Content1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="body2">
              {privacyContentArticle5Content2}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});

export default Privacy;
