import React, { useCallback, useEffect, useMemo } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Routes,
  Route,
} from "react-router-dom";
import {
  Box,
  Container,
} from '@mui/material';
import { useAtom } from 'jotai';

import { Footer } from '../../components/Footer';
import { ForgotPassword } from '../ForgotPassword';
import { Home } from '../Home';
import { Nav } from '../../components/Nav';
import { Admin } from '../Admin';
import { Profile } from '../Profile';
import { About } from '../About';
import { Terms } from '../Terms';
import { Privacy } from '../Privacy';
import { Contact } from '../Contact';
import { Login } from '../Login';
import { SignUp } from '../SignUp';
import { Short } from '../Short';
import { NotFound } from '../NotFound';
import { UserRoles } from '../../types';
import { StateAuth, Context, useAuth } from '../../context/auth';
import { innerHeightAtom } from '../../atoms/window';
import { isMobileAtom } from '../../atoms';
import HomeSwipe from '../Home/HomeSwipe';
import styles from './style';

const NotConnectedRoutes = [
  '/login',
  '/signup',
  '/forgotpassword'
]

const NavigationRoot: React.FC<{ lang?: string, tz?: string }> = React.memo((props) => {
  const {
    logout,
    redirectToApp,
    user,
  } = useAuth() as Context;
  const [isMobile] = useAtom(isMobileAtom);
  const [innerHeight] = useAtom(innerHeightAtom)
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const HomeToRender = useCallback(() => {
    if (user.role === 'Admin' && user.isConnected === StateAuth.CONNECTED) {
      return <Admin />;
    } else if (user.role === 'User' && user.isConnected === StateAuth.CONNECTED) {
      return <Profile />;
    }
    if (isMobile) {
      return <HomeSwipe
        isMobile={isMobile}
        user={user}
        innerHeight={innerHeight}
      />;
    }
    return <Home
      isMobile={isMobile}
      user={user}
      innerHeight={innerHeight}
    />;
  }, [
    innerHeight,
    isMobile,
    user,
  ]);

  const getMarginBox = useCallback(() => {
    let rem = '3.75rem';
    if (innerHeight < 660 && user.os === 'ios') {
      rem = '2.75rem';
    } else if (innerHeight < 660 && user.os === 'android') {
      rem = '2.7rem';
    } else if (innerHeight >= 660 && innerHeight <= 734) {
      rem = '3.3rem';
    } else if (innerHeight >= 735) {
      rem = '2.8rem';
    }
    return rem;
  }, [innerHeight, user.os]);

  useEffect(() => {
    if (
      user.isConnected === StateAuth.NOT_CONNECTED
      && location.pathname !== '/'
      && location.pathname !== '/login'
      && location.pathname !== '/signup'
      && location.pathname !== '/forgotpassword'
      && location.pathname !== '/forgotpassword'
      && location.pathname !== '/about'
      && location.pathname !== '/privacy'
      && location.pathname !== '/terms'
      && location.pathname !== '/contact'
      && !location.pathname.includes('/short/')
    ) {
      navigate('/login', { replace: true });
    } else if (
      user.isConnected === StateAuth.CONNECTED
      && (
        location.pathname === '/login'
        || location.pathname === '/signup'
        || location.pathname === '/forgotpassword'
      )
    ) {
      navigate('/', { replace: true });
    } else if (
      user.isConnected === StateAuth.CONNECTED
      && location.pathname === '/'
      && user
      && user.role === UserRoles.ADMIN
    ) {
      navigate('/admin', { replace: true });
    }
  }, [
    navigate,
    location.pathname,
    user,
  ]);

  const getClassNameBox = useCallback(() => {
    let str = 'app-mobile';
    if (innerHeight < 660 && user.os === 'ios') {
      str = 'app-mobile-little-x2-ios';
    } else if (innerHeight < 660 && user.os === 'android') {
      str = 'app-mobile-little-x2-android';
    } else if (innerHeight >= 660 && innerHeight <= 734) {
      str = 'app-mobile-little';
    } else if (innerHeight >= 735) {
      str = 'app-mobile';
    }
    return str;
  }, [innerHeight, user.os]);

  const boxClass = useMemo(() => {
    if (
      isMobile &&
      (
        (location.pathname.includes('/short/') && !searchParams.get('redirect'))
        || location.pathname === '/'
      )
    ) {
      return "app-short-without-footer";
    } else if (
      (isMobile && !location.pathname.includes('/short/'))
      || (searchParams.get('redirect') && searchParams.get('redirect') === '42')
    ) {
      return getClassNameBox();
    } else if (!isMobile) {
      return "app";
    }
  }, [
    getClassNameBox,
    isMobile,
    location.pathname,
    searchParams,
  ]);

  const boxMt = useMemo(() => {
    return isMobile
      ? ((!location.pathname.includes('/short/') && location.pathname !== '/')
        || (searchParams.get('redirect') && searchParams.get('redirect') === '42'))
        ? getMarginBox()
        : '0rem'
      : '4.1rem'
  }, [
    isMobile,
    getMarginBox,
    location.pathname,
    searchParams,
  ]);

  const disableGutters = useMemo(() => (
    (location.pathname === '/' && isMobile)
    || location.pathname.includes('/short/')
  ), [isMobile, location.pathname]);

  return (
    <div>
      {user.isConnected !== StateAuth.LOADING && (
        <div>
          <Nav
            redirectToApp={redirectToApp}
            isConnected={user.isConnected}
            os={user.os}
            innerHeight={innerHeight}
            user={user}
            logout={logout}
            isMobile={isMobile}
          />
          <Container maxWidth="lg" disableGutters={disableGutters}>
            <Box
              mt={boxMt}
              className={boxClass}
            >
              <div style={styles.fullHeight}>
                <Routes>
                  {user.role === 'Admin' && user.isConnected === StateAuth.CONNECTED && (
                    <Route path="/admin" element={<Admin />} />
                  )}
                  <Route
                    path="/short/:codeshare"
                    element={
                      <Short
                        isMobile={isMobile}
                        user={user}
                        innerHeight={innerHeight}
                      />
                    }
                  />
                  <Route
                    index
                    element={HomeToRender()}
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  {user.isConnected === StateAuth.NOT_CONNECTED && (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/forgotpassword" element={<ForgotPassword />} />
                    </>
                  )}
                  {!NotConnectedRoutes.some((r) => r === location.pathname) && (
                    <Route path="*" element={<NotFound />} />
                  )}
                </Routes>
              </div>
            </Box>
          </Container>
          {
            (
              location.pathname.includes('/short/') && !isMobile
              || location.pathname === '/' && !isMobile
              || (location.pathname.includes('/short/') && !isMobile)
              || (searchParams.get('redirect') && searchParams.get('redirect') === '42')
            )
            && (
              <Footer
                isMobile={isMobile}
                innerHeight={innerHeight}
                os={user.os}
              />
            )}
        </div>
      )}
    </div>
  );
});

export default NavigationRoot;
