import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { useTranslation } from "react-i18next";

import styles from './style';

interface CustomInputArgs {
  alreadyExist?: boolean;
  autoFocus?: boolean;
  control: any;
  defaultValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  innerRef?: any;
  label?: string;
  multiline?: boolean;
  name: string;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  placeholder: string;
  required?: boolean;
  rules: any;
  size?: 'medium' | 'small';
  sx?: {};
  styleContainer?: {};
  type?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  value?: string;
}

const CustomInput = React.memo(({
  alreadyExist = false,
  autoFocus,
  control,
  disabled,
  fullWidth,
  id,
  label,
  multiline,
  name,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  required,
  rules = {},
  size,
  sx,
  type,
  variant = 'outlined',
  value: valueInput,
}: CustomInputArgs) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={!valueInput ? '' : valueInput}
      render={({ field: { value: v, onChange: onChangeText }, fieldState: { error } }) => (
        <div style={styles.input}>
          <TextField
            error={isEmpty(error) ? false : true}
            disabled={disabled}
            name={name}
            required={required}
            fullWidth={fullWidth}
            label={isEmpty(error) ? label : "Error"}
            id={id}
            size={!size ? 'medium' : size}
            value={v}
            placeholder={placeholder}
            autoFocus={autoFocus}
            multiline={multiline}
            type={type}
            variant={variant}
            onChange={!onChange ? onChangeText : (e) => {
              onChange(e);
              onChangeText(e);
            }}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {!alreadyExist && error && (
            <Typography variant="body2" color="#f44336">
              {error.message || 'Error'}
            </Typography>
          )}
          {alreadyExist && (
            <Typography variant="body2" color="#f44336">
              {t(`form.error.alreadyExist.${name}`)}
            </Typography>
          )}
        </div>
      )
      }
    />
  );
});

export { CustomInput };
