import React, { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Container,
  Checkbox,
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { useManualQuery } from 'graphql-hooks';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAtom } from 'jotai';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { USER_EMAIL_EXIST, USER_USERNAME_EXIST } from '../../graphql/user/query';
import { CustomInput } from '../../components/CustomInput';
import { DatePick } from '../../components/DatePick';
import { useAuth, Context } from '../../context/auth';
import { isMobileAtom } from '../../atoms';
import styles from './style';

interface SignUpData {
  accessToken?: string;
  provider?: string;
  email: string;
  dateOfBirth: Date;
  password: string;
  username: string;
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

interface SignUpProps {
  state?: any;
}

const SignUp = React.memo((props: SignUpProps) => {
  const { t } = useTranslation();
  const {
    user,
    signUp,
  } = useAuth() as Context;
  const [isMobile] = useAtom(isMobileAtom);
  const location = useLocation() as any;

  const { control, handleSubmit } = useForm<SignUpData>();
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [userEmailExist] = useManualQuery(USER_EMAIL_EXIST);
  const [usernameAlreadyExist, setUsernameAlreadyExist] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [userUsernameExist] = useManualQuery(USER_USERNAME_EXIST);
  const today = new Date(Date.now());
  const year = today.getFullYear() - 13;
  const maxDate = new Date();
  maxDate.setFullYear(year);
  const [cguAccepted, setCguAccepted] = useState(false);

  const emailTrad = t('email');
  const signUpTrad = t('form.signUp');
  const usernameTrad = t('username');
  const formPasswordTrad = t('form.password');
  const formResumeTitleTrad = t('form.resumeTitle');
  const addYourDateOfBirthTrad = t('addYourDateOfBirth');
  const formCguIAcceptTrad = t('form.cgu.iAccept');
  const formSignUpTrad = t('form.signUp');
  const formSignInQuestionTrad = t('form.signInQuestion');
  const formSignInTrad = t('form.signIn');
  const formErrorRequiredUsernameTrad = t('form.error.required.username');
  const formErrorMinLengthUsernameTrad = t('form.error.minLength.username');
  const formErrorMaxLengthUsernameTrad = t('form.error.maxLength.username');
  const formErrorRequiredEmailTrad = t('form.error.required.email');
  const formErrorPatternEmailTrad = t('form.error.pattern.email');
  const formErrorRequiredPasswordTrad = t('form.error.required.password');
  const formErrorMinLengthPasswordTrad = t('form.error.minLength.password');
  const formErrorPatternPasswordTrad = t('form.error.pattern.password');

  const onClickCgu = useCallback((e: any) => {
    setCguAccepted(e.target.checked);
  }, []);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const isValidUsername = async (username: string) => {
    const { data } = await userUsernameExist({
      variables: {
        username,
      },
    }) as any;
    if (data.userUsernameExist && !usernameAlreadyExist) {
      setUsernameAlreadyExist(true);
    } else if (!data.userUsernameExist && usernameAlreadyExist) {
      setUsernameAlreadyExist(false);
    }
  };

  const isValidEmail = async (email: string) => {
    const { data } = await userEmailExist({
      variables: {
        email,
      },
    }) as any;
    if (data.userEmailExist && !emailAlreadyExist) {
      setEmailAlreadyExist(true);
    } else if (!data.userEmailExist && emailAlreadyExist) {
      setEmailAlreadyExist(false);
    }
  };

  const debounceUsernameFn = debounce(isValidUsername, 300);
  const debounceEmailFn = debounce(isValidEmail, 300);

  const onChangeUsername = (e: any) => {
    debounceUsernameFn(e.target.value);
  };

  const onChangeEmail = (e: any) => {
    debounceEmailFn(e.target.value);
  };

  const onRegisterPressed = async (data: SignUpData) => {
    if (!data.dateOfBirth) {
      data.dateOfBirth = maxDate;
    }
    try {
      if (!usernameAlreadyExist && !emailAlreadyExist && cguAccepted) {
        if (location && !isEmpty(location.state) && location.state.provider) {
          if (location.state.provider === 'Facebook' && location.state.accessToken) {
            data.accessToken = location.state.accessToken;
          }
          data.provider = location.state.provider;
        }
        await signUp(data);
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  };

  const onCloseSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const usernameRules = useMemo(() => ({
    required: formErrorRequiredUsernameTrad,
    minLength: {
      value: 3,
      message: formErrorMinLengthUsernameTrad,
    },
    maxLength: {
      value: 24,
      message: formErrorMaxLengthUsernameTrad,
    },
  }), [
    formErrorRequiredUsernameTrad,
    formErrorMinLengthUsernameTrad,
    formErrorMaxLengthUsernameTrad,
  ]);

  const emailRules = useMemo(() => ({
    required: formErrorRequiredEmailTrad,
    pattern: {
      value: EMAIL_REGEX,
      message: formErrorPatternEmailTrad,
    },
  }), [formErrorRequiredEmailTrad, formErrorPatternEmailTrad]);

  const passwordRules = useMemo(() => ({
    required: formErrorRequiredPasswordTrad,
    minLength: {
      value: 8,
      message: formErrorMinLengthPasswordTrad,
    },
    pattern: {
      value: PASSWORD_REGEX,
      message: formErrorPatternPasswordTrad,
    },
  }), [
    formErrorRequiredPasswordTrad,
    formErrorMinLengthPasswordTrad,
    formErrorPatternPasswordTrad,
  ]);

  const linkSignIn = useMemo(() => ({
    marginLeft: isMobile ? '1vw' : '0.5vw',
  }), [isMobile]);

  const typoAlign = useMemo(() => isMobile ? 'center' : 'inherit', [isMobile]);

  return (
    <Grid
      container
      alignItems="center"
      sx={styles.container}
    >
      <Container
        maxWidth="sm"
        disableGutters={isMobile}
      >
        <Paper
          sx={styles.paper}
          elevation={3}
        >
          <Box px={2}>
            <Grid
              container
              direction="row"
            >
              <Grid item xs={12} mt={2}>
                <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                  {signUpTrad}
                </Typography>
              </Grid>
              <Grid item mt={2} xs={12}>
                <CustomInput
                  alreadyExist={usernameAlreadyExist}
                  name="username"
                  label={usernameTrad}
                  control={control}
                  onChange={onChangeUsername}
                  placeholder={usernameTrad}
                  value={location && location.state ? location.state.username : null}
                  rules={usernameRules}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item mt={2} xs={12}>
                <CustomInput
                  alreadyExist={emailAlreadyExist}
                  name="email"
                  label='Email'
                  control={control}
                  onChange={onChangeEmail}
                  placeholder={emailTrad}
                  value={location && location.state ? location.state.email : null}
                  rules={emailRules}
                  fullWidth
                  variant="outlined"
                  required
                />
              </Grid>
              {location && !location.state && (
                <Grid item mt={2} xs={12}>
                  <CustomInput
                    name="password"
                    control={control}
                    label={formPasswordTrad}
                    placeholder={formPasswordTrad}
                    rules={passwordRules}
                    fullWidth
                    variant="outlined"
                    type="password"
                    required
                  />
                  <Typography variant="body2" mt={1} color="black">
                    {formResumeTitleTrad}
                  </Typography>
                </Grid>
              )}
              <Grid item mt={3} xs={12}>
                <DatePick
                  maxDate={maxDate}
                  name="dateOfBirth"
                  control={control}
                  label={addYourDateOfBirthTrad}
                  language={user.language}
                  value={maxDate}
                />
              </Grid>
              <Grid item xs={12} mt={1} >
                <FormControlLabel
                  value="end"
                  checked={cguAccepted}
                  control={
                    <Checkbox
                      color="primary"
                    />
                  }
                  onChange={onClickCgu}
                  label={
                    <>
                      <Link
                        to="/cgu"
                        target="_blank"
                        style={styles.linkTextDecoration}
                      >
                        <Typography
                          variant="body2"
                          color="#3f51b5"
                          fontWeight="bold"
                        >
                          {formCguIAcceptTrad}
                        </Typography>
                      </Link>
                    </>
                  }
                  labelPlacement="end"
                />
              </Grid>
              <Grid item mt={1} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleSubmit(onRegisterPressed)}
                  sx={styles.signupButton}
                >
                  <Typography fontWeight="bold">
                    {formSignUpTrad}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                mt={1}
                mb={2}
                xs={12}
                flexDirection="row"
                display="flex"
                justifyContent="flex-end"
              >
                <Typography variant="body2" color="black" display="inline">
                  {formSignInQuestionTrad}
                </Typography>
                <Link to="/login" style={styles.linkTextDecoration}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    align={typoAlign}
                    color="#3f51b5"
                    display="inline"
                    sx={linkSignIn}
                  >
                    {formSignInTrad}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Snackbar
          anchorOrigin={styles.anchorOrigin}
          open={errorMessage.active}
          autoHideDuration={3000}
          message={errorMessage.msg}
          onClose={onCloseSnack}
        >
          <Alert severity="error" sx={styles.alert}>
            {errorMessage.msg}
          </Alert>
        </Snackbar>
      </Container>
    </Grid>
  );
});

export default SignUp;
