import DateDiff from 'date-diff';
import videoLocale from '../locales/en/video.json';

const getWeaponTrad = (weapon: string, t: any) => {
  const weaponObj = videoLocale.weapon as { [key: string]: any };
  const tmp = t(weaponObj[weapon]);
  if (!tmp) {
    return weapon;
  }
  return tmp;
};

const getModeTrad = (mode: string, t: any) => {
  const modeObj = videoLocale.mode as { [key: string]: any };
  const tmp = t(modeObj[mode]);
  if (!tmp) {
    return mode;
  }
  return tmp;
};

const getFormatViews = (value: number) => {
  if (!value) {
    return '0';
  }
  const str = value.toString();
  if (str.length === 5 || str.length === 6) {
    return `${str.slice(0, str.length - 3)}K`;
  }
  if (str.length === 7) {
    return `${str[0]},${str[1]}M`;
  }
  if (str.length === 8) {
    return `${str[0]}${str[1]},${str[2]}M`;
  }
  if (str.length === 9) {
    return `${str[0]}${str[1]}Md`;
  }
  return str;
};

const getFormatDate = (parentDate: Date, childDate: number, t: any) => {
  const diff = new DateDiff(parentDate, new Date(childDate));
  const year = Math.round(diff.years());
  const month = Math.round(diff.months());
  const day = Math.round(diff.days());
  const hour = Math.round(diff.hours());
  const min = Math.round(diff.minutes());
  const second = Math.round(diff.seconds());

  let d = `${second}s`;
  if (year >= 1) {
    d = ` ${year} ${t('year')}`;
    if (year > 1) {
      d += 's';
    }
  } else if (month >= 2) {
    d = ` ${month} ${t('month')}`;
    if (month > 1) {
      d += 's';
    }
  } else if (day >= 1) {
    d = ` ${day} ${t('day')}`;
    if (day > 1) {
      d += 's';
    }
  } else if (hour >= 1) {
    d = ` ${hour} ${t('hour')}`;
    if (hour > 1) {
      d += 's';
    }
  } else if (min >= 1) {
    d = ` ${min.toString()[0]} ${t('min')}`;
    if (min > 1) {
      d += 's';
    }
  } else if (second) {
    if (second < 1) {
      d = ' 1 s';
    } else {
      d = ` ${second.toString()[0]}s`;
      if (second >= 10) {
        const tmp = second.toString();
        d = ` ${tmp[0]}${tmp[1]}s`;
      }
    }
  }
  return d;
};

const getKillTrad = (kills: number, t: any) => {
  const infiniteTrad = t(videoLocale.infinite);
  let str = '';
  if (kills! > 6) {
    str = infiniteTrad;
  } else if (kills! > 0 && kills! <= 6) {
    str = kills!.toString();
  }
  return str;
};

export {
  getKillTrad,
  getFormatDate,
  getFormatViews,
  getModeTrad,
  getWeaponTrad,
};
