import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import styles from './style';

interface ProfileAddVideoProps {
  t: (doc: string) => string;
  valueInputAddVideo: string;
  onChangeVideoInput: (event: any) => Promise<void>;
}

const ProfileAddVideo = React.memo((props: ProfileAddVideoProps) => {
  const {
    t,
    valueInputAddVideo,
    onChangeVideoInput,
  } = props;

  const addVideoTrad = t('addVideo');
  const maxTimeVideoTrad = t('maxTimeVideo');

  return (
    <Grid
      display="flex"
      alignItems="center"
      item
      sm={4}
      justifyContent="flex-end"
    >
      <Grid container>
        <Grid
          item
          sm={12}
          display="flex"
          justifyContent="flex-end"
        >
          <label>
            <input
              value={valueInputAddVideo}
              onChange={onChangeVideoInput}
              accept="video/*"
              multiple={false}
              style={styles.input}
              type="file"
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<AddIcon />}
              sx={styles.button}
            >
              <Typography fontWeight="bold">
                {addVideoTrad}
              </Typography>
            </Button>
          </label>
        </Grid>
        <Grid
          item
          sm={12}
          display="flex"
          justifyContent="flex-end"
        >
          <Typography variant="caption" color="grey">
            {maxTimeVideoTrad}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ProfileAddVideo;
