import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from "react-router-dom";
import {
  ClientContext,
  GraphQLClient,
} from 'graphql-hooks';
import moment from 'moment';
import 'moment-timezone';
import i18n from "i18next";
import memCache from 'graphql-hooks-memcache';

import { resources, initReactI18next } from './i18n/config';
import { AuthProvider } from './context/auth';
import { NavigationRoot } from './screens/NavigationRoot';

function App() {
  const [appLoaded, setAppLoaded] = useState(false);
  const getLang = useCallback(() => {
    const lang = window.navigator.language;
    if (lang.split('-').length) {
      const tmp = lang.split('-')[0];
      if (tmp === 'fr' || tmp === 'en') {
        return tmp;
      } else {
        return 'en';
      }
    }
    if (lang && (lang === 'fr' || lang === 'en')) {
      return window.navigator.language;
    } else {
      return 'en';
    }
  }, []);
  const getUrl = useCallback(() => {
    let url = 'http://192.168.1.13:3000/graphql';
    if (
      process.env.REACT_APP_ENV === 'production'
      && (
        window.location.hostname === 'soskills.io'
        || window.location.hostname === 'localhost'
        || window.location.hostname.includes('192.168.1')
      )
    ) {
      url = 'https://backend.soskills.io/graphql';
    } else if (
      process.env.REACT_APP_ENV === 'production'
      && window.location.hostname === 'soskills.fr'
    ) {
      url = 'https://backend.soskills.fr/graphql';
    }
    return url;
  }, [process.env.REACT_APP_ENV]);

  const client = useMemo(() => new GraphQLClient({
    url: getUrl(),
    cache: memCache(),
  }), [getUrl]);
  const lang = getLang();
  const tz = moment.tz.guess();
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  window.addEventListener('resize', appHeight)

  useEffect(() => {
    appHeight();
    i18n
      //.use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources,
        react: {
          useSuspense: false,
        }
      }, () => {
        i18n.changeLanguage(lang);
        setAppLoaded(true);
        //i18n.changeLanguage('fr');
      });
  }, [lang]);

  if (!appLoaded) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_WEBCLIENT_ID!}>
      <BrowserRouter>
        <ClientContext.Provider value={client}>
          <AuthProvider
            lang={lang}
            tz={tz}
          >
            <NavigationRoot />
          </AuthProvider>
        </ClientContext.Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
