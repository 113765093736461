import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  uploadFormButton: {
    width: '100%',
    borderRadius: 1,
    marginTop: '1.5vh',
    textTransform: 'none',
  },
};

export default styles;
