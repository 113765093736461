const styles = {
  mainBox: {
    width: '100%',
    typography: 'body1',
  },
  blackTab: {
    color: 'black'
  },
  box: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  tabList: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
