const styles = {
  avatar: {
    width: '1.8rem',
    height: '1.8rem',
  },
  avatarGrid: {
    position: 'absolute',
    top: '0.2rem',
    left: '0.25rem',
    height: '8%',
  },
  box: {
    position: 'absolute',
    bottom: 0,
    border: '1px solid #1C2833',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    padding: '0.4rem',
    bgcolor: '#18181b',
    outline: 'none'
  },
  cursor: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  closeGrid: {
    position: 'absolute',
    top: '0.45rem',
    right: '0rem',
    height: '8%',
  },
  closeIcon: {
    color: 'white'
  },
  description: {
    fontFamily: 'Inter',
  },
  title: {
    fontSize: '1.03rem',
  },
};

export default styles;
