import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  Grid,
  Pagination,
  Snackbar,
  Stack,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useManualQuery } from 'graphql-hooks';
import { useAtom } from 'jotai';

import { REPORTS_PAGINATION } from '../../graphql/report/query';
import { Report, ReportType, ReportStatus } from '../../types';
import { isReportUpdatedAtom } from '../../atoms';
import ReportCommentModal from '../../components/Modals/ReportCommentModal/ReportCommentModal';
import AddVideoModalfrom from '../../components/Modals/AddVideoModal/AddVideoModal';
import UserModal from '../Modals/UserModal/UserModal';
import styles from './style';

interface ReportPagination {
  filter: Filter;
  docs: Report[];
  infos: {
    limit: number;
    offset: number;
    totalDocs: number;
  };
}

interface Filter {
  offset?: number;
  limit?: number;
  status?: string;
  type?: string;
}

interface FilterProps {
  filterData: Filter;
}

const Reports = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [videoPreview, setVideoPreview] = useState({} as { id: string });
  const [isReportUpdated, setIsReportUpdated] = useAtom(isReportUpdatedAtom);
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [reportsPagination] = useManualQuery(REPORTS_PAGINATION);
  const [userIdPreview, setUserIdPreview] = useState('');
  const [report, setReport] = useState({} as Report);
  const [reports, setReports] = useState({
    docs: [] as Report[],
    filter: {} as Filter,
  } as ReportPagination);
  const [isAddVideoModalVisible, setIsAddVideoModalVisible] = useState(false);
  const handleIsAddVideoModalVisible = useCallback(() => {
    setIsAddVideoModalVisible(!isAddVideoModalVisible);
  }, [isAddVideoModalVisible]);
  const [isReportCommentModalVisible, setIsReportCommentModalVisible] = useState(false);
  const handleIsReportCommentModalVisible = useCallback(() => {
    setIsReportCommentModalVisible(!isReportCommentModalVisible);
  }, [isReportCommentModalVisible]);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const handleIsUserModalVisible = useCallback(() => {
    setIsUserModalVisible(!isUserModalVisible);
  }, [isUserModalVisible]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getData = useCallback(async ({ filterData }: FilterProps) => {
    const { data } = await reportsPagination({
      variables: {
        input: {
          ...filterData,
        },
      },
    }) as any;
    if (isReportUpdated) {
      setIsReportUpdated(false);
    }
    setReports({
      filter: filterData,
      infos: {
        totalDocs: data.reportsPagination.totalDocs,
        limit: data.reportsPagination.limit,
        offset: data.reportsPagination.offset,
      },
      docs: data.reportsPagination.docs,
    });
  }, [
    reportsPagination,
    isReportUpdated,
    setIsReportUpdated,
  ]);

  const onChangePagination = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    getData({
      filterData: {
        ...reports.filter,
        offset: (page - 1) * 5,
        limit: 5,
      },
    });
    setCurrentPage(page - 1);
  }, [getData, reports.filter]);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onReportHandle = useCallback((report: Report) => {
    if (report.type === ReportType.COMMENT) {
      setVideoPreview({ id: report.videoId });
      setReport(report);
      handleIsReportCommentModalVisible();
    } else if (report.type === ReportType.VIDEO) {
      setVideoPreview({ id: report.videoId });
      setReport(report);
      handleIsAddVideoModalVisible();
    }
  }, [handleIsAddVideoModalVisible, handleIsReportCommentModalVisible]);

  const onChangeReportType = useCallback((event: SelectChangeEvent) => {
    if (reports.filter.type) {
      setCurrentPage(0);
      getData({
        filterData: {
          offset: 0,
          limit: 5,
          status: reports.filter.status,
          type: event.target.value,
        }
      });
    }
  }, [reports.filter, getData]);

  const onChangeReportStatus = useCallback((event: SelectChangeEvent) => {
    if (reports.filter.status && event.target.value) {
      setCurrentPage(0);
      getData({
        filterData: {
          offset: 0,
          limit: 5,
          status: event.target.value,
          type: reports.filter.type,
        }
      });
    }
  }, [getData, reports.filter]);

  const goToUser = useCallback((id: string) => {
    setUserIdPreview(id);
    handleIsUserModalVisible();
  }, [handleIsUserModalVisible]);

  useEffect(() => {
    getData({
      filterData: {
        offset: 0,
        limit: 5,
        status: ReportStatus.OPEN,
        type: ReportType.VIDEO,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReportUpdated) {
      getData({
        filterData: {
          ...reports.filter,
          offset: 0,
          limit: 5,
        },
      });
    }
  }, [
    isReportUpdated,
    getData,
    reports.filter
  ]);

  return (
    <Container>
      <Grid container direction="row" mt={1}>
        <Grid item xs={12} mb={1}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  value={reports.filter.type ? reports.filter.type : 'VIDEO'}
                  label="Type"
                  onChange={onChangeReportType}
                  sx={styles.selectHeight}
                >
                  <MenuItem value={'VIDEO'}>VIDEO</MenuItem>
                  <MenuItem value={'COMMENT'}>COMMENT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reports.filter.status ? reports.filter.status : 'OPEN'}
                  label="Status"
                  onChange={onChangeReportStatus}
                  sx={styles.selectHeight}
                >
                  <MenuItem value={'OPEN'}>OPEN</MenuItem>
                  <MenuItem value={'CLOSED'}>CLOSED</MenuItem>
                  <MenuItem value={'REJECTED'}>REJECTED</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        rowSpacing={1}
        py={2}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography color="black" fontWeight="bold">
                    TYPE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    IDENTIFIANT
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    USER
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    REPORTER
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    REASON
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="black" fontWeight="bold">
                    STATUS
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {reports && reports.docs && reports.docs.length > 0 && (
              <TableBody>
                {(reports.docs.map((r, index) => {
                  return (
                    <TableRow key={`${index}-${r.id}`}>
                      <TableCell>
                        <Typography variant="button" fontWeight="bold" color="grey">
                          {r.type}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {r.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={styles.hover}
                          variant='body2'
                          onClick={() => goToUser(r.user.id)}
                        >
                          {r.user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant='body2'
                          sx={styles.hover}
                          onClick={() => goToUser(r.reporter.id)}
                        >
                          {r.reporter.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {r.reason}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          //variant="contained"
                          color="primary"
                          onClick={() => onReportHandle(r)}
                          sx={styles.statusButton}
                        >
                          <Typography fontWeight="bold" color={r.status === ReportStatus.OPEN ? "#1976d2" : "#f44336"}>
                            {r.status}
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                }))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {reports && reports.docs && reports.docs.length > 0 && (
          <Grid item xs={12} display="flex" justifyContent="center" mt={1}>
            <Stack spacing={2}>
              <Pagination
                page={currentPage + 1}
                count={Math.ceil(reports.infos.totalDocs / 5)}
                onChange={onChangePagination}
                color="primary"
              />
            </Stack>
          </Grid>
        )}
      </Grid>
      {
        reports && !reports.docs.length && (
          <Container maxWidth="xs">
            <Grid container direction="row" py={2} mx={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography fontWeight="bold" color="#212121" variant="h6">
                  Aucun signalement
                </Typography>
              </Grid>
            </Grid>
          </Container>
        )
      }
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
      {
        isAddVideoModalVisible && (
          <AddVideoModalfrom
            videoPreview={videoPreview}
            isAddVideoModalVisible={isAddVideoModalVisible}
            handleIsAddVideoModalVisible={handleIsAddVideoModalVisible}
            report={report}
          />
        )
      }
      {
        isReportCommentModalVisible && (
          <ReportCommentModal
            isReportCommentModalVisible={isReportCommentModalVisible}
            handleIsReportCommentModalVisible={handleIsReportCommentModalVisible}
            report={report}
          />
        )
      }
      {
        isUserModalVisible && userIdPreview && (
          <UserModal
            isUserModalVisible={isUserModalVisible}
            handleIsUserModalVisible={handleIsUserModalVisible}
            userId={userIdPreview}
          />
        )
      }
    </Container>
  )
}

export default React.memo(Reports);
