import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { fr, enUS } from 'date-fns/locale'


interface DatePickArgs {
  control: any;
  language: string;
  label?: string;
  name: string;
  maxDate: Date | null;
  onChange?: (value: any, keyboardInputValue?: string | undefined) => void;
  value?: Date,
}

const DatePick = ({
  control,
  label,
  language,
  name,
  onChange,
  maxDate,
  value,
}: DatePickArgs) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value: v, onChange: onChangeDate }, fieldState: { error } }) => (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={language === 'fr' ? fr : enUS}
          >
            <DatePicker
              maxDate={maxDate}
              disableFuture
              label={label}
              value={!v ? value : v}
              onChange={onChangeDate}
              renderInput={(params) => <TextField {...params} sx={{
                width: '100%',
              }} />}
            />
          </LocalizationProvider>
        )}
      />
    </>
  );
};

export { DatePick };
