const styles = {
  container: {
    paddingLeft: 0.4,
    paddingRight: 0.4,
    height: '100%',
  },
  contactButton: {
    width: '95%',
    borderRadius: 1,
    textTransform: 'none',
  },
  paper: {
    backgroundColor: 'white',
    color: '#212121',
  },
};

export default styles;
