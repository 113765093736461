import {
  Avatar,
  Grid,
  Typography,
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { User, Stat } from '../../../types';
import styles from './style';

interface ProfileStatProps {
  followersTitle: string;
  followsTitle: string;
  isMobile: boolean;
  onChangeAvatarInput: (event: any) => Promise<void>;
  stat: Stat;
  user: User;
  valueInputAddAvatar: string;
  videoTitle: string;
}

const ProfileStat = React.memo((props: ProfileStatProps) => {
  const {
    followersTitle,
    followsTitle,
    isMobile,
    onChangeAvatarInput,
    stat,
    user,
    valueInputAddAvatar,
    videoTitle,
  } = props;

  const variant = useMemo(() => isMobile ? 'h6' : 'h5', [isMobile]);

  return (
    <Grid item sm={8}>
      <Grid container>
        <Grid item xs={2}>
          <Badge
            overlap="circular"
            anchorOrigin={styles.anchorOrigin}
            badgeContent={
              <>
                {!isMobile && (
                  <label>
                    <input
                      value={valueInputAddAvatar}
                      type="file"
                      accept='image/*'
                      onChange={onChangeAvatarInput}
                      multiple={false}
                      style={styles.inputStyle}
                    />
                    <FontAwesomeIcon
                      style={styles.fontAwesomeIcon}
                      icon={faPlusCircle}
                      size="2x"
                    />
                  </label>
                )}
              </>
            }
          >
            <Avatar
              alt="profile"
              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${user.avatar}`}
              sx={styles.avatar}
            />
          </Badge>
        </Grid>
        <Grid
          item
          xs={9}
          ml={3}
          display="flex"
          alignItems="center"
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <Typography variant={variant} fontWeight="bold" color="#212121">
                {capitalize(user.username)}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="body2" color="black" fontWeight="medium">
                {stat.follows} &nbsp; {followsTitle} &nbsp;
                | &nbsp; {stat.followers} &nbsp; {followersTitle} &nbsp;
                | &nbsp; {stat.publications} &nbsp; {videoTitle} &nbsp;
                | &nbsp; {stat.likes} &nbsp; Likes
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ProfileStat;
