import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  container: {
    paddingLeft: 0.4,
    paddingRight: 0.4,
    height: '100%',
  },
  mainBox: {
    width: '100%',
    typography: 'body1',
  },
  box: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  paper: {
    backgroundColor: 'white',
    color: 'black',
  },
  tabList: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
