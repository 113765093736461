import { User } from './user';
import { Video } from './video';

enum ReportStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

enum ReportType {
  VIDEO = 'VIDEO',
  COMMENT = 'COMMENT',
}

interface Report {
  id: string;
  commentId: string;
  reason: string;
  reporterId: string;
  reporter: User;
  userId: string;
  user: User;
  videoId: string;
  video: Video;
  status: ReportStatus;
  type: ReportType;
}

interface ReportStat {
  reportCreated: number;
  reportCreatedByDay: number;
  reportCreatedByWeek: number;
}

export { ReportType, ReportStatus };

export type { Report, ReportStat };
