import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  buttonAddVideo: {
    textTransform: 'none',
  },
  buttonCancel: {
    textTransform: 'none',
  },
  buttonDelete: {
    textTransform: 'none',
  },
  deleteVideoGrid: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.75,
    }
  },
  cursor: {
    cursor: 'grab',
  },
  hoverBlock: {
    display: 'block',
  },
  imgOverlay: {
    display: 'block',
    borderRadius: 5,
  },
  inputAddVideo: {
    display: 'none',
  },
  linkHover: {
    '&:hover': {
      opacity: 0.7,
    },
  },
  linkTypography: {
    fontSize: 13,
  },
  videoDetailsGrid: {
    cursor: 'grab',
    '&:hover': {
      opacity: 0.75,
    },
  },
};

export default styles;
