import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  useMutation,
  useManualQuery,
} from 'graphql-hooks';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { UPDATE_PASSWORD } from '../../graphql/user/mutation';
import { USER_EMAIL_EXIST } from '../../graphql/user/query';
import { CREATE_CODE, VALIDATE_CODE } from '../../graphql/code/mutation';
import { CustomInput } from '../../components/CustomInput';
import { CodeType } from '../../types';
import { isMobileAtom } from '../../atoms';
import styles from './style';

interface ForgotPasswordEmail {
  email: string;
}

interface ForgotPasswordCode {
  code: string;
}

interface ForgotPasswordData {
  password: string;
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const ForgotPassword = React.memo(() => {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [isEmailFill, setIsEmailFill] = useState(false);
  const [isCodeFill, setIsCodeFill] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [userEmailExist] = useManualQuery(USER_EMAIL_EXIST);
  const [userUpdatePassword] = useMutation(UPDATE_PASSWORD);
  const [createCode] = useMutation(CREATE_CODE);
  const [validateCode] = useMutation(VALIDATE_CODE);
  const navigate = useNavigate();

  const recoverYourAccount = t('recoverYourAccount');
  const incorrectCodeTitle = t('form.error.incorrect.code');
  const notExistEmailTitle = t('form.error.notExist.email');
  const codeSentTitle = t('form.codeSent');
  const passwordSentTitle = t('form.passwordSent');
  const formValidateCodeTrad = t('form.validateCode');
  const formNextTrad = t('form.next');
  const formErrorRequiredEmailTrad = t('form.error.required.email');
  const formErrorPatternEmailTrad = t('form.error.pattern.email');
  const emailTrad = t('email');
  const formErrorRequiredCodeTrad = t('form.error.required.code');
  const formErrorMinLengthCodeTrad = t('form.error.minLength.code');
  const formPasswordTrad = t('form.password');
  const formErrorRequiredPasswordTrad = t('form.error.required.password');
  const formErrorMinLengthPasswordTrad = t('form.error.minLength.password');
  const formErrorPatternPasswordTrad = t('form.error.pattern.password');
  const formResumeTitleTrad = t('form.resumeTitle');
  const formSignUpTrad = t('form.signUp');
  const formSignUpQuestionTrad = t('form.signUpQuestion');

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const {
    control,
    handleSubmit,
  } = useForm<ForgotPasswordEmail>();

  const {
    control: controlCode,
    handleSubmit: handleCode,
  } = useForm<ForgotPasswordCode>();

  const {
    control: controlPassword,
    handleSubmit: handlePassword,
  } = useForm<ForgotPasswordData>();

  const onSubmitEmail = useCallback(async (obj: ForgotPasswordEmail) => {
    try {
      const { data } = await userEmailExist({
        variables: {
          email: obj.email,
        },
      });
      if (data.userEmailExist) {
        const { data: code } = await createCode({
          variables: {
            input: {
              email: obj.email,
              type: CodeType.EMAIL,
            },
          },
        });
        if (code.createCode && code.createCode.id) {
          setSuccessMessage({ active: true, msg: codeSentTitle });
          setEmail(obj.email);
          setIsEmailFill(true);
        }
      } else {
        setErrorMessage({ active: true, msg: notExistEmailTitle });
        setIsEmailFill(false);
      }
    } catch (err: any) {
      if (err && err.message === 'UPG') {
        setErrorMessage({ active: true, msg: t('upgError') });
      } else if (err && err.message === 'UPA') {
        setErrorMessage({ active: true, msg: t('upaError') });
      } else if (err && err.message === 'UPB') {
        setErrorMessage({ active: true, msg: t('upbError') });
      } else if (err && err.message) {
        setErrorMessage({ active: true, msg: err.message });
      }
    }
  }, [
    t,
    codeSentTitle,
    createCode,
    notExistEmailTitle,
    userEmailExist
  ]);

  const onSubmitCode = useCallback(async (obj: ForgotPasswordCode) => {
    try {
      const { data } = await validateCode({
        variables: {
          input: {
            email,
            value: parseInt(obj.code, 10),
            type: CodeType.EMAIL,
          },
        },
      });
      if (data.validateCode) {
        setIsCodeFill(true);
        setCode(obj.code);
      } else {
        setErrorMessage({ active: true, msg: incorrectCodeTitle });
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  }, [email, validateCode, incorrectCodeTitle])

  const onSubmitPassword = useCallback(async (obj: ForgotPasswordData) => {
    try {
      const { data } = await userUpdatePassword({
        variables: {
          input: {
            email,
            code: parseInt(code, 10),
            password: obj.password,
          },
        },
      });
      if (data.userUpdatePassword) {
        navigate('/login', { replace: true });
        setSuccessMessage({ active: true, msg: passwordSentTitle });
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  }, [
    navigate,
    email,
    userUpdatePassword,
    code,
    passwordSentTitle
  ]);

  const submitFC = useCallback(() => {
    if (!isEmailFill && !isCodeFill) {
      return handleSubmit(onSubmitEmail);
    } else if (isEmailFill && !isCodeFill) {
      return handleCode(onSubmitCode)
    } else if (isEmailFill && isCodeFill) {
      return handlePassword(onSubmitPassword);
    }
  }, [
    isEmailFill,
    isCodeFill,
    handleCode,
    handlePassword,
    handleSubmit,
    onSubmitCode,
    onSubmitEmail,
    onSubmitPassword
  ]);

  const getTitleButton = useCallback(() => {
    if (!isEmailFill && !isCodeFill) {
      return formNextTrad;
    } else if (isEmailFill && !isCodeFill) {
      return formValidateCodeTrad;
    } else if (isEmailFill && isCodeFill) {
      return formNextTrad;
    }
  }, [
    formValidateCodeTrad,
    formNextTrad,
    isEmailFill,
    isCodeFill,
  ]);

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const emailRules = useMemo(() => ({
    required: formErrorRequiredEmailTrad,
    pattern: {
      value: EMAIL_REGEX,
      message: formErrorPatternEmailTrad,
    },
  }), [
    formErrorRequiredEmailTrad,
    formErrorPatternEmailTrad,
  ]);

  const codeRules = useMemo(() => ({
    required: formErrorRequiredCodeTrad,
    minLength: {
      value: 4,
      message: formErrorMinLengthCodeTrad,
    },
  }), [
    formErrorRequiredCodeTrad,
    formErrorMinLengthCodeTrad,
  ]);

  const passwordRules = useMemo(() => ({
    required: formErrorRequiredPasswordTrad,
    minLength: {
      value: 8,
      message: formErrorMinLengthPasswordTrad,
    },
    pattern: {
      value: PASSWORD_REGEX,
      message: formErrorPatternPasswordTrad,
    },
  }), [
    formErrorRequiredPasswordTrad,
    formErrorMinLengthPasswordTrad,
    formErrorPatternPasswordTrad,
  ]);

  const formSignUpSx = useMemo(() => ({
    marginLeft: isMobile ? '1vw' : '0.5vw',
  }), [isMobile])

  return (
    <Grid
      container
      alignItems="center"
      sx={styles.container}
    >
      <Container
        maxWidth="sm"
        disableGutters={isMobile}
      >
        <Paper
          elevation={3}
          sx={styles.paper}>
          <Box mx={2} my={2}>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                  {recoverYourAccount}
                </Typography>
              </Grid>
              <Grid item mt={3} xs={12}>
                <CustomInput
                  name="email"
                  placeholder={emailTrad}
                  control={control}
                  label={'Email'}
                  rules={emailRules}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              {isEmailFill ? (
                <Grid item xs={12} mt={2}>
                  <CustomInput
                    name="code"
                    label="code"
                    placeholder="code"
                    disabled={!isEmailFill}
                    control={controlCode}
                    rules={codeRules}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              ) : (<></>)}
              {isEmailFill && isCodeFill ? (
                <Grid item mt={2} xs={12}>
                  <CustomInput
                    name="password"
                    control={controlPassword}
                    disabled={!isCodeFill}
                    label={formPasswordTrad}
                    placeholder={formPasswordTrad}
                    rules={passwordRules}
                    fullWidth
                    variant="outlined"
                    type="password"
                    required
                  />
                  <Typography variant="body2" mt={1}>
                    {formResumeTitleTrad}
                  </Typography>
                </Grid>
              ) : (<></>)}
              <Grid
                item
                xs={12}
                mt={2}
              >
                <Button
                  variant="contained"
                  onClick={submitFC()}
                  color="primary"
                  fullWidth
                  sx={styles.validateButton}
                >
                  <Typography fontWeight="bold">
                    {getTitleButton()}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                mt={1}
                mb={2}
                xs={12}
                flexDirection="row"
                display="flex"
                justifyContent="flex-end"
              >
                <Typography variant="body2" display="inline">
                  {formSignUpQuestionTrad}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  display="inline"
                  sx={formSignUpSx}
                >
                  <Link to="/signup" style={styles.linkTypo}>
                    {formSignUpTrad}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Snackbar
          anchorOrigin={styles.anchorOrigin}
          open={errorMessage.active}
          autoHideDuration={3000}
          message={errorMessage.msg}
          onClose={onCloseErrorSnack}
        >
          <Alert severity="error" sx={styles.alert}>
            {errorMessage.msg}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={styles.anchorOrigin}
          open={successMessage.active}
          autoHideDuration={3000}
          message={successMessage.msg}
          onClose={onCloseSuccessSnack}
        >
          <Alert severity="success" sx={styles.alert}>
            {successMessage.msg}
          </Alert>
        </Snackbar>
      </Container>
    </Grid>
  );
});

export default ForgotPassword;
