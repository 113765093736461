import React, { useCallback, useMemo } from 'react';
import {
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import styles from './style';

interface FooterProps {
  isMobile: boolean;
  os: string;
  innerHeight: number;
}

const Footer = React.memo((props: FooterProps) => {
  const { isMobile, innerHeight, os } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const allRightReservedTrad = t(`allRightsReserved`);
  const aboutTrad = t('about');
  const contactUsTrad = t('contactUs');
  const cguTrad = t('cgu');
  const privacyWebsiteTrad = t('privacyWebsite');
  const privacyMobileTrad = t('privacyMobile');

  const getHeightFooter = useCallback(() => {
    let h = '4.5rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '4.2rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '3rem';
    } else if (innerHeight >= 660 && innerHeight <= 734) {
      h = '4.2rem';
    } else if (innerHeight >= 735) {
      h = '4.5rem';
    }
    return h;
  }, [innerHeight, os]);

  const containerMemo = useMemo(() => ({
    height: isMobile ? getHeightFooter() : '4.1rem',
    backgroundColor: '#18181b',
  }), [isMobile, getHeightFooter]);

  const onClickToTerms = useCallback(() => {
    navigate('/terms', { replace: true });
  }, [navigate]);
  const onClickToPrivacy = useCallback(() => {
    navigate('/privacy', { replace: true });
  }, [navigate]);
  const onClickToAbout = useCallback(() => {
    navigate('/about', { replace: true });
  }, [navigate]);
  const onClickToContact = useCallback(() => {
    navigate('/contact', { replace: true });
  }, [navigate]);

  const justifyReserved = useMemo(() => isMobile ? 'center' : 'none', [isMobile]);
  const justifyIcon = useMemo(() => isMobile ? 'center' : 'flex-end', [isMobile]);

  return (
    <Grid
      container
      sx={containerMemo}
      alignItems="center"
    >
      <Container
        maxWidth="lg"
      >
        <Grid
          container
          direction='row'
          alignItems="center"
        >

          {!isMobile && (
            <Grid
              item
              display="flex"
              justifyContent={justifyReserved}
              sm={2.75}
            >
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                {allRightReservedTrad}
              </Typography>
            </Grid>
          )}

          {!isMobile && (
            <Grid
              item
              display="flex"
              sm={6}
            >
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                onClick={onClickToContact}
                sx={styles.cursor}
              >
                {contactUsTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                onClick={onClickToTerms}
                sx={styles.cursor}
              >
                {cguTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                onClick={onClickToPrivacy}
                sx={styles.cursor}
              >
                {privacyWebsiteTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                onClick={onClickToAbout}
                sx={styles.cursor}
              >
                {aboutTrad}
              </Typography>
            </Grid>
          )}

          {!isMobile && (
            <Grid
              item
              display="flex"
              sm={3.25}
              justifyContent="flex-end"
            >
              <Grid
                item
                display="flex"
                sm={2}
                justifyContent={justifyIcon}
              >
                <Link
                  href="https://discord.gg/crKwB8UrQJ"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon icon={faDiscord} size="2x" color="white" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                sm={2}
                justifyContent={justifyIcon}
              >
                <Link
                  href="https://twitter.com/SoSkills42"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon icon={faTwitter} size="2x" color="white" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                sm={2}
                justifyContent={justifyIcon}
                sx={styles.hover}
              >
                <Link
                  href="https://www.facebook.com/gaming/soskills.io"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" color="white" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                sm={2}
                justifyContent={justifyIcon}
              >
                <Link
                  href="https://www.instagram.com/soskills.io/"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                sm={2}
                justifyContent={justifyIcon}
              >
                <Link
                  href="https://www.linkedin.com/company/soskills/?viewAsMember=true"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon icon={faLinkedin} size="2x" color="white" />
                </Link>
              </Grid>
            </Grid>
          )}

          {isMobile && (
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={styles.heightIconMobile}
            >
              <Grid
                item
                display="flex"
                xs={2}
                justifyContent="center"
              >
                <Link
                  href="https://twitter.com/SoSkills42"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={styles.fontAwesomeIconMobile}
                    color="white"
                  />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                xs={2}
                justifyContent="center"
              >
                <Link
                  href="https://discord.gg/crKwB8UrQJ"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon
                    icon={faDiscord}
                    style={styles.fontAwesomeIconMobile}
                    color="white"
                  />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                xs={2}
                justifyContent="center"
                sx={styles.hover}
              >
                <Link
                  href="https://www.instagram.com/soskills.io/"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={styles.fontAwesomeIconMobile}
                    color="white"
                  />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                xs={2}
                justifyContent="center"
              >
                <Link
                  href="https://www.facebook.com/gaming/soskills.io"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={styles.fontAwesomeIconMobile}
                    color="white"
                  />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                xs={2}
                justifyContent="center"
              >
                <Link
                  href="https://www.linkedin.com/company/soskills/?viewAsMember=true"
                  target="_blank"
                  color="inherit"
                  sx={styles.hover}
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={styles.fontAwesomeIconMobile}
                    color="white"
                  />
                </Link>
              </Grid>
            </Grid>
          )}

          {isMobile && (
            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={styles.heightTextMobileSection}
              xs={12}
            >
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                fontFamily="Russo One"
                onClick={onClickToContact}
                sx={styles.cursor}
              >
                {contactUsTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                fontFamily="Russo One"
                onClick={onClickToTerms}
                sx={styles.cursor}
              >
                {cguTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                fontFamily="Russo One"
                onClick={onClickToPrivacy}
                sx={styles.cursor}
              >
                {privacyMobileTrad}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
              >
                &nbsp; | &nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="white"
                fontWeight="medium"
                fontFamily="Russo One"
                onClick={onClickToAbout}
                sx={styles.cursor}
              >
                {aboutTrad}
              </Typography>
            </Grid>
          )}

        </Grid>

      </Container>
    </Grid>
  );
});

export { Footer };
