import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useManualQuery } from 'graphql-hooks';
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
  Button,
  Snackbar,
} from '@mui/material/';
import { useForm } from 'react-hook-form';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import ReactPlayer from 'react-player';
import HoverVideoPlayer from 'react-hover-video-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlaystation,
  faXbox,
} from '@fortawesome/free-brands-svg-icons';
import {
  faComputer,
  faInfinity,
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty, omit } from 'lodash';
import { useAtom } from 'jotai';

import {
  Game,
  Video,
  UserRoles,
  Report,
  ReportStatus,
} from '../../../types';
import { CustomInput } from '../../CustomInput';
import { REPORT_UPDATE_STATUS } from '../../../graphql/report/mutation';
import { GAMES_BY_CATEGORY, GAME_BY_NAME } from '../../../graphql/game';
import { CATEGORIES } from '../../../graphql/category/query';
import { VIDEO } from '../../../graphql/video/query';
import {
  PUBLISH_VIDEO,
  DELETE_DRAFT_VIDEO_ON_AWS,
  SUSPEND_VIDEO,
  UPDATE_VIDEO,
  VIDEO_SIGN_URL,
  VIDEO_DRAFT_V2,
} from '../../../graphql/video/mutation';
import { getModeTrad } from '../../../helpers/format';
import { Context, useAuth } from '../../../context/auth';
import {
  isMobileAtom,
  isReportUpdatedAtom,
  isVideoUploadedAtom,
  isVideoSuspendedAtom,
} from '../../../atoms';
import styles from './style';

interface VideoPreview {
  id?: string;
  file?: any;
  path?: string;
  filename?: string;
}

interface AddVideoModalProps {
  isAddVideoModalVisible: boolean;
  handleIsAddVideoModalVisible: () => void;
  videoPreview: VideoPreview;
  report?: Report;
}

interface UploadFormData {
  description: string;
}

const AddVideoModal: React.FC<AddVideoModalProps> = React.memo((props) => {
  const {
    videoPreview,
    isAddVideoModalVisible,
    handleIsAddVideoModalVisible,
    report,
  } = props;
  const { t } = useTranslation();
  const { user } = useAuth() as Context;
  const [isMobile] = useAtom(isMobileAtom);
  const [, setIsVideoUploaded] = useAtom(isVideoUploadedAtom);
  const [, setIsVideoSuspended] = useAtom(isVideoSuspendedAtom);
  const [, setIsReportUpdated] = useAtom(isReportUpdatedAtom);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [isVideoViewed, setIsVideoViewed] = useState(!isEmpty(videoPreview) && videoPreview.id ? true : false);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [loadingNext, setLoadingNext] = useState(false);
  const [games, setGames] = useState([] as Game[]);
  const [video, setVideo] = useState({} as Partial<Video>);
  const [accordion, setAccordion] = useState({
    platform: false,
    game: false,
    ks: false,
    weapon: false,
    mode: false,
  });
  const { control, handleSubmit } = useForm<UploadFormData>();
  const [suspendVideo] = useMutation(SUSPEND_VIDEO);
  const [publishVideo] = useMutation(PUBLISH_VIDEO);
  const [videoUpdate] = useMutation(UPDATE_VIDEO);
  const [videoSignUrl] = useMutation(VIDEO_SIGN_URL);
  const [videoDraftV2] = useMutation(VIDEO_DRAFT_V2);
  const [reportUpdateStatus] = useMutation(REPORT_UPDATE_STATUS);
  const [gamesByCategoryId] = useManualQuery(GAMES_BY_CATEGORY);
  const [getVideo] = useManualQuery(VIDEO);
  const [categories] = useManualQuery(CATEGORIES);
  const [deleteDraftVideoOnAWS] = useManualQuery(DELETE_DRAFT_VIDEO_ON_AWS);
  const [gameByName] = useManualQuery(GAME_BY_NAME);

  const gameTrad = t('uploadForm.placeholderOptions.game');
  const weaponTrad = t('uploadForm.placeholderOptions.weapon');
  const platformTrad = t('uploadForm.placeholderOptions.platform');
  const ksTrad = t('modal.KsModal');
  const uploadFormTitle = t('uploadForm.title');
  const detailsVideoFormTitle = t('uploadForm.detailsVideotitle');
  const errorUpload = t('errorUpload');
  const cancelTrad = t('cancel');
  const formNextTrad = t('form.next');
  const descriptionTrad = t('description');
  const formErrorRequiredDescriptionTrad = t('form.error.required.description');
  const formErrorMaxLengthDescriptionTrad = t('form.error.maxLength.description');

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const boxStyle = useMemo(() => ({
    position: 'absolute' as 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    bgcolor: 'white',
    borderRadius: 2,
    border: '2px solid black',
    boxShadow: 24,
    pt: 2,
    pb: isVideoViewed ? 0 : 2,
    px: 4,
    overflow: 'scroll-y',
  }), [isVideoViewed]);

  async function getGamesByCategory(categoryId: string) {
    const { data } = await gamesByCategoryId({
      variables: {
        categoryId,
      },
    });
    if (data.gamesByCategoryId) {
      setGames(data.gamesByCategoryId);
    }
  }

  async function getCategories() {
    const { data } = await categories();
    if (data && data.categories) {
      const { data: res } = await gameByName({
        variables: {
          name: 'Call of Duty Modern Warfare 2',
        },
      });
      await getGamesByCategory(data.categories[0].id);
      if (!videoPreview.id) {
        setVideo({
          ...video,
          categoryId: data.categories[0].id,
          categoryName: data.categories[0].name,
          filename: videoPreview.filename,
          kills: 1,
          game: res.gameByName,
          gameId: res.gameByName.id,
          gameName: res.gameByName.name,
          mode: res.gameByName.modes[0],
          platform: 'Playstation',
          weapon: res.gameByName.weapons[0],
        });
      }
      setLoading(false);
    }
  }

  async function getVideoById() {
    const { data } = await getVideo({
      variables: {
        id: videoPreview.id
      }
    }) as any;
    if (data.video) {
      setVideo(data.video);
    } else {
      setErrorMessage({ active: true, msg: 'Invalid Video' });
      handleIsAddVideoModalVisible();
    }
  }

  useEffect(() => {
    setLoading(true);
    if (videoPreview.id) {
      getVideoById();
      getCategories();
    } else {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyVideo = useCallback(() => {
    if (video && video.categoryName && video.filename
      && video.kills && video.mode && video.gameName
      && video.platform && video.weapon) {
      return true;
    }
    return false;
  }, [video]);

  const getGameIdByName = (name: string) => (games.find((game) => game.name === name) as Game).id;

  const onRegisterPressed = async (obj: UploadFormData) => {
    try {
      if (obj.description && verifyVideo()) {
        setLoadingNext(true);
        setTimeout(async () => {
          const { data } = await publishVideo({
            variables: {
              input: {
                ...omit(video, ['filename', 'game']),
                from: 'pc',
                description: obj.description,
                gameId: getGameIdByName(video.gameName!)!,
                with: 'gallery',
              },
            },
          });
          if (data.publishVideo) {
            setLoadingNext(false);
            setSuccessMessage({ active: true, msg: t('videoUploaded') });
            setIsVideoUploaded(true);
            handleIsAddVideoModalVisible();
          } else {
            setErrorMessage({ active: true, msg: errorUpload });
          }
        }, 500);
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  };

  const onSuspendVideo = useCallback(async () => {
    if (!isEmpty(videoPreview) && videoPreview.id && user.role === UserRoles.ADMIN) {
      const { data } = await suspendVideo({
        variables: {
          id: videoPreview.id,
        }
      })
      if (data.suspendVideo) {
        setIsVideoSuspended(true);
        setSuccessMessage({ active: true, msg: t('videoSuspended') });
      }
    }
  }, [
    t,
    setSuccessMessage,
    setIsVideoSuspended,
    videoPreview,
    suspendVideo,
    user.role,
  ]);

  const onReportVideo = useCallback(async (status: ReportStatus) => {
    if (!isEmpty(video) && video.id && user.role === UserRoles.ADMIN && !isEmpty(report)) {
      const { data } = await reportUpdateStatus({
        variables: {
          id: report!.id,
          status,
        }
      })
      if (data.reportUpdateStatus) {
        setIsReportUpdated(true);
        handleIsAddVideoModalVisible();
      }
    }
  }, [
    handleIsAddVideoModalVisible,
    reportUpdateStatus,
    report,
    setIsReportUpdated,
    user.role,
    video,
  ]);

  const onUpdateVideo = useCallback(async (obj: UploadFormData) => {
    try {
      if (obj.description && verifyVideo()) {
        setLoadingNext(true);
        const { data } = await videoUpdate({
          variables: {
            input: {
              id: video.id,
              kills: video.kills,
              gameId: video.gameId,
              gameName: video.gameName,
              mode: video.mode,
              platform: video.platform,
              weapon: video.weapon,
              description: obj.description,
              userId: video.userId,
            },
          },
        });
        if (data.videoUpdate) {
          setLoadingNext(false);
          setSuccessMessage({ active: true, msg: t('videoUpdated') });
        }
      }
    } catch (err: any) {
      setLoadingNext(false);
      setErrorMessage({ active: true, msg: err.message })
    }
  }, [
    video,
    verifyVideo,
    videoUpdate,
    t,
  ]);

  const onFilterWeapon = useCallback(async (weapon: string) => {
    if (weapon !== video.weapon) {
      setVideo({
        ...video,
        weapon,
      });
      setAccordion({
        ...accordion,
        weapon: false,
      });
    }
  }, [video, accordion]);

  const onFilterGame = useCallback(async (obj: Game) => {
    if (obj.name !== video.gameName) {
      const { data } = await gameByName({
        variables: {
          name: obj.name,
        },
      });
      setVideo({
        ...video,
        gameName: obj.name,
        game: data.gameByName,
        gameId: data.gameByName.id,
        kills: 1,
        mode: data.gameByName.modes[0],
        weapon: data.gameByName.weapons[0],
      });
      setAccordion({
        ...accordion,
        game: false,
      });
    }
  }, [video, accordion, gameByName]);

  const onFilterKs = useCallback(async (kills: number) => {
    if (kills !== video.kills) {
      setVideo({
        ...video,
        kills,
      });
      setAccordion({
        ...accordion,
        ks: false,
      });
    }
  }, [video, accordion]);

  const onFilterMode = useCallback(async (mode: string) => {
    if (mode !== video.mode) {
      setVideo({
        ...video,
        mode,
      });
      setAccordion({
        ...accordion,
        mode: false,
      });
    }
  }, [video, accordion]);

  const onFilterPlatform = useCallback(async (platform: string) => {
    if (platform !== video.platform) {
      setVideo({
        ...video,
        platform,
      });
      setAccordion({
        ...accordion,
        platform: false,
      });
    }
  }, [video, accordion]);

  const deleteFile = useCallback(async () => {
    const { data } = await deleteDraftVideoOnAWS({
      variables: {
        id: video.id,
      },
    });
    if (data.deleteDraftVideoOnAWS) {
      setIsVideoViewed(false);
      setVideo({
        ...omit(video, ['id']),
        filename: '',
      });
      handleIsAddVideoModalVisible();
    }
  }, [
    video,
    deleteDraftVideoOnAWS,
    handleIsAddVideoModalVisible,
  ]);

  const uploadVideo = useCallback(async () => {
    try {
      setLoadingUpload(true);
      const { data } = await videoSignUrl({
        variables: {
          filename: videoPreview.file.name,
        }
      })
      if (data.videoSignUrl) {
        const myHeaders = new Headers({ 'Content-Type': 'video/*' });
        const response = await fetch(data.videoSignUrl, {
          method: 'PUT',
          headers: myHeaders,
          body: videoPreview.file,
        });
        if (response.statusText === 'OK' && response.status === 200) {
          const { data: res } = await videoDraftV2({
            variables: {
              input: {
                filename: videoPreview.file.name,
                originalSize: videoPreview.file.size,
              }
            }
          })
          if (res.videoDraftV2) {
            setVideo({ ...video, id: res.videoDraftV2.id });
            //setTimeout(() => {
            setIsVideoViewed(true);
            setLoadingUpload(false);
            //}, 1000);
          }
        }
      }
    } catch (err: any) {
      setLoadingUpload(false);
      setErrorMessage({ active: true, msg: err.message })
    }
  }, [
    video,
    setVideo,
    videoSignUrl,
    videoPreview,
    videoDraftV2,
  ]);

  const title = useMemo(() => {
    let str = uploadFormTitle;
    if (videoPreview && !isEmpty(videoPreview) && videoPreview.id && user.role === 'User') {
      str = detailsVideoFormTitle;
    } else if (
      videoPreview
      && !isEmpty(videoPreview)
      && videoPreview.id
      && video
      && !isEmpty(video)
      && video.user
      && !isEmpty(video.user)
      && user.role === 'Admin'
    ) {
      str = `Video ${video.id} by ${video.user!.username} / ${video.user!.email}`;
      if (video.user!.twitter) {
        str += ` / ${video.user!.twitter}`;
      }
    }
    return str;
  }, [
    video,
    detailsVideoFormTitle,
    uploadFormTitle,
    user,
    videoPreview,
  ]);

  const closeModal = useCallback(() => {
    if (!isVideoViewed) {
      setIsVideoViewed(false);
      setVideo({
        ...video,
        filename: '',
      });
      handleIsAddVideoModalVisible();
    } else {
      if (!isEmpty(video) && video.id) {
        deleteFile();
      }
    }
  }, [
    deleteFile,
    handleIsAddVideoModalVisible,
    isVideoViewed,
    video,
  ]);

  const loadingOverlay = useMemo(() => (
    <>
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    </>
  ), []);

  const descriptionRules = useMemo(() => ({
    required: formErrorRequiredDescriptionTrad,
    maxLength: {
      value: 60,
      message: formErrorMaxLengthDescriptionTrad,
    },
  }), [
    formErrorRequiredDescriptionTrad,
    formErrorMaxLengthDescriptionTrad,
  ]);

  return (
    <>
      {!loading && (
        <Modal
          hideBackdrop
          open={isAddVideoModalVisible}
          onClose={handleIsAddVideoModalVisible}
          style={styles.modal}
        >
          <Box sx={boxStyle}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" fontFamily="Anton" color="#212121">
                  {title}
                </Typography>
                <div
                  onClick={handleIsAddVideoModalVisible}
                  style={styles.closeRow as any}
                >
                  <IconButton
                    sx={styles.closeIcon}
                    component="span"
                    onClick={closeModal}
                    disabled={loadingNext}
                  >
                    <Close />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid container sx={styles.container}>
              {!isVideoViewed && video && videoPreview.filename && (
                <>
                  <Grid container direction="row">
                    <Grid item xs={12} display="flex" justifyContent="center" >
                      <ReactPlayer
                        controls={true}
                        url={videoPreview.path}
                      />
                    </Grid>
                    <Grid
                      mt={2}
                      mb={0.5}
                      container
                      direction="row"
                      columnSpacing={2}
                    >
                      <Grid item xs={6} display="flex" justifyContent="flex-end">
                        <Button
                          component="span"
                          variant="contained"
                          color="error"
                          sx={styles.cancelButton}
                          onClick={handleIsAddVideoModalVisible}
                        >
                          <Typography fontWeight="bold">
                            {cancelTrad}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          component="span"
                          variant="contained"
                          disabled={loadingUpload}
                          color="primary"
                          onClick={uploadVideo}
                          sx={styles.nextButton}
                        >
                          <Typography fontWeight="bold">
                            {formNextTrad}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {isVideoViewed && !isEmpty(video) && (
                <>
                  <Grid
                    item
                    mt={2}
                    xs={5}
                    display="flex"
                    justifyContent="center"
                  >
                    <HoverVideoPlayer
                      controls
                      crossOrigin="anonymous"
                      videoSrc={
                        video.id && videoPreview.id ?
                          `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.path}` :
                          videoPreview.path!
                      }
                      loop={true}
                      videoStyle={styles.videoStyle}
                      loadingOverlay={loadingOverlay}
                    />
                  </Grid>
                  <Grid item mt={2} xs={12}>
                    <CustomInput
                      name="description"
                      placeholder={descriptionTrad}
                      control={control}
                      label={'Description'}
                      rules={descriptionRules}
                      sx={styles.descriptionSx}
                      fullWidth
                      variant="outlined"
                      value={video.description}
                    />
                  </Grid>
                  <Grid item mt={2} xs={12}>
                    <Accordion expanded={accordion['platform']} onChange={() => {
                      setAccordion({
                        platform: !accordion['platform'] as boolean,
                        game: false,
                        ks: false,
                        weapon: false,
                        mode: false
                      });
                    }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row">
                          <Grid item xs={2} display="flex" justifyContent="flex-start">
                            {video.platform === 'Playstation' && (
                              <FontAwesomeIcon icon={faPlaystation} size="2x" />
                            )}
                            {video.platform === 'PC' && (
                              <FontAwesomeIcon icon={faComputer} size="2x" />
                            )}
                            {video.platform === 'Xbox' && (
                              <FontAwesomeIcon icon={faXbox} size="2x" />
                            )}
                          </Grid>
                          {!isMobile && (
                            <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                              <Typography fontWeight="bold">{platformTrad}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row" display="flex" justifyContent="center">
                          <Grid item xs={4} display="flex" justifyContent="center">
                            <FontAwesomeIcon
                              icon={faPlaystation}
                              size="2x"
                              onClick={() => onFilterPlatform('Playstation')}
                              style={styles.grab}
                            />
                          </Grid>
                          <Grid item xs={4} display="flex" justifyContent="center">
                            <FontAwesomeIcon
                              icon={faXbox}
                              size="2x"
                              onClick={() => onFilterPlatform('Xbox')}
                              style={styles.grab}
                            />
                          </Grid>
                          <Grid item xs={4} display="flex" justifyContent="center">
                            <FontAwesomeIcon
                              icon={faComputer}
                              size="2x"
                              onClick={() => onFilterPlatform('PC')}
                              style={styles.grab}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={accordion['game']} onChange={() => {
                      setAccordion({
                        platform: false,
                        game: !accordion['game'] as boolean,
                        ks: false,
                        weapon: false,
                        mode: false
                      });
                    }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row">
                          <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-start">
                            <img
                              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}games/${video.gameName}.webp`}
                              width={40}
                              height={40}
                              alt=""
                            />
                          </Grid>
                          {!isMobile && (
                            <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                              <Typography fontWeight="bold">{gameTrad}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row" rowSpacing={1}>
                          {games.map((game: Game) => {
                            return (
                              <Grid
                                item
                                xs={1.3}
                                display="flex"
                                justifyContent="center"
                                key={game.name}
                              >
                                <img
                                  onClick={() => onFilterGame(game)}
                                  src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}games/${game.name}.webp`}
                                  width={45}
                                  height={45}
                                  alt=""
                                  style={styles.grab}
                                />
                              </Grid>
                            )
                          })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={accordion['ks']} onChange={() => {
                      setAccordion({
                        platform: false,
                        game: false,
                        ks: !accordion['ks'] as boolean,
                        weapon: false,
                        mode: false
                      });
                    }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row">
                          <Grid item xs={2} display="flex" alignItems="center">
                            {video.kills! > 0 && video.kills! <= 6 && (
                              <>
                                <Grid container direction="row" ml={1}>
                                  <Grid item xs={2}>
                                    <Typography
                                      color="red"
                                      fontWeight="bold"
                                      fontSize="large"
                                    >
                                      x
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} display="flex" justifyContent="flex-start">
                                    <Typography
                                      color="black"
                                      fontWeight="bold"
                                      fontSize="large"
                                    >
                                      {video.kills}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            {video.kills === 42 && (
                              <>
                                <FontAwesomeIcon icon={faInfinity} size="2x" />
                              </>
                            )}
                          </Grid>
                          {!isMobile && (
                            <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                              <Typography fontWeight="bold">{ksTrad}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row" display="flex" justifyContent="center">
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(1)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" display="flex" justifyContent="center">
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    1
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(2)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={2}>
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    2
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(3)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" display="flex" justifyContent="center">
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    3
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(4)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" display="flex" justifyContent="center">
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    4
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(5)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" display="flex" justifyContent="center">
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    5
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5}>
                            <div onClick={() => onFilterKs(6)} style={{ cursor: 'grab' }}>
                              <Grid container direction="row" display="flex" justifyContent="center">
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Typography
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    x
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="large"
                                  >
                                    6
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={1.5} display="flex" alignItems="center" justifyContent="center">
                            <FontAwesomeIcon
                              icon={faInfinity}
                              style={styles.grab}
                              size="lg"
                              onClick={() => onFilterKs(42)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={accordion['weapon']} onChange={() => {
                      setAccordion({
                        platform: false,
                        game: false,
                        ks: false,
                        weapon: !accordion['weapon'] as boolean,
                        mode: false
                      });
                    }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row">
                          <Grid item xs={2} display="flex" alignItems="center">
                            <img
                              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}weapons/v2/${video.weapon}.webp`}
                              width={50}
                              height={50}
                              alt=""
                            />
                          </Grid>
                          {!isMobile && (
                            <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                              <Typography fontWeight="bold">{weaponTrad}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container rowSpacing={2}>
                          {video.game!.weapons.map((weapon: string) => {
                            return (
                              <Grid
                                key={weapon}
                                item
                                xs={1.2}
                              >
                                <Grid item xs={12} display="flex" justifyContent="center">
                                  <img
                                    style={styles.grab}
                                    onClick={() => onFilterWeapon(weapon)}
                                    src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}weapons/v2/${weapon}.webp`}
                                    width={50}
                                    height={50}
                                    alt=""
                                  />
                                </Grid>
                              </Grid>
                            )
                          })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={accordion['mode']} onChange={() => {
                      setAccordion({
                        platform: false,
                        game: false,
                        ks: false,
                        weapon: false,
                        mode: !accordion['mode'] as boolean
                      });
                    }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row">
                          <Grid item xs={2}>
                            <></>
                          </Grid>
                          <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
                            <Typography fontWeight="bold">{video.mode}</Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container columnSpacing={1} rowSpacing={1}>
                          {video.game!.modes.map((mode: string) => {
                            return (
                              <Grid
                                key={mode}
                                item
                                xs={3}
                              >
                                <div
                                  key={mode}
                                  style={styles.modeRow}
                                  onClick={() => onFilterMode(mode)}
                                >
                                  <Typography key={mode} variant="subtitle2" align="center" fontWeight="medium" color="white">
                                    {getModeTrad(mode, t)}
                                  </Typography>
                                </div>
                              </Grid>
                            )
                          })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {
                    !isEmpty(videoPreview)
                    && videoPreview.id
                    && user.role === UserRoles.ADMIN
                    && !report
                    && (
                      <Grid
                        item
                        xs={4}
                        mb={2}
                        mt={2}
                        display="flex"
                        justifyContent="center"
                      >
                        <LoadingButton
                          loading={loadingNext}
                          variant="contained"
                          disabled={loadingNext}
                          onClick={onSuspendVideo}
                          color="error"
                          fullWidth
                          sx={styles.loadingButtonSx}
                        >
                          <Typography fontFamily="Roboto" fontWeight="medium">
                            SUSPENDRE
                          </Typography>
                        </LoadingButton>
                      </Grid>
                    )}
                  {
                    !isEmpty(videoPreview)
                    && videoPreview.id
                    && user.role === UserRoles.ADMIN
                    && report
                    && !isEmpty(report)
                    && (
                      <Grid
                        item
                        xs={4}
                        mb={2}
                        mt={2}
                        display="flex"
                        justifyContent="center"
                      >
                        <LoadingButton
                          loading={loadingNext}
                          variant="contained"
                          disabled={loadingNext}
                          onClick={() => onReportVideo(ReportStatus.REJECTED)}
                          color="error"
                          fullWidth
                          sx={styles.loadingButtonSx}
                        >
                          <Typography fontFamily="Roboto" fontWeight="medium">
                            REJECT
                          </Typography>
                        </LoadingButton>
                      </Grid>
                    )}
                  <Grid
                    item
                    xs={!isEmpty(videoPreview) && videoPreview.id && user.role === UserRoles.ADMIN ? 4 : 6}
                    mb={2}
                    mt={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <LoadingButton
                      loading={loadingNext}
                      variant="contained"
                      disabled={loadingNext}
                      onClick={handleSubmit(videoPreview.id ? onUpdateVideo : onRegisterPressed)}
                      color="primary"
                      fullWidth
                      sx={styles.loadingButtonSx}
                    >
                      <Typography fontFamily="Roboto" fontWeight="medium">
                        {video.id && videoPreview.id ? t('save') : t('uploadForm.send')}
                      </Typography>
                    </LoadingButton>
                  </Grid>
                  {
                    !isEmpty(videoPreview)
                    && videoPreview.id
                    && user.role === UserRoles.ADMIN
                    && report
                    && !isEmpty(report)
                    && (
                      <Grid
                        item
                        xs={4}
                        mb={2}
                        mt={2}
                        display="flex"
                        justifyContent="center"
                      >
                        <LoadingButton
                          loading={loadingNext}
                          variant="contained"
                          disabled={loadingNext}
                          onClick={() => onReportVideo(ReportStatus.CLOSED)}
                          color="error"
                          fullWidth
                          sx={styles.loadingButtonSx}
                        >
                          <Typography fontFamily="Roboto" fontWeight="medium">
                            CLOSE
                          </Typography>
                        </LoadingButton>
                      </Grid>
                    )}
                </>
              )}
            </Grid>
            <Backdrop
              sx={styles.backdropSx}
              open={loadingNext || loadingUpload}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Modal>
      )}
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
    </>
  )
});

export default AddVideoModal;
