export const REPORTS_COUNT_BY_USER_ID = `
  query reportsCountByUserId($userId: ID!) {
    reportsCountByUserId(userId: $userId) {
      reportByComment
      reportByVideo
    }
  }
`;

export const REPORTS_COUNT_BY_REPORTER_ID = `
  query reportsCountByReporterId($reporterId: ID!) {
    reportsCountByReporterId(reporterId: $reporterId) {
      reportByComment
      reportByVideo
    }
  }
`;

export const REPORTS_STAT = `
  query reportsStat {
    reportsStat {
      reportCreated
      reportCreatedByDay
      reportCreatedByWeek
    }
  }
`;

export const REPORTS_PAGINATION = `
  query reportsPagination($input: ReportPaginationInput) {
    reportsPagination(input: $input) {
      docs {
        id
        createdAt
        deletedAt
        reason
        reporterId
        reporter {
          id
          email
          username
        }
        status
        userId
        user {
          id
          email
          username
        }
        commentId
        videoId
        type
      }
      totalDocs
      offset
      limit
      totalPages
      hasPrevPage
      hasNextPage
      page
      pagingCounter
      prevPage
      nextPage
    }
  }
`;
