export const VIDEOS_STAT = `
  query videosStat {
    videosStat {
      videoCreated
      videoCreatedByDay
      videoCreatedByWeek
    }
  }
`;

export const VIDEOS_TOP_PAGINATION = `
  query videosTopPagination($input: VideoTopPaginationInput) {
    videosTopPagination(input: $input) {
      docs {
        codeshare
        createdAt
        id
        filename
        description
        kills
        mode
        likes
        gameName
        gameId
        height
        categoryId
        categoryName
        comments
        top
        path
        stream
        shares
        platform
        user {
          avatar
          email
          twitter
          username
        }
        userId
        width
        weapon
      }
      totalDocs
      offset
      limit
      totalPages
      hasPrevPage
      hasNextPage
      page
      pagingCounter
      prevPage
      nextPage
    }
  }
`;

export const THUMBNAIL_VIDEOS_PAGINATION = `
  query videosPagination($input: VideoPaginationInput) {
    videosPagination(input: $input) {
      docs {
        codeshare
        createdAt
        id
        filename
        description
        kills
        mode
        likes
        isFollowed
        gameName
        gameId
        height
        categoryId
        categoryName
        comments
        thumbnail
        top
        path
        shares
        platform
        user {
          email
          username
          twitter
        }
        userId
        views
        width
        weapon
      }
      totalDocs
      offset
      limit
      totalPages
      hasPrevPage
      hasNextPage
      page
      pagingCounter
      prevPage
      nextPage
    }
  }
`;

export const VIDEO = `
  query video($id: ID!) {
    video(id: $id) {
      createdAt
      codeshare
      id
      filename
      description
      kills
      mode
      likes
      isFollowed
      isLiked
      isViewed
      game {
        id
        name
        modes
        weapons
      }
      gameName
      gameId
      height
      categoryId
      categoryName
      comments
      path
      platform
      shares
      top
      user {
        id
        email
        avatar
        username
        twitter
      }
      userId
      views
      width
      weapon
    }
  }
`;

export const VIDEO_BY_CODESHARE = `
  query videoByCodeshare($codeshare: String!) {
    videoByCodeshare(codeshare: $codeshare) {
      createdAt
      codeshare
      id
      filename
      description
      kills
      mode
      likes
      isFollowed
      isLiked
      isViewed
      game {
        id
        name
        modes
        weapons
      }
      gameName
      gameId
      height
      categoryId
      categoryName
      comments
      path
      platform
      shares
      stream
      top
      user {
        id
        email
        avatar
        username
        twitter
      }
      userId
      views
      width
      weapon
    }
  }
`;
