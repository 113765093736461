const styles = {
  height: {
    height: '100%',
  },
  paperReportCreated: {
    backgroundColor: '#2B81B8',
    width: '95%',
    height: '9vh',
  },
  paperReportCreatedByWeek: {
    backgroundColor: '#5FA7D5',
    width: '95%',
    height: '9vh',
  },
  paperReportCreatedByDay: {
    backgroundColor: '#88C9F3',
    width: '95%',
    height: '9vh',
  },
};

export default styles;
