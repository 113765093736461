import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useManualQuery, useMutation } from 'graphql-hooks';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material/';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Close from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {
  USER_EMAIL_EXIST,
  USER_USERNAME_EXIST,
  USER_BY_ID,
} from '../../../graphql/user/query';
import {
  USER_UPDATE,
  USER_SUSPEND,
} from '../../../graphql/user/mutation';
import {
  REPORTS_COUNT_BY_REPORTER_ID,
  REPORTS_COUNT_BY_USER_ID,
} from '../../../graphql/report/query';
import { Context, useAuth } from '../../../context/auth';
import { DatePick } from '../../DatePick';
import { CustomInput } from '../../CustomInput';
import { UserRoles, User } from '../../../types';
import styles from './style';

interface IObjectKeys {
  [key: string]: string | Date;
}

interface ProfileFormData extends IObjectKeys {
  email: string;
  dateOfBirth: Date;
  username: string;
  bio: string;
}

interface UserModalProps {
  isUserModalVisible: boolean;
  handleIsUserModalVisible: () => void;
  userId: string;
}

interface ReportByUser {
  reportByComment: number;
  reportByVideo: number;
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const UserModal: React.FC<UserModalProps> = React.memo((props) => {
  const {
    isUserModalVisible,
    handleIsUserModalVisible,
    userId,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState({} as User);
  const { user: userAuth } = useAuth() as Context;
  const { control, handleSubmit } = useForm<ProfileFormData>();
  const [reportByUser, setReportByUser] = useState({} as ReportByUser);
  const [reportByReporter, setReportByReporter] = useState({} as ReportByUser);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [usernameAlreadyExist, setUsernameAlreadyExist] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [userUpdate] = useMutation(USER_UPDATE);
  const [userSuspend] = useMutation(USER_SUSPEND);
  const [reportsCountByReporterId] = useManualQuery(REPORTS_COUNT_BY_REPORTER_ID);
  const [reportsCountByUserId] = useManualQuery(REPORTS_COUNT_BY_USER_ID);
  const [userById] = useManualQuery(USER_BY_ID);
  const [userEmailExist] = useManualQuery(USER_EMAIL_EXIST);
  const [userUsernameExist] = useManualQuery(USER_USERNAME_EXIST);
  const maxDate = new Date();

  const formErrorRequiredUsernameTrad = t('form.error.required.username');
  const formErrorMinLengthUsernameTrad = t('form.error.minLength.username');
  const formErrorMaxLengthUsernameTrad = t('form.error.maxLength.username');
  const formErrorRequiredEmailTrad = t('form.error.required.email');
  const formErrorPatternEmailTrad = t('form.error.pattern.email');
  const formPlaceholderBioTrad = t('form.placeholder.bio');
  const formErrorMinLengthBioTrad = t('form.error.minLength.bio');
  const formErrorMaxLengthBioTrad = t('form.error.maxLength.bio');
  const addYourDateOfBirthTrad = t('addYourDateOfBirth');
  const formPlaceholderAvatarTrad = t('form.placeholder.avatar');
  const emailTrad = t('email');
  const usernameTrad = t('username');
  const saveTrad = t('save');

  if (userAuth && userAuth.role !== UserRoles.ADMIN) {
    navigate('/', { replace: true });
  }

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const isValidUsername = async (username: string) => {
    const { data } = await userUsernameExist({
      variables: {
        username,
      },
    }) as any;
    if (data.userUsernameExist && !usernameAlreadyExist) {
      setUsernameAlreadyExist(true);
    } else if (!data.userUsernameExist && usernameAlreadyExist) {
      setUsernameAlreadyExist(false);
    }
  };
  const isValidEmail = async (email: string) => {
    const { data } = await userEmailExist({
      variables: {
        email,
      },
    }) as any;
    if (data.userEmailExist && !emailAlreadyExist) {
      setEmailAlreadyExist(true);
    } else if (!data.userEmailExist && emailAlreadyExist) {
      setEmailAlreadyExist(false);
    }
  };

  const debounceUsernameFn = debounce(isValidUsername, 300);
  const debounceEmailFn = debounce(isValidEmail, 300);

  const onChangeUsername = (e: any) => {
    debounceUsernameFn(e.target.value);
  };

  const onChangeEmail = (e: any) => {
    debounceEmailFn(e.target.value);
  };

  const getObjModified = (obj: ProfileFormData) => {
    const input = {} as ProfileFormData;
    const keys = Object.keys(obj);
    keys.forEach((k) => {
      if (obj[k] !== (user as unknown as ProfileFormData)[k]) {
        input[k] = obj[k];
      }
    })
    return input;
  };

  const onUpdateUser = async (obj: ProfileFormData) => {
    const input = getObjModified(obj);
    try {
      if (!usernameAlreadyExist && !emailAlreadyExist && !isEmpty(input)) {
        const { data } = await userUpdate({
          variables: {
            input: { ...input, userId: user.id },
          }
        })
        if (data && data.userUpdate) {
          setUser(data.userUpdate);
          setSuccessMessage({ active: true, msg: t('MAJ') });
        }
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  };

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const onSuspendUser = useCallback(async () => {
    const { data } = await userSuspend({
      variables: {
        id: user.id,
      },
    });
    if (data.userSuspend) {
      setUser({
        ...user,
        deletedAt: data.userSuspend.deletedAt,
      })
      setSuccessMessage({ active: true, msg: t('MAJ') });
    }
  }, [t, userSuspend, user]);

  const getData = useCallback(async () => {
    const { data } = await userById({
      variables: {
        id: userId
      },
    });
    const { data: rcu } = await reportsCountByUserId({
      variables: {
        userId
      },
    });
    const { data: rcr } = await reportsCountByReporterId({
      variables: {
        reporterId: userId
      },
    });
    if (rcr.reportsCountByReporterId) {
      setReportByReporter(rcr.reportsCountByReporterId);
    }
    if (rcu.reportsCountByUserId) {
      setReportByUser(rcu.reportsCountByUserId);
    }
    if (data.userById) {
      setUser(data.userById);
    }
  }, [
    reportsCountByReporterId,
    reportsCountByUserId,
    userById,
    userId,
  ]);

  const usernameRules = useMemo(() => ({
    required: formErrorRequiredUsernameTrad,
    minLength: {
      value: 3,
      message: formErrorMinLengthUsernameTrad,
    },
    maxLength: {
      value: 24,
      message: formErrorMaxLengthUsernameTrad,
    },
  }), [
    formErrorRequiredUsernameTrad,
    formErrorMinLengthUsernameTrad,
    formErrorMaxLengthUsernameTrad,
  ]);

  const emailRules = useMemo(() => ({
    required: formErrorRequiredEmailTrad,
    pattern: {
      value: EMAIL_REGEX,
      message: formErrorPatternEmailTrad,
    },
  }), [
    formErrorRequiredEmailTrad,
    formErrorPatternEmailTrad,
  ]);

  const bioRules = useMemo(() => ({
    minLength: {
      value: 4,
      message: formErrorMinLengthBioTrad,
    },
    maxLength: {
      value: 60,
      message: formErrorMaxLengthBioTrad,
    },
  }), [formErrorMinLengthBioTrad, formErrorMaxLengthBioTrad]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(user) && (
        <Modal
          hideBackdrop
          open={isUserModalVisible}
          onClose={handleIsUserModalVisible}
          style={styles.modal}
        >
          <Box sx={styles.box}>
            <Grid container spacing={2} py={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Avatar
                  alt="profile"
                  src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${user.avatar}`}
                  sx={styles.avatar}
                />
                <div
                  onClick={handleIsUserModalVisible}
                  style={styles.closeRow as any}
                >
                  <IconButton
                    sx={styles.closeIcon}
                    component="span"
                  >
                    <Close />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography fontWeight="bold">
                  {user.deletedAt ? `Utilisateur suspendu depuis le ${moment(user.deletedAt).locale("fr").format('DD/MM/YYYY')}` : 'Utilisateur Actif'}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography variant="body2">
                  Signalé : {reportByUser.reportByComment} commentaires et {reportByUser.reportByVideo} videos
                </Typography>
                <Typography variant="body2">
                  Signalement : {reportByReporter.reportByComment} commentaires et {reportByReporter.reportByVideo} videos
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  alreadyExist={usernameAlreadyExist}
                  name="username"
                  fullWidth
                  label={usernameTrad}
                  control={control}
                  value={user.username}
                  onChange={onChangeUsername}
                  placeholder={usernameTrad}
                  rules={usernameRules}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  alreadyExist={emailAlreadyExist}
                  name="email"
                  label='Email'
                  control={control}
                  value={user.email}
                  onChange={onChangeEmail}
                  placeholder={emailTrad}
                  rules={emailRules}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item mt={1} xs={12} sm={6}>
                <CustomInput
                  name="bio"
                  control={control}
                  label="bio"
                  placeholder={formPlaceholderBioTrad}
                  value={user.bio}
                  rules={bioRules}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item mt={1} xs={12} sm={6}>
                <DatePick
                  name="dateOfBirth"
                  maxDate={maxDate}
                  control={control}
                  label={addYourDateOfBirthTrad}
                  language={userAuth.language}
                  value={!user.dateOfBirth ? maxDate : new Date(user.dateOfBirth)}
                />
              </Grid>
              <Grid item mt={1} xs={12} sm={12}>
                <CustomInput
                  name="avatar"
                  control={control}
                  label="avatar"
                  placeholder={formPlaceholderAvatarTrad}
                  value={user.avatar}
                  rules={{}}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item mt={1} xs={6} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="error"
                  onClick={onSuspendUser}
                  disabled={Boolean(user.deletedAt)}
                  sx={styles.suspendButton}
                >
                  <Typography fontWeight="bold">
                    SUSPENDRE
                  </Typography>
                </Button>
              </Grid>
              <Grid item mt={1} xs={6} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onUpdateUser)}
                  sx={styles.saveButton}
                >
                  <Typography fontWeight="bold">
                    {saveTrad}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Snackbar
              anchorOrigin={styles.anchorOrigin}
              open={errorMessage.active}
              autoHideDuration={3000}
              message={errorMessage.msg}
              onClose={onCloseErrorSnack}
            >
              <Alert severity="error" sx={styles.alert}>
                {errorMessage.msg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={styles.anchorOrigin}
              open={successMessage.active}
              autoHideDuration={3000}
              message={successMessage.msg}
              onClose={onCloseSuccessSnack}
            >
              <Alert severity="success" sx={styles.alert}>
                {successMessage.msg}
              </Alert>
            </Snackbar>
          </Box>
        </Modal>
      )
      }
    </>
  )
});

export default UserModal;
