export const USER_SIGN_URL = `
  mutation userSignUrl($filename: String!) {
    userSignUrl(filename: $filename) 
  }
`;

export const USER_UPDATE_AVATAR = `
  mutation userUpdateAvatar($filename: String!) {
    userUpdateAvatar(filename: $filename) {
      avatar
    }
  }
`;

export const LOGIN = `
  mutation login($email: String!, $password: String!) {
    login(
      email: $email
      password: $password
    ) {
      email
      token
    }
  }
`;

export const LOGIN_WITH_FACEBOOK = `
  mutation loginWithFacebook($email: String!, $accessToken: String!) {
    loginWithFacebook(email: $email, accessToken: $accessToken) {
      email
      token
    }
  }
`;

export const LOGIN_WITH_APPLE = `
  mutation loginWithApple($idToken: String!, $userAppleId: String!) {
    loginWithApple(idToken: $idToken, userAppleId: $userAppleId) {
      email
      token
    }
  }
`;

export const LOGIN_WITH_GOOGLE = `
  mutation loginWithGoogle($email: String!, $idToken: String!) {
    loginWithGoogle(email: $email, idToken: $idToken) {
      email
      token
    }
  }
`;

export const UPDATE_PASSWORD = `
  mutation userUpdatePassword($input: UserUpdatePasswordInput!) {
    userUpdatePassword(
      input: $input
    )
  }
`;

export const USER_ENROLL = `
  mutation userEnroll($input: UserEnrollInput!) {
    userEnroll(
      input: $input
    ) {
      email
      token
      language
      timezone
    }
  }
`;

export const USER_SUSPEND = `
  mutation userSuspend($id: ID!) {
    userSuspend(id: $id) {
      id
      deletedAt
    }
  }
`;

export const USER_REDIRECT_TO_STORE = `
 mutation userRedirectToStore($input: UserRedirectToStoreInput!) {
    userRedirectToStore(input: $input)
  }
`

export const USER_PLAY_HOME_VIDEO = `
 mutation userPlayHomeVideo($input: UserPlayHomeVideoInput!) {
    userPlayHomeVideo(input: $input)
  }
`

export const USER_UPDATE = `
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(
      input: $input
    ) {
      avatar
      id
      createdAt
      language
      timezone
      dateOfBirth
      username
      email
    }
  }
`;
