const styles = {
  container: {
    overflowY: 'scroll',
    height: '100%',
  },
  typoTitle: {
    fontFamily: 'Anton, sans-serif',
    textAlign: 'center',
  },
  ulMargin: {
    marginTop: 0
  },
}

export default styles;
