import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useManualQuery } from 'graphql-hooks';
import { useParams, useSearchParams } from "react-router-dom";
import isEmpty from 'lodash/isEmpty';
import { Home } from '../Home';

import {
  VIDEO_BY_CODESHARE,
} from '../../graphql/video/query';
import { User, Video } from '../../types';
import Post from '../../components/Post/Post';
import PostDesktop from '../../components/PostDesktop/PostDesktop';
import { useAtom } from 'jotai';
import { currentIndexAtom } from '../../atoms';

const Short: React.FC<{
  isMobile: boolean,
  innerHeight: number;
  user: User;
}> = (props) => {
  const {
    innerHeight,
    isMobile,
    user,
  } = props;
  const [, setCurrentIndex] = useAtom(currentIndexAtom);
  const [searchParams] = useSearchParams();
  const { codeshare } = useParams();
  const [video, setVideo] = useState({} as Video);
  const [videoByCodeshare] = useManualQuery(
    VIDEO_BY_CODESHARE,
    { skipCache: true },
  );

  const getVideo = useCallback(async () => {
    if (codeshare && codeshare.length === 9) {
      const { data } = await videoByCodeshare({
        variables: {
          codeshare,
        },
      });
      if (data && data.videoByCodeshare) {
        setCurrentIndex(0);
        setVideo(data.videoByCodeshare);
      }
    }
  }, [codeshare, videoByCodeshare, setCurrentIndex]);

  useEffect(() => {
    getVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    searchParams.get('redirect')
    && searchParams.get('redirect') === '42'
    && isMobile
  ) {
    return (
      <Home redirect isMobile innerHeight={innerHeight} user={user} />
    );
  } else if (video && isEmpty(video)) {
    return null;
  }

  return (
    <>
      {
        isMobile ? (
          <Post
            index={0}
            user={user}
            innerHeight={innerHeight}
            video={video}
          />
        ) : (
          <PostDesktop
            user={user}
            video={video}
          />
        )}
    </>
  );
};

export default React.memo(Short);
