const styles = {
  button: {
    borderRadius: 1,
    textTransform: 'none',
  },
  container: {
    height: '100%',
  },
  paper: {
    backgroundColor: 'white',
    color: '#212121',
  },
};

export default styles;
