import {
  Box,
  Grid,
  Paper,
  Tab,
  Typography,
  Snackbar,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { Dashboard } from '../../components/Dashboard';
import { Reports } from '../../components/Reports';
import { useAuth, Context } from '../../context/auth';
import UsersSearch from '../../components/UsersSearch';
import NotificationToCommunity from '../../components/NotificationToCommunity';
import Videos from '../Videos/Videos';
import styles from './style';

const Admin: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth() as Context;
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [value, setValue] = React.useState('1');

  const usersTrad = t('users');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={styles.container}
    >
      <Grid
        item
        xs={12}
      >
        <Paper
          elevation={3}
          sx={styles.paper}
        >
          <Box sx={styles.mainBox} mt={2}>
            <TabContext value={value}>
              <Box sx={styles.box}>
                <TabList
                  onChange={handleChange}
                  sx={styles.tabList}
                >
                  <Tab
                    label={
                      <Typography fontWeight="gold" color="black">
                        DASH
                      </Typography>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <Typography fontWeight="gold" color="black">
                        VIDEOS
                      </Typography>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <Typography fontWeight="gold" color="black">
                        NOTIFS
                      </Typography>
                    }
                    value="3"
                  />
                  <Tab
                    label={
                      <Typography fontWeight="gold" color="black">
                        REPORTS
                      </Typography>
                    }
                    value="4"
                  />
                  <Tab
                    label={
                      <Typography fontWeight="gold" color="black">
                        {usersTrad}
                      </Typography>
                    }
                    value="5"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Dashboard />
              </TabPanel>
              <TabPanel value="2">
                <Videos
                  admin={true}
                  user={user}
                //onChangeVideoInput={onChangeVideoInput}
                //valueInputAddVideo={valueInputAddVideo}
                />
              </TabPanel>
              <TabPanel value="3">
                <NotificationToCommunity />
              </TabPanel>
              <TabPanel value="4">
                <Reports />
              </TabPanel>
              <TabPanel value="5">
                <UsersSearch />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
        <Snackbar
          anchorOrigin={styles.anchorOrigin}
          open={errorMessage.active}
          autoHideDuration={3000}
          message={errorMessage.msg}
          onClose={onCloseErrorSnack}
        >
          <Alert severity="error" sx={styles.alert}>
            {errorMessage.msg}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default React.memo(Admin);
