const styles = {
  cursor: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  fontAwesomeIconMobile: {
    height: '4vh',
    width: '10vw',
  },
  heightIconMobile: {
    height: '5.25vh',
  },
  heightTextMobileSection: {
    height: '3.5vh',
  },
  hover: {
    '&:hover': {
      opacity: 0.7,
    },
  },
};

export default styles;
