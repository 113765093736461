import ReactDOM from 'react-dom/client';
import React from 'react';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root-webapp') as HTMLElement
);

root.render(<App />);
