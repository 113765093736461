enum UserRoles {
  'USER' = 'User',
  'ADMIN' = 'Admin',
}

interface UserDeviceInfo {
  ios?: {
    model: string;
    version: string;
  }
  android?: {
    model: string;
    version: string;
  }
}

enum StateAuth {
  LOADING = 'LOADING',
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

interface User {
  id: string;
  avatar: string;
  bio: string;
  dateOfBirth: string;
  isConnected: StateAuth;
  language: string;
  username: string;
  email: string;
  phone: string;
  createdAt?: number;
  deletedAt?: number;
  deviceInfo?: UserDeviceInfo;
  os: string;
  twitter?: string;
  timezone: string;
  role?: string;
}

interface UserStat {
  userEnrolled: number;
  userEnrolledByDay: number;
  userEnrolledByWeek: number;
}

export { UserRoles };

export type { User, UserStat };
