export const NOTIFICATION_TO_COMMUNITY = `
  mutation notificationToCommunity($message: String!) {
    notificationToCommunity(message: $message)
  }
`;

export const NOTIFICATION_TO_USER = `
  mutation notificationToUser($input: NotificationToUserInput!) {
    notificationToUser(input: $input)
  }
`;
