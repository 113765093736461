const styles = {
  actionsContainerRow: {
    height: '25%',
  },
  avatarProfile: {
    width: '3.5rem',
    height: '3.5rem',
  },
  commentIcon: {
    fontSize: '1.8rem',
  },
  heartIcon: {
    fontSize: '2rem',
  },
  likeTypo: {
    height: '1.5rem',
    fontFamily: 'Russo One',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: 'white',
  },
  shareIcon: {
    fontSize: '1.85rem',
  },
};

export default styles;
