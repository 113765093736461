import React, { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useManualQuery } from 'graphql-hooks';
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import debounce from 'lodash/debounce';

import {
  USER_EMAIL_EXIST,
  USER_USERNAME_EXIST
} from '../../graphql/user/query';
import {
  USER_UPDATE,
} from '../../graphql/user/mutation';
import { DatePick } from '../DatePick';
import { CustomInput } from '../CustomInput';
import { User } from '../../types';
import styles from './style';

interface ProfileFormProps {
  user: User;
}

interface IObjectKeys {
  [key: string]: string | Date;
}

interface ProfileFormData extends IObjectKeys {
  email: string;
  dateOfBirth: Date;
  username: string;
  bio: string;
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ProfileForm: React.FC<ProfileFormProps> = React.memo((props) => {
  const { user } = props;
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<ProfileFormData>();
  const [userUpdate] = useMutation(USER_UPDATE);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [usernameAlreadyExist, setUsernameAlreadyExist] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [userEmailExist] = useManualQuery(USER_EMAIL_EXIST);
  const [userUsernameExist] = useManualQuery(USER_USERNAME_EXIST);
  const today = new Date(Date.now());
  const year = today.getFullYear() - 13;
  const maxDate = new Date();
  maxDate.setFullYear(year);

  const usernameTrad = t('username');
  const emailTrad = t('email');
  const formErrorRequiredUsernameTrad = t('form.error.required.username');
  const formErrorMinLengthUsernameTrad = t('form.error.minLength.username');
  const formErrorMaxLengthUsernameTrad = t('form.error.maxLength.username');
  const formErrorRequiredEmailTrad = t('form.error.required.email');
  const formErrorPatternEmailTrad = t('form.error.pattern.email');
  const formPlaceholderBioTrad = t('form.placeholder.bio');
  const formErrorRequiredBioTrad = t('form.error.required.bio');
  const formErrorMinLengthBioTrad = t('form.error.minLength.bio');
  const formErrorMaxLengthBioTrad = t('form.error.maxLength.bio');
  const addYourDateOfBirthTrad = t('addYourDateOfBirth');
  const saveTrad = t('save');

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const isValidUsername = async (username: string) => {
    const { data } = await userUsernameExist({
      variables: {
        username,
      },
    }) as any;
    if (data.userUsernameExist && !usernameAlreadyExist) {
      setUsernameAlreadyExist(true);
    } else if (!data.userUsernameExist && usernameAlreadyExist) {
      setUsernameAlreadyExist(false);
    }
  };
  const isValidEmail = async (email: string) => {
    const { data } = await userEmailExist({
      variables: {
        email,
      },
    }) as any;
    if (data.userEmailExist && !emailAlreadyExist) {
      setEmailAlreadyExist(true);
    } else if (!data.userEmailExist && emailAlreadyExist) {
      setEmailAlreadyExist(false);
    }
  };

  const debounceUsernameFn = debounce(isValidUsername, 300);
  const debounceEmailFn = debounce(isValidEmail, 300);

  const onChangeUsername = (e: any) => {
    debounceUsernameFn(e.target.value);
  };

  const onChangeEmail = (e: any) => {
    debounceEmailFn(e.target.value);
  };

  const getObjModified = (obj: ProfileFormData) => {
    const input = {} as ProfileFormData;
    const keys = Object.keys(obj);
    keys.forEach((k) => {
      if (obj[k] !== (user as unknown as ProfileFormData)[k]) {
        input[k] = obj[k];
      }
    })
    return input;
  };

  const onRegisterPressed = async (obj: ProfileFormData) => {
    const input = getObjModified(obj);
    try {
      if (!usernameAlreadyExist && !emailAlreadyExist && !isEmpty(input)) {
        const { data } = await userUpdate({
          variables: {
            input,
          }
        })
        if (data && data.userUpdate) {
          setSuccessMessage({ active: true, msg: t('MAJ') });
        }
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  };

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const usernameRules = useMemo(() => ({
    required: formErrorRequiredUsernameTrad,
    minLength: {
      value: 3,
      message: formErrorMinLengthUsernameTrad,
    },
    maxLength: {
      value: 24,
      message: formErrorMaxLengthUsernameTrad,
    },
  }), [
    formErrorRequiredUsernameTrad,
    formErrorMinLengthUsernameTrad,
    formErrorMaxLengthUsernameTrad,
  ]);

  const emailRules = useMemo(() => ({
    required: formErrorRequiredEmailTrad,
    pattern: {
      value: EMAIL_REGEX,
      message: formErrorPatternEmailTrad,
    },
  }), [
    formErrorPatternEmailTrad,
    formErrorRequiredEmailTrad,
  ]);

  const bioRules = useMemo(() => ({
    required: formErrorRequiredBioTrad,
    minLength: {
      value: 4,
      message: formErrorMinLengthBioTrad,
    },
    maxLength: {
      value: 60,
      message: formErrorMaxLengthBioTrad,
    },
  }), [
    formErrorRequiredBioTrad,
    formErrorMinLengthBioTrad,
    formErrorMaxLengthBioTrad,
  ]);

  return (
    <Grid
      container
      direction="row"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            alreadyExist={usernameAlreadyExist}
            name="username"
            fullWidth
            label={usernameTrad}
            control={control}
            value={user.username}
            onChange={onChangeUsername}
            placeholder={usernameTrad}
            rules={usernameRules}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            alreadyExist={emailAlreadyExist}
            name="email"
            label='Email'
            control={control}
            value={user.email}
            onChange={onChangeEmail}
            placeholder={emailTrad}
            rules={emailRules}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item mt={1} xs={12} sm={6}>
          <CustomInput
            name="bio"
            control={control}
            label="bio"
            placeholder={formPlaceholderBioTrad}
            value={user.bio}
            rules={bioRules}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item mt={1} xs={12} sm={6}>
          <DatePick
            name="dateOfBirth"
            maxDate={maxDate}
            control={control}
            label={addYourDateOfBirthTrad}
            language={user.language}
            value={!user.dateOfBirth ? maxDate : new Date(user.dateOfBirth)}
          />
        </Grid>
        <Grid item mt={1} xs={12} display="flex" justifyContent="center">
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmit(onRegisterPressed)}
            sx={styles.saveButton}
          >
            <Typography fontWeight="bold">
              {saveTrad}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
    </Grid>
  )
});

export default ProfileForm;
