const styles = {
  container: {
    overflowY: 'scroll',
    height: '100%',
  },
  margin: {
    marginTop: 0,
  },
  lineHeight: {
    lineHeight: '24px',
  },
  title: {
    fontFamily: 'Anton, sans-serif',
    color: 'white',
  },
};

export default styles;
