import React, { useCallback, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXbox, faPlaystation } from '@fortawesome/free-brands-svg-icons';
import { faComputer } from '@fortawesome/free-solid-svg-icons';

import { User, Video } from '../../../types';
import styles from './style';

interface FooterProps {
  currentIndex: number;
  user: User;
  video: Video;
}

const Footer = React.memo((props: FooterProps) => {
  const { currentIndex, user, video } = props;

  const platformIcon = useMemo(() => {
    if (video.platform === 'PC') {
      return faComputer;
    } else if (video.platform === 'Xbox') {
      return faXbox;
    } else if (video.platform === 'Playstation') {
      return faPlaystation;
    }
  }, [video]);

  const killWord = useMemo(() => {
    if (video.kills === 1) {
      return "1 kill";
    } else if (video.kills === 42) {
      return "∞";
    }
    return `${video.kills} kills`;
  }, [video]);

  const gameSx = useMemo(() => {
    const { gameName } = video;
    let style = {
      position: 'absolute',
      height: '4.8rem',
      marginBottom: '0.25rem',
    } as {
      bottom: string;
      height: string;
      marginBottom: string;
      position: string;
      top: string;
    };
    if (gameName === 'Fortnite') {
      style.height = '4.9rem';
      style.marginBottom = '0.3rem';
    } else if (gameName === 'Apex Legends') {
      style.height = '4.75rem';
      style.marginBottom = '0.15rem';
    } else if (gameName === 'Call of Duty Modern Warfare 2') {
      style.height = '5.25rem';
      style.marginBottom = '0.25rem';
    } else if (gameName === 'Call of Duty Black Ops Cold War') {
      style.height = '5rem';
      style.marginBottom = '0.25rem';
    } else if (gameName === 'Call of Duty Modern Warfare 3') {
      style.height = '4.8rem';
      style.marginBottom = '0.1rem';
    } else if (gameName === 'Call of Duty Modern Warfare') {
      style.height = '4.75rem';
      style.marginBottom = '0.5rem';
    } else if (gameName === 'Call of Duty Warzone') {
      style.height = '4.75rem';
      style.marginBottom = '0.35rem';
    } else if (gameName === 'Call of Duty Vanguard') {
      style.height = '5rem';
      style.marginBottom = '0.4rem';
    } else if (gameName === 'Halo Infinite') {
      style.height = '5.35rem';
      style.marginBottom = '0.15rem';
    } else if (gameName === 'Hell Let Loose') {
      style.height = '5.1rem';
      style.marginBottom = '0.2rem';
    } else if (gameName === 'OverWatch2') {
      style.height = '5.2rem';
      style.marginBottom = '0.2rem';
    } else if (gameName === 'Rainbow Six Siege') {
      style.height = '5rem';
      style.marginBottom = '0.3rem';
    } else if (gameName === 'Counter-Strike') {
      style.height = '4.9rem';
      style.marginBottom = '0.5rem';
    } else if (gameName === 'XDefiant') {
      style.height = '4.9rem';
      style.marginBottom = '0.2rem';
    } else if (gameName === 'Battlefield 5') {
      style.height = '4.8rem';
      style.marginBottom = '0.45rem';
    } else if (gameName === 'Battlefield 2042') {
      style.height = '4.8rem';
      style.marginBottom = '0.45rem';
    } else if (gameName === 'Battlefield 4') {
      style.height = '4.8rem';
      style.marginBottom = '0.25rem';
    } else if (gameName === 'The Finals') {
      style.height = '4.7rem';
      style.marginBottom = '0.5rem';
    } else if (gameName === 'Valorant') {
      style.height = '4.8rem';
      style.marginBottom = '0.2rem';
    }
    return style;
  }, [currentIndex, video]);

  const weaponSx = useMemo(() => {
    const { weapon } = video;
    let style = {
      height: '3.75rem',
      top: '0.4rem',
      marginRight: '1.5rem',
      position: 'absolute',
    } as {
      bottom: string;
      height: string;
      left: string;
      position: string;
      marginRight: string;
      right: string;
      top: string;
      width: string;
    };

    if (weapon === 'Assault') {
      style.height = '4rem';
      style.top = '-0.05rem';
    } else if (weapon === 'Knife') {
      style.top = '-0.025rem';
      style.marginRight = '1.25rem';
      style.height = '4.3rem';
    } else if (weapon === 'Sniper') {
      style.top = '0rem';
      style.height = '3.85rem';
    } else if (weapon === 'Machine Gun') {
      style.top = '-0.05rem';
      style.height = '4.25rem';
    } else if (weapon === 'Support') {
      style.top = '-0.125rem';
      style.marginRight = '1.5rem';
      style.height = '4.05rem';
    } else if (weapon === 'Bomb') {
      style.height = '3.95rem';
      style.top = '0.2rem';
    } else if (weapon === 'Gun') {
      style.top = '0.025rem';
      style.height = '4.3rem';
    } else if (weapon === 'Shot Gun') {
      style.top = '0.2rem';
      style.height = '3.9rem';
    } else if (weapon === 'Launcher') {
      style.height = '3.9rem';
      style.top = '0.3rem';
    } else if (weapon === 'C4') {
      style.height = '3.9rem';
      style.top = '0.3rem';
    } else if (weapon === 'Shield') {
      style.height = '3.7rem';
      style.top = '0.45rem';
    } else if (weapon === 'Grenade') {
      style.height = '3.7rem';
      style.top = '0.4rem';
    } else if (weapon === 'Plane') {
      style.height = '3.7rem';
      style.top = '0.25rem';
    } else if (weapon === 'Tank') {
      style.top = '0.05rem';
      style.height = '4.1rem';
    } else if (weapon === 'Helicopter') {
      style.height = '3.9rem';
      style.top = '0.15rem';
    } else if (weapon === 'Hand') {
      style.height = '3.75rem';
      style.top = '0.4rem';
    }

    return style;
  }, [currentIndex, video]);

  const rowMemo = useMemo(() => {
    if (
      user.os === 'ios'
      && document
      && document.referrer
      && document.referrer.includes('tiktok')
    ) {
      return styles.tiktokFooter;
    }
    return styles.footer;
  }, [user.os]);

  const platformIconStyle = useMemo(() => {
    const style = { fontSize: '1.35rem' };

    if (video.platform === 'Playstation') {
      style.fontSize = '1.20rem';
    } else if (video.platform === 'PC') {
      style.fontSize = '1.25rem';
    } else if (video.platform === 'Xbox') {
      style.fontSize = '1.15rem';
    }
    return style;
  }, [currentIndex, video]);

  const mlByPlatform = useMemo(() => {
    let ml = '0.2rem';

    if (video.platform === 'Playstation') {
      ml = '0.3rem';
    } else if (video.platform === 'Xbox') {
      ml = '0.3rem';
    } else if (video.platform === 'PC') {
      ml = '0.5rem';
    }
    return ml;
  }, [currentIndex, video]);

  const mlTopPlatform = useMemo(() => {
    let mt = {
      marginTop: '0.08rem',
      marginLeft: '0rem',
    } as any;

    if (video.platform === 'Playstation') {
      mt.marginTop = '0.075rem';
      mt.marginLeft = '0.09rem';
    } else if (video.platform === 'PC') {
      mt.marginTop = '0.025rem';
      mt.marginLeft = '0.2rem';
    }

    return mt;
  }, [currentIndex, video.platform]);

  const prRightItem = useMemo(() => ('0.95rem'), []);
  const plItem = useMemo(() => ('0.5rem'), []);
  const weaponSrc = useMemo(
    () => (`${process.env.REACT_APP_AWS_CLOUDFRONT_URL!}weapons/v2/${video.weapon}.webp`.replace(/\s/g, '%20')),
    [video.weapon],
  );
  const gameSrc = useMemo(
    () => (`${process.env.REACT_APP_AWS_CLOUDFRONT_URL!}games/${video.gameName}.webp`.replace(/\s/g, '%20')),
    [video.gameName],
  );
  const weaponPrRight = useMemo(() => ('0.75rem'), []);
  const killFontSize = useMemo(() => ('0.75rem'), []);
  const modeFontSize = useMemo(() => ('0.75rem'), []);
  const modeSx = useMemo(() => {
    let style = {
      height: '1.2rem',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
      paddingTop: '0.025rem',
      paddingBottom: '0.025rem',
      backgroundColor: 'white',
      letterSpacing: '-0.05rem',
      textAlign: 'center',
      borderWidth: 1,
      borderRadius: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    if (user.os === 'android') {
      style.letterSpacing = '-0.025rem';
    }
    return style;
  }, [currentIndex, user]);
  const killSx = useMemo(() => {
    let style = {
      marginLeft: '0.3rem',
      height: '1.2rem',
      backgroundColor: 'red',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
      paddingTop: '0.05rem',
      paddingBottom: '0.05rem',
      letterSpacing: '-0.05rem',
      borderWidth: 1,
      borderRadius: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    if (user.os === 'android') {
      style.letterSpacing = '0rem';
    }
    return style;
  }, [currentIndex, user]);

  return (
    <Grid
      item
      xs={12}
      display="flex"
      sx={rowMemo}
    >
      <Grid
        display="flex"
        container
      >
        <Grid
          item
          pl={plItem}
          xs={8}
          display="flex"
        >
          <Grid container flexDirection="row">
            <Grid
              item
              display="flex"
              alignItems="center"
              xs={11}
            >
              <Typography
                variant="body2"
                align="center"
                sx={styles.usernameTypo}
              >
                {video.user.username}
              </Typography>
            </Grid>

            <Grid
              item
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              xs={11}
            >
              <Typography
                variant="body2"
                align="center"
                sx={styles.description}
              >
                {video.description}
              </Typography>
            </Grid>

            <Grid
              item
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              xs={11}
              mt={0.5}
            >
              <Grid container>
                <Grid
                  item
                  xs={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={mlTopPlatform}
                >
                  <FontAwesomeIcon
                    icon={platformIcon as IconProp}
                    style={platformIconStyle}
                    color="white"
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  display="flex"
                  ml={mlByPlatform}
                  alignItems="center"
                >
                  <Typography
                    color="black"
                    fontFamily="Inter"
                    fontWeight="bold"
                    fontSize={modeFontSize}
                    sx={modeSx}
                  >
                    {video.mode}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="white"
                    fontFamily="Inter"
                    fontWeight="bold"
                    alignItems="center"
                    fontSize={killFontSize}
                    sx={killSx}
                  >
                    {killWord}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          pr={prRightItem}
          xs={4}
          sm={7.85}
          display="flex"
        >
          <Grid
            container
            display="flex"
          >
            <Grid
              item
              sm={1.5}
              xs={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
              pr={weaponPrRight}
            >
              <Box
                component="img"
                alt=""
                src={weaponSrc}
                sx={weaponSx}
              />
            </Grid>
            <Grid
              item
              sm={1}
              xs={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                alt=""
                src={gameSrc}
                sx={gameSx}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
});

export default Footer;
