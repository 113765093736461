
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import privacyFr from '../locales/fr/privacy.json';
import privacyEn from '../locales/en/privacy.json';
import termsFr from '../locales/fr/terms.json';
import termsEn from '../locales/en/terms.json';
import userFr from '../locales/fr/user.json';
import userEn from '../locales/en/user.json';
import videoFr from '../locales/fr/video.json';
import videoEn from '../locales/en/video.json';

const resources = {
  en: {
    translation: {
      ...privacyEn,
      ...termsEn,
      ...userEn,
      ...videoEn,
    }
  },
  fr: {
    translation: {
      ...privacyFr,
      ...termsFr,
      ...userFr,
      ...videoFr
    }
  }
} as const;


export { resources, LanguageDetector, initReactI18next };
