import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  appleButton: {
    backgroundColor: '#1C2833',
    borderRadius: 1,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1C2833',
    }
  },
  buttonConnexion: {
    borderRadius: 1,
    textTransform: 'none',
  },
  container: {
    paddingLeft: 0.4,
    paddingRight: 0.4,
    height: '100%',
  },
  facebookButton: {
    backgroundColor: '#1C2833',
    borderRadius: 1,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1C2833',
    },
  },
  googleButton: {
    backgroundColor: '#1C2833',
    borderRadius: 1,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1C2833',
    }
  },
  linkPassword: {
    textDecoration: 'none',
    color: '#3f51b5',
  },
  linkTypo: {
    textDecoration: 'none',
    color: '#3f51b5',
  },
  paper: {
    backgroundColor: 'white',
    color: '#212121',
  },
};

export default styles;
