import {
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'graphql-hooks';
import { useParams } from 'react-router-dom';
//import CookieConsent from "react-cookie-consent";
import React, { useCallback, useMemo } from 'react';
import {
  Avatar,
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  USER_REDIRECT_TO_STORE,
} from '../../graphql/user/mutation';
import { StateAuth } from '../../context/auth';
import { User } from '../../types';
import styles from './style';

interface NavProps {
  isConnected: StateAuth;
  user: User;
  logout: () => Promise<void>;
  isMobile: boolean;
  os: string;
  innerHeight: number;
  redirectToApp: () => void;
}

const Nav: React.FC<NavProps> = React.memo((props) => {
  const {
    isConnected,
    isMobile,
    logout,
    redirectToApp,
    os,
    innerHeight,
    user
  } = props;
  const { codeshare } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userRedirectToStore] = useMutation(USER_REDIRECT_TO_STORE);
  const location = useLocation();
  const navigate = useNavigate();

  const signInTrad = t('form.signIn');
  const signUpTrad = t('form.signUp');
  const downloadApp = t('downloadApp');
  const openApp = t('openApp');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onUserRedirectToStore = useCallback((input: {
    os: string;
    option: string;
  }) => userRedirectToStore({
    variables: {
      input,
    },
  }), [userRedirectToStore]);

  const goToProfile = useCallback(() => {
    navigate('/profile', { replace: true });
  }, [navigate]);

  const goLogout = useCallback(async () => {
    await logout();
    setAnchorEl(null);
  }, [logout]);

  const redirectToAppFromCodeshare = useCallback((codeshare: string) => {
    const nativeSchemaUrl = `https://store.soskills.io/short/${codeshare}?redirect=42`;
    window.open(nativeSchemaUrl, '_self');
  }, []);

  const getTheApp = useCallback(() => {
    if (location.pathname.includes('/short/')) {
      redirectToAppFromCodeshare(codeshare!)
    } else {
      redirectToApp();
    }
    onUserRedirectToStore({
      os,
      option: 'download',
    });
  }, [
    codeshare,
    redirectToApp,
    redirectToAppFromCodeshare,
    location.pathname,
    onUserRedirectToStore,
    os,
  ]);

  const getLogoDim = useCallback(() => {
    let h = '2.5rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '2rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '1.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'ios') {
      h = '2.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'android') {
      h = '2.5rem';
    } else if (innerHeight >= 735 && os === 'ios') {
      h = '2.5rem';
    } else if (innerHeight >= 735 && os === 'android') {
      h = '2.5rem';
    }
    return h;
  }, [innerHeight, os]);

  const getNavTitle = useCallback(() => {
    let h = '2.25rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '1.75rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '1.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'android') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && os === 'android') {
      h = '2.25rem';
    }
    return h;
  }, [os, innerHeight]);

  const getButtonHeight = useCallback(() => {
    let h = '2.25rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '1.75rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '1.75rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'android') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && os === 'android') {
      h = '2.25rem';
    }
    return h;
  }, [os, innerHeight]);


  const getNavHeight = useCallback(() => {
    let h = '3.3rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '2.75rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '2.75rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'ios') {
      h = '3.3rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'android') {
      h = '3.3rem';
    } else if (innerHeight >= 735 && os === 'ios') {
      h = '3.3rem';
    } else if (innerHeight >= 735 && os === 'android') {
      h = '3.3rem';
    }
    return h;
  }, [os, innerHeight]);

  const appBarMemo = useMemo(() => ({
    backgroundColor:
      (location.pathname.includes('/short/') || location.pathname === '/' && isMobile)
        ? 'transparent'
        : '#18181b',
    borderWidth: 0,
    height: isMobile ? getNavHeight() : '4.1rem',
    display: 'flex',
    justifyContent: 'center',
  }), [getNavHeight, isMobile, location.pathname]);

  const goBackToHome = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);
  const onClickToSignUp = useCallback(() => {
    navigate('signup', { replace: true });
  }, [navigate]);
  const onClickToLogin = useCallback(() => {
    navigate('login', { replace: true });
  }, [navigate]);

  const logoBlackStyle = useMemo(() => ({
    height: getLogoDim()
  }), [getLogoDim]);

  const titleTypoSx = useMemo(() => ({
    fontSize: isMobile ? getNavTitle() : '2.25rem',
    marginLeft: !isMobile ? 1 : 0,
    flexGrow: 1,
    cursor: "pointer",
    fontFamily: 'Anton',
  }), [isMobile, getNavTitle]);

  const downloadButtonMemo = useMemo(() => ({
    height: getButtonHeight(),
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    }
  }), [getButtonHeight]);

  return (
    <React.Fragment>
      <AppBar
        color="transparent"
        position="fixed"
        variant="outlined"
        sx={appBarMemo}
        elevation={0}
      >
        {/*{isConnected === 'CONNECTED' && (
          <CookieConsent
            location="bottom"
            //flipButtons
            buttonText={t('acceptCookies')}
            cookieName="cookieSoSkills"
            style={{
              background: "#1a73e8",
            }}
            buttonStyle={{
              backgroundColor: 'white',
              color: "black",
              borderRadius: '4px',
              fontWeight: 'bold',
              fontSize: '11px',
            }}
            expires={150}
          >
            {t('cookieConsentTxt')}{" "}
          </CookieConsent>
        )}*/}
        <Toolbar
        >
          <IconButton
            //edge="start"
            sx={styles.iconButton}
            aria-label="menu"
            onClick={goBackToHome}
          >
            {
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={`/logo-black.png`}
                style={logoBlackStyle}
              />
            }
          </IconButton>
          <Typography
            color="white"
            sx={titleTypoSx}
            onClick={goBackToHome}
          >
            SoSkills
          </Typography>
          {/*{((isMobile && location.pathname === '/login' && isConnected === StateAuth.NOT_CONNECTED) || !isMobile) && (*/}
          {!isMobile && (
            <>
              {
                location.pathname !== '/signup' && isConnected === StateAuth.NOT_CONNECTED && (
                  <Button
                    variant="contained"
                    onClick={onClickToSignUp}
                    color="success"
                    sx={styles.signUpButton}
                  >
                    <Typography fontWeight="bold">
                      {signUpTrad}
                    </Typography>
                  </Button>
                )
              }
            </>
          )}
          {location.pathname !== '/login' && isConnected === StateAuth.NOT_CONNECTED && !isMobile && (
            <Button
              variant="contained"
              onClick={onClickToLogin}
              color="primary"
              sx={styles.signInButton}
            >
              <Typography fontWeight="bold">
                {signInTrad}
              </Typography>
            </Button>
          )}
          {location.pathname !== '/login' && isConnected === StateAuth.NOT_CONNECTED && isMobile && (
            <Button
              variant="contained"
              onClick={getTheApp}
              color="primary"
              sx={downloadButtonMemo}
            >
              {
                !searchParams.get('redirect')
                  && location.pathname.includes('/short/')
                  ? (
                    <Typography
                      fontSize="4vw"
                      letterSpacing={0.9}
                      fontFamily="Anton"
                      color="black"
                    >
                      {openApp}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize="4vw"
                      letterSpacing={0.9}
                      fontFamily="Anton"
                      color="black"
                    >
                      {downloadApp}
                    </Typography>
                  )}
            </Button>
          )}
          {isConnected === StateAuth.CONNECTED && (
            <>
              <Button onClick={handleClick}>
                <Avatar
                  alt="profile"
                  src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${user.avatar}`}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {user.role === 'Admin' && (
                  <MenuItem onClick={goToProfile}>My profile</MenuItem>
                )}
                <MenuItem onClick={goLogout}><Typography color="black">Logout</Typography></MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
});

export { Nav };
