import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Modal,
  Button,
  Typography,
} from '@mui/material/';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';

import { User } from '../../../types';
import styles from './style';

interface ShortModalProps {
  user: User,
  codeshare: string;
  isShortModalVisible: boolean;
  closeShortModal: () => void;
  redirectToApp: () => void;
  innerHeight: number;
}

const ShortModal: React.FC<ShortModalProps> = React.memo((props) => {
  const {
    user,
    innerHeight,
    codeshare,
    closeShortModal,
    isShortModalVisible,
  } = props;
  const [isVisible, setIsVisible] = useState(isShortModalVisible);
  const { t } = useTranslation();
  const openAppTrad = t('openApp');
  const descriptionTrad = t('descriptionShortModal');

  const getTheApp = useCallback(() => {
    var nativeSchemaUrl = `https://store.soskills.io/short/${codeshare}?redirect=42`;
    window.open(nativeSchemaUrl, '_self');
  }, [codeshare]);

  useEffect(() => {
    if (isShortModalVisible) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 700); // Match duration of animation
      return () => clearTimeout(timer);
    }
  }, [isShortModalVisible]);

  const getButtonHeight = useCallback(() => {
    let h = '2.25rem';
    if (innerHeight < 660 && user.os === 'ios') {
      h = '1.75rem';
    } else if (innerHeight < 660 && user.os === 'android') {
      h = '1.75rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'android') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      h = '2.25rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      h = '2.25rem';
    }
    return h;
  }, [user.os, innerHeight]);

  const openAppButtonSx = useMemo(() => ({
    height: getButtonHeight(),
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    textTransform: 'none',
  }), [getButtonHeight]);

  const title = useMemo(() => {
    let title = 'REJOINS-NOUS';
    if (user.language !== 'fr') {
      title = 'GET THE APP SOSKILLS';
    }
    return title;
  }, [user.language]);

  const logo = useMemo(() => (`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}logo/180x180-red.webp`), []);
  const openAppSx = useMemo(() => {
    let style = {
      marginTop: '0.45rem',
      marginBottom: '0.2rem'
    };
    if (
      user.os === 'ios'
      && document
      && document.referrer
      && document.referrer.includes('tiktok')
    ) {
      style.marginBottom = '0.9rem';
    }
    return style;
  }, [user.os]);
  const boxClassName = useMemo(() => {
    let name = 'fade-in-slide-up';
    if (!isShortModalVisible) {
      name = 'fade-out-slide-down';
    }
    return name;
  }, [isShortModalVisible]);
  const open = useMemo(() => (isShortModalVisible || isVisible), [isShortModalVisible, isVisible]);

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={closeShortModal}
      onClick={closeShortModal}
    >
      <Box
        sx={styles.box}
        className={boxClassName}
      >
        <Grid
          item
          xs={12}
          display="flex"
          sx={styles.avatarGrid}
        >
          <Avatar
            alt="profile"
            src={logo}
            sx={styles.avatar}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          sx={styles.closeGrid}
          onClick={closeShortModal}
        >
          <IconButton sx={styles.closeIcon}>
            <Close />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body2"
            color="white"
            fontWeight="bold"
            fontFamily="Russo One"
            align="center"
            sx={styles.title}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          mt={'0.65rem'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body2"
            color="white"
            align="center"
            sx={styles.description}
          >
            {descriptionTrad}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={openAppSx}
        >
          <Button
            sx={openAppButtonSx}
            variant="contained"
            onClick={getTheApp}
          >
            <Typography
              fontSize="4vw"
              letterSpacing={0.9}
              fontFamily="Anton"
              color="black"
            >
              {openAppTrad}
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
});

export default ShortModal;
