import React from 'react';
import {
  Grid,
  IconButton,
  Modal,
  Box,
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import Close from '@mui/icons-material/Close';

import { UserRoles } from '../../../types';
import { Context, useAuth } from '../../../context/auth';
import NotificationToCommunity from '../../NotificationToCommunity';
import styles from './style';

interface MessageToUserModalProps {
  isMessageToUserModalVisible: boolean;
  handleIsMessageToUserModalVisible: () => void;
  userId: string;
  username: string;
}

const MessageToUserModal: React.FC<MessageToUserModalProps> = React.memo((props) => {
  const {
    isMessageToUserModalVisible,
    handleIsMessageToUserModalVisible,
    userId,
    username,
  } = props;
  const navigate = useNavigate();

  const { user: userAuth } = useAuth() as Context;

  if (userAuth && userAuth.role !== UserRoles.ADMIN) {
    navigate('/', { replace: true });
  }

  return (
    <Modal
      hideBackdrop
      open={isMessageToUserModalVisible}
      onClose={handleIsMessageToUserModalVisible}
      style={styles.modal}
    >
      <Box sx={styles.box}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <div
            onClick={handleIsMessageToUserModalVisible}
            style={styles.closeRow as any}
          >
            <IconButton
              sx={styles.closeIcon}
              component="span"
            >
              <Close />
            </IconButton>
          </div>
        </Grid>
        <NotificationToCommunity
          userId={userId}
          username={username}
        />
      </Box>
    </Modal>
  )
});

export default MessageToUserModal;
