import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  hover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  selectHeight: {
    height: 45,
  },
  statusButton: {
    textTransform: 'none',
    borderRadius: 1,
  },
};

export default styles;
