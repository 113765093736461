export const USERS_SEARCH = `
  query usersSearch($search: String) {
    usersSearch(search: $search) {
      avatar
      deletedAt
      id
      bio
      createdAt
      timezone
      dateOfBirth
      username
      twitter
      email
    }
  }
`

export const USER_BY_ID = `
  query userById($id: ID!) {
    userById(id: $id) {
      id
      avatar
      deletedAt
      bio
      dateOfBirth
      createdAt
      email
      isFollowed
      language
      twitter
      timezone
      username
    }
  }
`;

export const ME = `
  query user {
    user {
      id
      avatar
      dateOfBirth
      bio
      createdAt
      email
      language
      timezone
      username
      twitter
      role
    }
  }
`;

export const USER_BY_FACEBOOK_API = `
  query userByFacebookApi($accessToken: String!) {
    userByFacebookApi(accessToken: $accessToken) {
      birthday
      name
      email
      userId
  }
}`;


export const USER_BY_GOOGLE_API = `
  query userByGoogleApi($code: String!) {
    userByGoogleApi(code: $code) {
      idToken
      birthday
      name
      email
      userId
  }
}`;

export const USER_EMAIL_EXIST = `
  query userEmailExist($email: String!) {
    userEmailExist(email: $email)
  }
`;

export const USERS_STAT = `
  query usersStat {
    usersStat {
      userEnrolled
      userEnrolledByDay
      userEnrolledByWeek
    }
  }
`;

export const USER_USERNAME_EXIST = `
  query userUsernameExist($username: String!) {
    userUsernameExist(username: $username)
  }
`;
