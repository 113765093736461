const styles = {
  badgeSx: {
    height: '6.5vh',
  },
  borderBox: {
    borderRadius: 3,
  },
  fullHeight: {
    height: '100%',
  },
  joinRow: {
    marginRight: '3rem',
  },
  linkOpacity: {
    "&:hover": {
      opacity: 0.7,
    },
  },
  mainContainer: {
    height: '100%',
  },
  soundGrid: {
    position: 'absolute',
    width: '100%',
    right: '1.2rem',
    top: '4.5rem',
    zIndex: 10,
  },
  spacing: {
    letterSpacing: '1.5px',
  },
};

export default styles;
