import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Snackbar } from '@mui/material';
import {
  ClientContext,
  useMutation,
  useManualQuery,
} from 'graphql-hooks';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import jwt_decode from "jwt-decode";
//import isBot from 'isbot';
import mobile from 'is-mobile';

import isEmpty from 'lodash/isEmpty';

import {
  LOGIN,
  LOGIN_WITH_APPLE,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_GOOGLE,
  USER_ENROLL,
} from '../../graphql/user/mutation';
import {
  USER_BY_FACEBOOK_API,
  USER_BY_GOOGLE_API,
  USER_EMAIL_EXIST,
  ME,
} from '../../graphql/user/query';
import { User } from '../../types';
import { innerHeightAtom } from '../../atoms/window';
import {
  isMobileAtom,
  isWelcomeModalVisibleAtom,
} from '../../atoms/events';
import useDimensionWindow from '../../screens/NavigationRoot/useDimensionsWindow';
import styles from './style';

export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const keys = [TOKEN_KEY, USER_KEY];

interface Context {
  logout: () => Promise<void>;
  redirectToApp: () => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (input: SignUpArgs) => Promise<void>;
  setUser: (user: Partial<User>) => Promise<void>;
  signInWithFacebook: (response: any) => Promise<void>;
  signInWithGoogle: (response: any) => Promise<void>;
  signInWithApple: (response: any) => Promise<void>;
  user: User;
}

interface SignUpArgs {
  accessToken?: string;
  email?: string;
  idToken?: string;
  password?: string;
  username?: string;
  dateOfBirth?: Date;
  provider?: string;
  userAppleId?: string;
}

enum StateAuth {
  LOADING = 'LOADING',
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

interface Props {
  lang?: string;
  tz?: string;
  children?: React.ReactNode;
}

const AuthContext = React.createContext({});
const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<Props> = (props: Props) => {
  const {
    lang,
    tz,
    children
  } = props;
  const client = useContext(ClientContext);
  const { height, width } = useDimensionWindow();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorMinimumAge = t('minimumAge');
  const [innerHeight, setInnerHeight] = useAtom(innerHeightAtom);
  const [isMobile, setIsMobile] = useAtom(isMobileAtom);
  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useAtom(isWelcomeModalVisibleAtom);
  const getMobileOperatingSystem = useCallback(() => {
    let userAgent = window.navigator.userAgent;
    if (isMobile) {
      if (/windows phone/i.test(userAgent)) {
        return 'windows phone';
      } else if (/android/i.test(userAgent)) {
        return 'android';
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      }
    } else if (!isMobile) {
      return 'website';
    }
    return 'unknown';
  }, [isMobile]);
  const [user, setUser] = useState({
    timezone: tz,
    language: lang,
    isConnected: StateAuth.LOADING,
    os: getMobileOperatingSystem(),
  } as User);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [userByGoogleApi] = useManualQuery(
    USER_BY_GOOGLE_API,
    { skipCache: true },
  );
  const [userByFacebookApi] = useManualQuery(
    USER_BY_FACEBOOK_API,
    { skipCache: true },
  );
  const [userEmailExist] = useManualQuery(
    USER_EMAIL_EXIST,
    { skipCache: true },
  );
  const [getMe] = useManualQuery<{ user: User }>(
    ME,
    { skipCache: true },
  );
  const [loginMutation] = useMutation(LOGIN);
  const [enrollMutation] = useMutation(USER_ENROLL);
  const [loginWithFacebook] = useMutation(LOGIN_WITH_FACEBOOK);
  const [loginWithGoogle] = useMutation(LOGIN_WITH_GOOGLE);
  const [loginWithApple] = useMutation(LOGIN_WITH_APPLE);
  const location = useLocation();

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleIsWelcomeModalVisible = useCallback(() => {
    setIsWelcomeModalVisible(!isWelcomeModalVisible);
  }, [
    isWelcomeModalVisible,
    setIsWelcomeModalVisible,
  ]);

  const logout = useCallback(async () => {
    localStorage.clear();
    //client.clearStore();
    setUser({
      ...user,
      isConnected: StateAuth.NOT_CONNECTED,
    } as User);
  }, [user]);

  const me = useCallback(async () => {
    const { data } = await getMe() as any;

    if (data?.user) {
      setUser({
        ...user,
        ...data!.user as User,
        isConnected: StateAuth.CONNECTED,
      });
      setTimeout(() => navigate('/', { replace: true }), 50);
    } else {
      setUser({
        ...user,
        isConnected: StateAuth.NOT_CONNECTED
      } as User);
    }
  }, [getMe, navigate, user]);

  const getAuthState = useCallback(async (): Promise<void> => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await me() as any;
      } else {
        setUser({
          ...user,
          isConnected: StateAuth.NOT_CONNECTED,
        } as User);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }, [me, user]);

  const signUp = useCallback(async (input: SignUpArgs) => {
    try {
      const obj = input;
      if (obj.provider === 'Google' && !isEmpty(input) && !input.idToken) {
        obj.idToken = window.localStorage.getItem('GOOGLE_ID_TOKEN')!;
      } else if (obj.provider === 'Apple') {
        obj.idToken = window.localStorage.getItem('APPLE_USER_ID_TOKEN')!;
        obj.userAppleId = window.localStorage.getItem('APPLE_USER_ID')!;
      }

      const { data } = (await enrollMutation({
        variables: {
          input: {
            ...obj,
            language: user.language,
            timezone: user.timezone,
          },
        },
      })) as any;

      localStorage.setItem(TOKEN_KEY, data.userEnroll.token);
      (client as any).setHeader('Authorization', `Bearer ${data.userEnroll.token}`);
      const { data: dataMe } = await getMe() as any;

      if (dataMe!.user) {
        localStorage.setItem('user', JSON.stringify(dataMe.user));
        setUser({
          ...user,
          ...dataMe!.user as User,
          isConnected: StateAuth.CONNECTED,
        });
        handleIsWelcomeModalVisible();
        navigate('/', { replace: true });
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }, [
    client,
    enrollMutation,
    getMe,
    handleIsWelcomeModalVisible,
    navigate,
    user,
  ]);

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      const { data: { login } } = await loginMutation({
        variables: {
          email,
          password,
        },
      }) as any;
      localStorage.setItem('token', login.token);
      (client as any).setHeader('Authorization', `Bearer ${login.token}`);
      await me();
    } catch (error: any) {
      throw new Error(error);
    }
  }, [
    client,
    loginMutation,
    me,
  ]);

  const signApple = useCallback(async (
    idToken: string,
    userAppleId: string,
  ) => {
    try {
      const { data } = await loginWithApple({
        variables: {
          idToken,
          userAppleId,
        },
      });
      localStorage.setItem('token', data.loginWithApple.token);
      (client as any).setHeader('Authorization', `Bearer ${data.loginWithApple.token}`);
      await me();
    } catch (error: any) {
      throw new Error(error);
    }
  }, [
    client,
    me,
    loginWithApple,
  ]);

  const signInWithApple = useCallback(async (response: any) => {
    const { id_token } = response.authorization;
    const appleInfo = jwt_decode(id_token) as {
      sub: string;
      email: string;
    };

    if (response && isEmpty(response.user)) {
      if (appleInfo.email) {
        const { data } = await userEmailExist({
          variables: {
            email: appleInfo.email!.toLowerCase(),
          },
        });
        if (!data.userEmailExist) {
          const tmp = localStorage.getItem('APPLE_USER_INFO') as any;
          if (tmp) {
            const obj = JSON.parse(tmp);
            if (obj && !isEmpty(obj) && obj.email && obj.username) {
              return { ...obj, provider: 'Apple' };
            } else {
              setErrorMessage({ active: true, msg: 'Error Apple authentication with storage data' });
            }
          } else {
            setErrorMessage({ active: true, msg: 'Error Apple authentication with userInfo storage' });
          }
        } else {
          await signApple(id_token, appleInfo.sub);
        }
      }
    } else if (response && response.user && !isEmpty(response.user)) {
      localStorage.setItem(`APPLE_USER_INFO`, JSON.stringify(response.user));
      localStorage.setItem(`APPLE_USER_ID`, appleInfo.sub);
      localStorage.setItem(`APPLE_USER_ID_TOKEN`, id_token);
      const user = response.user as { email: string; name: { firstName: string, lastName: string } };
      const userInfo = {
        email: user.email,
      } as SignUpArgs;
      if (!isEmpty(user.name) && user.name.firstName && user.name.lastName) {
        userInfo.username = `${user.name!.firstName}_${user.name.lastName}`.trim().replace(/\s/g, '_')
      }
      return { ...userInfo, provider: 'Apple' };
    }
  }, [
    userEmailExist,
    signApple,
  ]);

  const signFacebook = useCallback(async (
    email: string,
    accessToken: string,
  ) => {
    try {
      const { data } = await loginWithFacebook({
        variables: {
          email,
          accessToken,
        },
      });
      localStorage.setItem('token', data.loginWithFacebook.token);
      (client as any).setHeader('Authorization', `Bearer ${data.loginWithFacebook.token}`);
      await me();
    } catch (error: any) {
      throw new Error(error);
    }
  }, [
    client,
    me,
    loginWithFacebook,
  ]);

  const signInWithFacebook = useCallback(async (response: any) => {
    const today = new Date();
    const year = today.getFullYear() - 13;
    const d = new Date();
    d.setFullYear(year);

    if (response && !isEmpty(response) && response.accessToken) {
      const { data } = await userByFacebookApi({
        variables: {
          accessToken: response.accessToken,
        },
      });

      if (data.userByFacebookApi && !isEmpty(data.userByFacebookApi)) {
        const userFacebook = data.userByFacebookApi;

        if (userFacebook.userId) {
          signFacebook(userFacebook.email, response.accessToken);
        } else if (userFacebook.birthday) {
          if (userFacebook.birthday < d.valueOf()) {
            signUp({
              email: userFacebook.email,
              username: userFacebook.name.trim().replace(/\s/g, '_'),
              provider: 'Facebook',
              dateOfBirth: userFacebook.birthday.toString(),
              accessToken: response.accessToken!,
            });
          } else {
            setErrorMessage({ active: true, msg: errorMinimumAge });
          }
        } else {
          return {
            provider: 'Facebook',
            email: userFacebook.email,
            username: userFacebook.name.trim().replace(/\s/g, '_'),
            accessToken: response.accessToken!,
          }
        }
      }
    }
  }, [
    errorMinimumAge,
    userByFacebookApi,
    signFacebook,
    signUp,
  ]);

  const signGoogle = useCallback(async (
    email: string,
    idToken: string,
  ) => {
    try {
      const { data } = await loginWithGoogle({
        variables: {
          email,
          idToken,
        },
      });
      localStorage.setItem('token', data.loginWithGoogle.token);
      (client as any).setHeader('Authorization', `Bearer ${data.loginWithGoogle.token}`);
      await me();
    } catch (error: any) {
      throw new Error(error);
    }
  }, [
    client,
    me,
    loginWithGoogle,
  ]);

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const signInWithGoogle = useCallback(async (response: any) => {
    const today = new Date();
    const year = today.getFullYear() - 13;
    const d = new Date();
    d.setFullYear(year);

    if (response && !isEmpty(response)) {
      const { data } = await userByGoogleApi({
        variables: {
          code: response.code,
        },
      });

      if (data.userByGoogleApi && !isEmpty(data.userByGoogleApi)) {
        const userGoogle = data.userByGoogleApi;
        if (userGoogle.userId) {
          signGoogle(userGoogle.email, userGoogle.idToken);
        } else if (userGoogle.birthday) {
          if (userGoogle.birthday < d.valueOf()) {
            signUp({
              email: userGoogle.email,
              username: userGoogle.name.trim().replace(/\s/g, '_'),
              provider: 'Google',
              dateOfBirth: userGoogle.birthday.toString(),
              idToken: userGoogle.idToken,
            });
          } else {
            setErrorMessage({ active: true, msg: errorMinimumAge });
          }
        } else {
          localStorage.setItem('GOOGLE_ID_TOKEN', userGoogle.idToken);
          return {
            provider: 'Google',
            email: userGoogle.email,
            username: userGoogle.name.trim().replace(/\s/g, '_')
          }
        }
      }
    }
  }, [
    errorMinimumAge,
    userByGoogleApi,
    signGoogle,
    signUp,
  ]);

  const redirectToApp = useCallback(() => {
    if (user.os === 'ios' && user.language === 'fr') {
      window.location.href = 'https://apps.apple.com/fr/app/soskills/id1635127812';
    } else if (user.os === 'ios' && user.language === 'en') {
      window.location.href = 'https://apps.apple.com/app/soskills/id1635127812';
    } else if (user.os === 'android') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.soskills';
    }
  }, [user]);

  useEffect(() => {
    //if (window && window.navigator && window.navigator.userAgent) {
    //  const res = isBot(window.navigator.userAgent);
    //  if (res) {
    //    userCrawler({
    //      variables: {
    //        input: {
    //          userAgent: window.navigator.userAgent,
    //        },
    //      },
    //    })
    //  }
    //}
    (client as any).mutationsEmitter.setMaxListeners(0);
    const token = window.localStorage.getItem('token');
    if (token) {
      (client as any).setHeader('Authorization', `Bearer ${token}`);
    }
    if (location.pathname === '/app') {
      if (isMobile) {
        redirectToApp();
      } else {
        window.location.href = '/';
      }
    } else if (location.pathname === '/app/apple' && user.language === 'fr') {
      window.location.href = 'https://apps.apple.com/fr/app/soskills/id1635127812';
    } else if (location.pathname === 'app/apple' && user.language === 'en') {
      window.location.href = 'https://apps.apple.com/app/soskills/id1635127812';
    } else if (location.pathname === '/app/android') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.soskills';
    } else {
      getAuthState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.language]);

  useEffect(() => {
    if (innerHeight && innerHeight !== height) {
      setIsMobile(mobile());
      setInnerHeight(height);
    }
  }, [
    height,
    innerHeight,
    setIsMobile,
    setInnerHeight,
    width,
    isMobile
  ]);

  const objMemo = useMemo(() => ({
    logout,
    redirectToApp,
    setUser,
    signInWithFacebook,
    signInWithApple,
    signInWithGoogle,
    signIn,
    signUp,
    user,
  } as unknown as Context), [
    logout,
    redirectToApp,
    setUser,
    signInWithFacebook,
    signInWithApple,
    signInWithGoogle,
    signIn,
    signUp,
    user,
  ]);

  return (
    <>
      <AuthContext.Provider value={objMemo}>
        {children}
      </AuthContext.Provider>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export {
  AuthProvider,
  AuthContext,
  StateAuth,
  useAuth,
};
export type { Context };
