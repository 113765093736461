import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Button,
  Container,
  Grid,
  Link,
  Pagination,
  Popover,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import {
  useMutation,
  useManualQuery,
} from 'graphql-hooks';
import { useTranslation } from 'react-i18next';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useAtom } from 'jotai';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HoverVideoPlayer from 'react-hover-video-player';

import { THUMBNAIL_VIDEOS_PAGINATION } from '../../graphql/video/query';
import { DELETE_VIDEO } from '../../graphql/video/mutation';
import { getFormatViews, getFormatDate } from '../../helpers';
import { User, Video } from '../../types';
import {
  isMobileAtom,
  isVideoSuspendedAtom,
  isVideoUploadedAtom,
} from '../../atoms';
import styles from './style';
import AddVideoModalfrom from '../../components/Modals/AddVideoModal/AddVideoModal';

interface VideoPagination {
  filter: Filter;
  docs: Video[];
  infos: {
    limit: number;
    offset: number;
    totalDocs: number;
  };
}

interface Filter {
  offset?: number;
  limit?: number;
  userId?: string;
}

interface FilterProps {
  filterData: Filter;
}

interface OpenSettingPopover {
  active: boolean;
  videoId?: string;
}

interface VideosFormProps {
  user: Partial<User>;
  admin?: boolean;
  onChangeVideoInput?: (event: any) => Promise<void>;
  valueInputAddVideo?: string;
}

const Videos: React.FC<VideosFormProps> = (props) => {
  const {
    admin,
    onChangeVideoInput,
    user,
    valueInputAddVideo,
  } = props;
  const [isMobile] = useAtom(isMobileAtom);
  const [isVideoSuspended, setIsVideoSuspended] = useAtom(isVideoSuspendedAtom);
  const [isVideoUploaded, setIsVideoUploaded] = useAtom(isVideoUploadedAtom);
  const { t } = useTranslation();
  const cancelTrad = t('cancel');
  const showYourSkillsTrad = t('showYourSkills');
  const addVideoProfileTrad = t('addVideoProfile');
  const confirmDeleteVideoTrad = t('confirmDeleteVideo');
  const deleteVideoTrad = t('deleteVideo');
  const deleteTrad = t('delete');
  const videoDetailsTrad = t('videoDetails');
  const addVideoTrad = t('addVideo');
  const [currentPage, setCurrentPage] = useState(0);
  const [videoPreview, setVideoPreview] = useState({} as { id: string });
  const [loading, setLoading] = useState(false);
  const [isAddVideoModalVisible, setIsAddVideoModalVisible] = useState(false);
  const [openSettingPopover, setOpenSettingPopover] = useState({ active: false } as OpenSettingPopover);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [videos, setVideos] = useState({
    docs: [] as Video[],
    filter: {} as Filter,
  } as VideoPagination);
  const [videosPagination] = useManualQuery(
    THUMBNAIL_VIDEOS_PAGINATION,
    { skipCache: true },
  );
  const [deleteVideo] = useMutation(DELETE_VIDEO);
  const handleIsAddVideoModalVisible = useCallback(() => {
    setIsAddVideoModalVisible(!isAddVideoModalVisible);
  }, [isAddVideoModalVisible]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onOpenPopover = (event: any, video: Video) => {
    setAnchorEl(event.currentTarget);
    setOpenSettingPopover({ active: true, videoId: video.id });
  };

  const onClosePopover = () => {
    setOpenSettingPopover({ active: false });
    setAnchorEl(null);
  };

  const onCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
  }, []);

  const getData = useCallback(async ({ filterData }: FilterProps) => {
    setLoading(true);
    const input = admin ? filterData : { ...filterData, userId: user.id };
    const { data } = await videosPagination({
      variables: {
        input,
      },
    }) as any;
    if (data && data.videosPagination && data.videosPagination.docs && data.videosPagination.docs.length > 0) {
      if (isVideoUploaded) {
        setIsVideoUploaded(false);
      } else if (isVideoSuspended) {
        setIsVideoSuspended(false);
      }
      setVideos({
        filter: filterData,
        infos: {
          totalDocs: data.videosPagination.totalDocs,
          limit: data.videosPagination.limit,
          offset: data.videosPagination.offset,
        },
        docs: data.videosPagination.docs,
      });
    }
    setLoading(false);
  }, [
    videosPagination,
    isVideoSuspended,
    setIsVideoSuspended,
    user.id,
    isVideoUploaded,
    setIsVideoUploaded,
    admin,
  ]);

  const onChangePagination = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    getData({
      filterData: {
        offset: (page - 1) * 10,
        limit: 10,
      },
    });
    setCurrentPage(page - 1);
  }, [getData]);

  useEffect(() => {
    getData({
      filterData: {
        offset: 0,
        limit: 10,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVideoUploaded || isVideoSuspended) {
      getData({
        filterData: {
          offset: 0,
          limit: 10,
        },
      });
    }
  }, [isVideoSuspended, isVideoUploaded, getData]);

  const onDeleteVideo = useCallback(async () => {
    if (openSettingPopover.videoId) {
      const { data } = await deleteVideo({
        variables: {
          id: openSettingPopover.videoId
        }
      }) as any;
      if (data.deleteVideo) {
        setOpenDialogDelete(false);
        setSuccessMessage({ active: true, msg: t('successDeleteVideo') });
        getData({
          filterData: {
            offset: (currentPage) * 10,
            limit: 10,
          },
        });
      }
    }
  }, [
    deleteVideo,
    getData,
    openSettingPopover.videoId,
    t,
    currentPage
  ]);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const videoDetails = useCallback(() => {
    setVideoPreview({ id: openSettingPopover.videoId! });
    handleIsAddVideoModalVisible();
    setOpenSettingPopover({ active: false });
  }, [
    handleIsAddVideoModalVisible,
    openSettingPopover.videoId
  ]);

  const openDetailsVideo = useCallback((videoId: string) => {
    setVideoPreview({ id: videoId });
    handleIsAddVideoModalVisible();
  }, [
    handleIsAddVideoModalVisible,
  ]);

  const videoStyle = useMemo(() => ({
    borderRadius: 5,
    height: !isMobile ? 130 : 70,
    width: !isMobile ? '100%' : 55
  }), [isMobile]);

  const heightImgOverlay = useMemo(() => !isMobile ? 130 : 70, [isMobile]);
  const widthImgOverlay = useMemo(() => !isMobile ? '100%' : 55, [isMobile]);

  const onOpenSettingPopover = useCallback(() => {
    setOpenSettingPopover({ ...openSettingPopover, active: false });
    setOpenDialogDelete(true)
  }, [openSettingPopover, setOpenSettingPopover, setOpenDialogDelete]);

  const loadingOverlay = useMemo(() => (
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  ), []);

  return (
    <>
      {videos && videos.docs && videos.docs.length > 0 && (
        <Grid
          container
          direction="row"
        >
          {(videos.docs.map((video, index) => {
            const mb = {
              xs: index > 4 ? 2 : 2,
              sm: index > 4 ? 5 : 6,
            };
            return (
              <React.Fragment key={video.id}>
                <Grid
                  key={video.id}
                  item
                  xs={2.25}
                  mb={mb}
                  ml={((index as number) !== 4 || (index as number) !== 9) ? '1.05%' : 0}
                >
                  <Grid container>
                    <Grid item xs={12}
                    >
                      <HoverVideoPlayer
                        sizingMode="overlay"
                        controls
                        muted={true}
                        volume={1}
                        unloadVideoOnPaused
                        crossOrigin="anonymous"
                        videoSrc={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.path}`}
                        //loop={true}
                        style={styles.hoverBlock}
                        videoStyle={videoStyle}
                        pausedOverlay={
                          <>
                            <img
                              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.thumbnail}`}
                              height={heightImgOverlay}
                              width={widthImgOverlay}
                              style={styles.imgOverlay}
                              alt=""
                            />
                          </>
                        }
                        loadingOverlay={loadingOverlay}
                      />
                    </Grid>
                    <Grid item xs={10} mt={1}>
                      {!isMobile ? (
                        <>
                          <Typography fontSize={12}>
                            {getFormatViews(video.views)}
                            &nbsp;
                            {t('views')} &nbsp; -
                            &nbsp;
                            {user.language === 'en' ? (
                              <>
                                {getFormatDate(new Date(), video.createdAt, t)}
                                &nbsp;ago
                              </>
                            ) : (
                              <>
                                Il y a
                                {getFormatDate(new Date(), video.createdAt, t)}
                              </>
                            )}
                          </Typography>
                        </>
                      ) : (
                        <Typography sx={{
                          fontSize: 8,
                        }}>
                          {getFormatViews(video.views)}
                          {t('views')}
                          {user.language === 'en' ? (
                            <>
                              {getFormatDate(new Date(), video.createdAt, t)}
                              ago
                            </>
                          ) : (
                            <>
                              Il y a
                              {getFormatDate(new Date(), video.createdAt, t)}
                            </>
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      mt={1}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {user && user.role === 'Admin' && (
                        <FontAwesomeIcon
                          icon={faInfoCircle} size="1x" color="black"
                          onClick={() => openDetailsVideo(video.id)}
                          style={styles.cursor}
                        />
                      )}
                      {user && user.role === 'User' && (
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          size="1x"
                          onClick={(e) => onOpenPopover(e, video)}
                          style={styles.cursor}
                        />
                      )}
                      {user && user.role === 'User' && (
                        <Popover
                          open={openSettingPopover.active}
                          anchorEl={anchorEl}
                          onClose={onClosePopover}
                        >
                          <Grid container direction="row" rowSpacing={1} my={1}>
                            <Grid
                              py={1}
                              container
                              sx={styles.videoDetailsGrid}
                              onClick={videoDetails}
                            >
                              <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" color="black" />
                              </Grid>
                              <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                                <Typography color="black" variant="body2">
                                  {videoDetailsTrad}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              py={1}
                              sx={styles.deleteVideoGrid}
                              onClick={onOpenSettingPopover}
                            >
                              <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                <FontAwesomeIcon icon={faTrash} size="1x" color="black" />
                              </Grid>
                              <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                                <Typography variant="body2" color="black">
                                  {deleteVideoTrad}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Popover>
                      )}
                    </Grid>
                    {
                      !isMobile
                      && admin
                      && video.user.username
                      && video.user.twitter
                      && (
                        <Grid
                          container
                          flexDirection="row"
                        >
                          <Grid
                            item
                            xs={3.5}
                            mt={0.25}
                          >
                            <Typography sx={styles.linkTypography}>
                              Twitter:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            mt={0.25}
                          >
                            <Link
                              href={`https://twitter.com/${video.user.twitter}`}
                              target="_blank"
                              sx={styles.linkHover}
                            >
                              <Typography sx={styles.linkTypography}>
                                {video.user.twitter}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                  <Dialog
                    BackdropProps={{ invisible: true }}
                    open={openDialogDelete}
                    onClose={onCloseDialogDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                        {deleteVideoTrad}
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Typography variant="body1" color="black">
                          {confirmDeleteVideoTrad}
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={onCloseDialogDelete}
                        sx={styles.buttonCancel}
                        variant="contained"
                        color="error"
                      >
                        <Typography fontWeight="bold">
                          {cancelTrad}
                        </Typography>
                      </Button>
                      <Button
                        onClick={onDeleteVideo}
                        variant="contained"
                        color="primary"
                        sx={styles.buttonDelete}
                      >
                        <Typography fontWeight="bold">
                          {deleteTrad}
                        </Typography>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </React.Fragment>
            )
          }))}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Stack spacing={100}>
              <Pagination
                page={currentPage + 1}
                count={Math.ceil(videos.infos.totalDocs / 10)}
                onChange={onChangePagination}
                color="primary"
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      {!loading && videos && videos.docs && !videos.docs.length && (
        <Container maxWidth="xs">
          <Grid container direction="row" py={2} mx={2}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography fontWeight="bold" color="#212121" variant="h5">
                {showYourSkillsTrad}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Typography variant="body1" color="black" textAlign={'justify'}>
                {addVideoProfileTrad}
              </Typography>
            </Grid>
            {!isMobile && !admin && (
              <Grid item xs={12} mt={2.5} display="flex" justifyContent="center">
                <label>
                  <input
                    value={valueInputAddVideo}
                    onChange={onChangeVideoInput}
                    accept="video/*"
                    multiple={false}
                    style={styles.inputAddVideo}
                    type="file"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<AddIcon />}
                    sx={styles.buttonAddVideo}
                  >
                    <Typography fontWeight="bold">
                      {addVideoTrad}
                    </Typography>
                  </Button>
                </label>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
      {
        isAddVideoModalVisible && (
          <AddVideoModalfrom
            videoPreview={videoPreview}
            isAddVideoModalVisible={isAddVideoModalVisible}
            handleIsAddVideoModalVisible={handleIsAddVideoModalVisible}
          />
        )
      }
    </>
  );
}

export default React.memo(Videos);
