import { useNavigate } from "react-router-dom";
import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './style';

const NotFound = React.memo(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pageNotAvailableTrad = t('pageNotAvailable');
  const goBackHomeTrad = t('goBackToHome');

  const goBackToHome = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <>
      <Container maxWidth="sm" sx={styles.container}>
        <Paper elevation={10} sx={styles.paper}>
          <Box mx={2} py={2}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                  {pageNotAvailableTrad}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <Button
                  variant="contained"
                  onClick={goBackToHome}
                  color="primary"
                  sx={styles.button}
                >
                  <Typography fontWeight="bold">
                    {goBackHomeTrad}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
});

export default NotFound;
