import {
  Box,
  Grid,
  Paper,
  Snackbar,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useManualQuery } from 'graphql-hooks';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { Context, useAuth } from '../../context/auth';
import { STAT_BY_USER_ID } from '../../graphql/stat';
import { Stat } from '../../types';
import { isMobileAtom } from '../../atoms';
import AddVideoModalfrom from '../../components/Modals/AddVideoModal/AddVideoModal';
import CropAvatarModal from '../../components/Modals/CropAvatarModal/CropAvatarModal';
import ProfileTab from './ProfileTab/ProfileTab';
import ProfileStat from './ProfileStat/ProfileStat';
import ProfileAddVideo from './ProfileAddVideo/ProfileAddVideo';
import styles from './style';

interface VideoPreview {
  filename: string;
  file: any;
  path: string;
  originalSize: number;
}

interface AvatarPreview {
  file: any;
  path: string;
  filename: string;
}

const Profile = React.memo(() => {
  const { user } = useAuth() as Context;
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const [videoPreview, setVideoPreview] = useState({} as VideoPreview);
  const [avatarPreview, setAvatarPreview] = useState({} as AvatarPreview);
  const [stat, setStat] = useState({} as Stat);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [statByUserId] = useManualQuery(
    STAT_BY_USER_ID,
    { skipCache: true },
  );
  const [value, setValue] = React.useState('1');
  const [valueInputAddVideo, setValueInputAddVideo] = React.useState('');
  const [valueInputAddAvatar, setValueInputAddAvatar] = React.useState('');
  const [isAddVideoModalVisible, setIsAddVideoModalVisible] = useState(false);
  const [isCropAvatarModalVisible, setIsCropAvatarModalVisible] = useState(false);
  const handleIsAddVideoModalVisible = useCallback(() => {
    setIsAddVideoModalVisible(!isAddVideoModalVisible);
  }, [isAddVideoModalVisible]);
  const handleIsCropAvatarModalVisible = useCallback(() => {
    setIsCropAvatarModalVisible(!isCropAvatarModalVisible);
  }, [isCropAvatarModalVisible]);
  const videoTitle = t('videos');
  const followsTitle = t('follows');
  const followersTitle = t('followers');
  const videoMaxTimeTrad = t('videoMaxTime');
  const videoMaxSizeTrad = t('videoMaxSize');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getStatByUserId = useCallback(async () => {
    const { id } = user;
    const { data } = await statByUserId({
      variables: {
        userId: id,
      },
    }) as any;
    if (data.statByUserId) {
      setStat(data.statByUserId);
    }
  }, [statByUserId, user]);

  useEffect(() => {
    getStatByUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVideoDuration = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result as any);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const getPathFile = (file: any) => {
    let url = '';
    if (window.URL) {
      url = window.URL.createObjectURL(file);
    } else if (window.webkitURL) {
      url = window.webkitURL.createObjectURL(file);
    }
    return url;
  }

  const onChangeVideoInput = useCallback(async (event: any) => {
    if (event.target.files.length) {
      const tmpFile = event.target.files[0];
      const originalSize = tmpFile.size;
      const maxSize = 100 * 1024 * 1024; // 100 Mo en octets
      const duration = await getVideoDuration(event.target.files[0]) as number;
      if (originalSize > maxSize) {
        setErrorMessage({
          active: true,
          msg: videoMaxSizeTrad,
        });
      } else if (duration > 60) {
        setErrorMessage({
          active: true,
          msg: videoMaxTimeTrad,
        })
      } else {
        setVideoPreview({
          filename: tmpFile.name,
          file: tmpFile,
          originalSize,
          path: getPathFile(tmpFile),
        });
        handleIsAddVideoModalVisible();
        setValueInputAddVideo('');
      }
    }
  }, [
    videoMaxSizeTrad,
    videoMaxTimeTrad,
    handleIsAddVideoModalVisible,
  ]);

  const onChangeAvatarInput = useCallback(async (event: any) => {
    if (event.target.files.length) {
      setAvatarPreview({
        file: event.target.files[0],
        path: getPathFile(event.target.files[0]),
        filename: event.target.files[0].name,
      });
      handleIsCropAvatarModalVisible();
      setValueInputAddAvatar('');
    }
  }, [handleIsCropAvatarModalVisible]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={styles.container}
    >
      <Grid
        item
        xs={12}
      >
        <Paper elevation={3}>
          <Box
            m={2}
            pt={2}
            sx={styles.box}
          >
            <Grid container direction="row">
              <ProfileStat
                followersTitle={followersTitle}
                followsTitle={followsTitle}
                isMobile={isMobile}
                onChangeAvatarInput={onChangeAvatarInput}
                stat={stat}
                user={user}
                valueInputAddAvatar={valueInputAddAvatar}
                videoTitle={videoTitle}
              />
              {!isMobile && (
                <ProfileAddVideo
                  onChangeVideoInput={onChangeVideoInput}
                  valueInputAddVideo={valueInputAddVideo}
                  t={t}
                />
              )}
            </Grid>
          </Box>
          <ProfileTab
            handleChange={handleChange}
            onChangeVideoInput={onChangeVideoInput}
            t={t}
            user={user}
            value={value}
            valueInputAddVideo={valueInputAddVideo}
          />
        </Paper>
      </Grid>
      {
        isAddVideoModalVisible && (
          <AddVideoModalfrom
            videoPreview={videoPreview}
            isAddVideoModalVisible={isAddVideoModalVisible}
            handleIsAddVideoModalVisible={handleIsAddVideoModalVisible}
          />
        )
      }
      {
        isCropAvatarModalVisible && (
          <CropAvatarModal
            avatarPreview={avatarPreview}
            isCropAvatarModalVisible={isCropAvatarModalVisible}
            handleIsCropAvatarModalVisible={handleIsCropAvatarModalVisible}
          />
        )
      }
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>

    </Grid>
  );
});

export default Profile;
