export const VIDEO_SIGN_URL = `
  mutation videoSignUrl($filename: String!) {
    videoSignUrl(filename: $filename) 
  }
`;

export const VIDEO_DRAFT_V2 = `
  mutation videoDraftV2($input: VideoDraftInput) {
    videoDraftV2(input: $input) {
      id
    }
  }
`;

export const PUBLISH_VIDEO = `
  mutation publishVideo($input: PublishVideoInput) {
    publishVideo(
      input: $input
    ) {
      id
      filename
      description
      kills
      mode
      height
      gameName
      categoryName
      path
      userId
      weapon
      width
      thumbnail
    }
  }
`;

export const DELETE_DRAFT_VIDEO_ON_AWS = `
  mutation deleteDraftVideoOnAWS($id: ID!) {
    deleteDraftVideoOnAWS(
      id: $id
    )
  }
`;

export const DELETE_VIDEO = `
  mutation deleteVideo($id: ID!) {
    deleteVideo(id: $id)
  }
`;

export const SUSPEND_VIDEO = `
  mutation suspendVideo($id: ID!) {
    suspendVideo(id: $id)
  }
`;

export const UPDATE_VIDEO = `
  mutation videoUpdate($input: UpdateVideoInput!) {
    videoUpdate(input: $input) {
      id
      filename
      description
      kills
      mode
      height
      gameName
      categoryName
      userId
      weapon
      width
      thumbnail
    }
  }
`;
