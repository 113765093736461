import { SnackbarOrigin } from "@mui/material";

const styles = {
  alert: {
    width: '100%',
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  box: {
    height: '10vh',
  },
  container: {
    paddingLeft: 0.4,
    paddingRight: 0.4,
    height: '100%',
  },
};

export default styles;
