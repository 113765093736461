const styles = {
  row: {
    position: 'absolute',
    width: '100%',
    right: '1.3rem',
    top: '4.5rem',
    zIndex: 10,
  },
};

export default styles;
