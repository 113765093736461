import { BadgeOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  } as BadgeOrigin,
  avatar: {
    width: { xs: 50, sm: 100 },
    height: { xs: 50, sm: 100 },
  },
  fontAwesomeIcon: {
    color: 'black',
    background: 'white',
    cursor: "grab",
    borderRadius: 20,
  },
  inputStyle: {
    display: 'none',
  },
};

export default styles;
