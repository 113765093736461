import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import ReactPlayer from 'react-player';
import isEmpty from 'lodash/isEmpty';

import { User, Video } from '../../types';
import { useAuth, Context } from '../../context/auth';
import { currentIndexAtom } from '../../atoms';
import ShortModal from '../Modals/ShortModal/ShortModal';
import Actions from './Actions/Actions';
import Footer from './Footer/Footer';
import Mute from './Mute/Mute';
import Play from './Play/Play';
import styles from './style';

interface Config {
  muted: boolean;
  playing: boolean;
  paused: boolean;
  ready: boolean;
}

const Post: React.FC<{
  index: number;
  innerHeight: number;
  user: User;
  video: Video,
  redirect?: boolean;
  id?: string,
  key?: string,
}> = React.memo((props) => {
  const {
    index,
    innerHeight,
    user,
    video,
  } = props;
  const { redirectToApp } = useAuth() as Context;
  const ref = React.useRef(null) as any;
  const [currentIndex] = useAtom(currentIndexAtom);
  const [muted, setMuted] = useState(true);
  const [isShortModalVisible, setIsShortModalVisible] = useState(!index);
  const [config, setConfig] = useState({
    playing: false,
    paused: false,
  } as Config)
  const url = useMemo(() => (`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.stream}`), [video.stream]);

  const closeShortModal = useCallback(() => {
    if (!index) {
      setMuted(false);
    }
    setIsShortModalVisible(false);
  }, [index]);

  const openShortModal = useCallback(() => {
    setIsShortModalVisible(true);
  }, []);

  const heightPlayer = useMemo(() => {
    let height: any;
    if (video.height > video.width) {
      height = '5100%';
    } else {
      height = '100%';
    }
    return height;
  }, [video]);

  const widthPlayer = useMemo(() => {
    let width: any;
    if (video.height > video.width) {
      width = '100%';
    } else {
      width = '100%';
    }
    return width;
  }, [video]);

  const tapVideo = useCallback(() => {
    setConfig({
      ...config,
      paused: !config.paused
    });
  }, [config]);

  const changeMutedOption = useCallback(() => {
    setConfig({
      ...config,
      muted: !config.muted,
    });
  }, [config]);

  const interactAsUser = useCallback(() => {
    if (muted) {
      setMuted(false);
      window.removeEventListener('touchstart', ref.current);
    }
  }, [muted, ref, setMuted]);

  useEffect(() => {
    if (index) {
      ref.current = window.addEventListener('touchstart', interactAsUser) as any;
    }

    return () => {
      if (index) {
        window.removeEventListener('touchstart', ref.current);
      }
    }
  }, [index, interactAsUser]);

  if (video && isEmpty(video)) {
    return null;
  }

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={styles.container}
    >
      {
        !index
        && index === currentIndex
        && (
          <Mute
            changeMutedOption={changeMutedOption}
            muted={muted}
          />
        )
      }

      <Play
        innerHeight={innerHeight}
        os={user.os}
        paused={config.paused}
        tapVideo={tapVideo}
      />

      <Grid
        item
        xs={12}
        display="flex"
      >
        <ReactPlayer
          playsinline
          loop
          playing={index === currentIndex && !config.paused}
          muted={muted}
          url={url}
          type="video/m3u8"
          style={styles.reactPlayer}
          height={heightPlayer}
          width={widthPlayer}
        />
      </Grid>

      <Actions
        innerHeight={innerHeight}
        openShortModal={openShortModal}
        os={user.os}
        video={video}
      />

      <Footer
        video={video}
        currentIndex={currentIndex}
        user={user}
      />

      <ShortModal
        user={user}
        codeshare={video.codeshare}
        innerHeight={innerHeight}
        isShortModalVisible={isShortModalVisible}
        closeShortModal={closeShortModal}
        redirectToApp={redirectToApp}
      />
    </Grid>
  );
});

export default Post;
