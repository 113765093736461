import { SnackbarOrigin } from "@mui/material";

const styles = {
  alert: {
    width: '100%',
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  container: {
    paddingLeft: 0.4,
    paddingRight: 0.4,
    height: '100%',
  },
  linkTextDecoration: {
    textDecoration: 'none',
  },
  paper: {
    backgroundColor: 'white',
  },
  signupButton: {
    textTransform: 'none',
    borderRadius: 1,
  },
}

export default styles;
