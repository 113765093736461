import React, { useCallback, useState } from 'react';
import { useManualQuery } from 'graphql-hooks';
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Grid,
  InputBase,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

import { User } from '../../types';
import { USERS_SEARCH } from '../../graphql/user/query';
import UserModal from '../Modals/UserModal/UserModal';
import MessageToUserModal from '../Modals/MessageToUserModal/MessageToUserModal';
import styles from './style';

const clickHandlers = (...handlers: Array<(e: React.UIEvent<HTMLElement>) => void>) => {
  const callEventHandler = (e: React.UIEvent<HTMLElement>) => {
    if (e.detail <= 0) return;
    const handler = handlers[e.detail - 1];
    if (handler) {
      handler(e);
    }
  };

  const debounceHandler = debounce(function (e: React.UIEvent<HTMLElement>) {
    callEventHandler(e);
  }, 250);

  return (e: React.UIEvent<HTMLElement>) => {
    e.persist();
    debounceHandler(e);
  };
};

const UsersSearch: React.FC = () => {
  const { t } = useTranslation();
  const searchUserPlaceholder = t('searchUserPlaceholder');
  const [userPreview, setUserPreview] = useState({} as Partial<User>);
  const [usersSearch] = useManualQuery(
    USERS_SEARCH,
    { skipCache: true },
  );
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const handleIsUserModalVisible = useCallback(() => {
    setIsUserModalVisible(!isUserModalVisible);
  }, [isUserModalVisible]);
  const [isMessageToUserModalVisible, setIsMessageToUserModalVisible] = useState(false);
  const handleIsMessageToUserModalVisible = useCallback(() => {
    setIsMessageToUserModalVisible(!isMessageToUserModalVisible);
  }, [isMessageToUserModalVisible]);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([] as User[]);

  const goToUser = useCallback((user: Partial<User>) => {
    setUserPreview(user);
    handleIsUserModalVisible();
  }, [handleIsUserModalVisible]);

  const getData = useCallback(async (search: string) => {
    const { data } = await usersSearch({
      variables: {
        search,
      },
    });
    setUsers(data.usersSearch);
  }, [usersSearch]);

  const onChangeText = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 0 && value !== search) {
      getData(event.target.value);
    } else if (!value) {
      setUsers([] as User[]);
    }
    setSearch(value);
  }, [
    search,
    getData,
  ]);

  const openMessageUserModal = useCallback((user: Partial<User>) => {
    setUserPreview(user);
    setIsMessageToUserModalVisible(true);
  }, []);

  return (
    <>
      <Grid container direction="row" mb={2} mt={2}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Paper sx={styles.paper}>
            <InputBase
              value={search}
              sx={styles.inputBase}
              placeholder={searchUserPlaceholder}
              onChange={onChangeText}
            />
            <IconButton
              type="submit"
              sx={styles.searchIconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        rowSpacing={1}
        py={2}
      >
        <TableContainer>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    AVATAR
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    PSEUDO
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    EMAIL
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    TIMEZONE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="black" fontWeight="bold">
                    SUSPENDU
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="black" fontWeight="bold">
                    DATE D'INSCRIPTION
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="black" fontWeight="bold">
                    DATE DE NAISSANCE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {users && users.length > 0 && (
              <TableBody>
                {(users.map((user, index) => {
                  return (
                    <TableRow
                      sx={styles.cursor}
                      key={`${index}-${user.id}`}
                      hover
                      onClick={clickHandlers(() => goToUser(user), () => openMessageUserModal(user))}
                    >
                      <TableCell>
                        <Avatar
                          alt="profile"
                          src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${user.avatar}`}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="button" fontWeight="bold" color="grey">
                          {user.username}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {user.timezone}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {user.deletedAt ? 'OUI' : 'NON'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant='body2'>
                          {moment(user.createdAt).locale("fr").format('DD/MM/YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant='body2'>
                          {moment(user.dateOfBirth).locale("fr").format('DD/MM/YYYY')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                }))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      {
        isUserModalVisible && userPreview && !isEmpty(userPreview) && (
          <UserModal
            isUserModalVisible={isUserModalVisible}
            handleIsUserModalVisible={handleIsUserModalVisible}
            userId={userPreview.id!}
          />
        )
      }
      {
        isMessageToUserModalVisible && userPreview && !isEmpty(userPreview) && (
          <MessageToUserModal
            isMessageToUserModalVisible={isMessageToUserModalVisible}
            handleIsMessageToUserModalVisible={handleIsMessageToUserModalVisible}
            userId={userPreview.id!}
            username={userPreview.username!}
          />
        )
      }
    </>
  )
}

export default UsersSearch;
