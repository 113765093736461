import React, { useCallback, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import {
  Box,
  Grid,
  Modal,
  Button,
  Typography,
} from '@mui/material/';
import { useTranslation } from "react-i18next";
import AvatarEdit from 'react-avatar-edit';

import { USER_SIGN_URL, USER_UPDATE_AVATAR } from '../../../graphql/user/mutation';
import { Context, useAuth } from '../../../context/auth';
import styles from './style';

interface AvatarPreview {
  file: any;
  path: string;
  filename: string;
}

interface CropAvatarModalProps {
  isCropAvatarModalVisible: boolean;
  handleIsCropAvatarModalVisible: () => void;
  avatarPreview: AvatarPreview;
}

const CropAvatarModal: React.FC<CropAvatarModalProps> = React.memo((props) => {
  const {
    avatarPreview,
    isCropAvatarModalVisible,
    handleIsCropAvatarModalVisible,
  } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState({} as File);
  const { user, setUser } = useAuth() as Context;
  const [userSignUrl] = useMutation(USER_SIGN_URL);
  const [userUpdateAvatar] = useMutation(USER_UPDATE_AVATAR);
  const sendTrad = t('send');

  const onAvatarEditCrop = useCallback(async (preview: any) => {
    if (preview) {
      const base64Response = await fetch(preview);
      const blob = await base64Response.blob();
      const file = new File(
        [blob],
        `${Math.floor(1000 + Math.random() * 9000)}-avatar.${blob.type.split('/')[1]}`,
        { type: blob.type },
      );
      setFile(file);
    }
  }, []);

  const onAvatarEditClose = useCallback(async () => {
    handleIsCropAvatarModalVisible();
  }, [handleIsCropAvatarModalVisible]);

  const onSubmitAvatar = useCallback(async () => {
    if (file) {
      const { data } = await userSignUrl({
        variables: {
          filename: file.name,
        },
      });
      if (data.userSignUrl) {
        const myHeaders = new Headers({ 'Content-Type': 'video/*' });
        const response = await fetch(data.userSignUrl, {
          method: 'PUT',
          headers: myHeaders,
          body: file,
        });
        if (response.status === 200) {
          const { data: res } = await userUpdateAvatar({
            variables: {
              filename: file.name,
            },
          });
          if (res.userUpdateAvatar) {
            setUser({
              ...user,
              avatar: res.userUpdateAvatar.avatar,
            });
            handleIsCropAvatarModalVisible();
          }
        }
      }
    }
  }, [
    file,
    handleIsCropAvatarModalVisible,
    user,
    setUser,
    userUpdateAvatar,
    userSignUrl,
  ]);

  return (
    <Modal
      hideBackdrop
      open={isCropAvatarModalVisible}
      onClose={handleIsCropAvatarModalVisible}
      style={styles.modal}
    >
      <Box sx={styles.box}>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <AvatarEdit
              width={300}
              height={300}
              onCrop={onAvatarEditCrop}
              onClose={onAvatarEditClose}
              src={avatarPreview.path}
            />
          </Grid>
          <Grid item xs={12} mt={2} columnSpacing={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              sx={styles.sendButton}
              component="span"
              onClick={onSubmitAvatar}
            >
              <Typography fontWeight="bold">
                {sendTrad}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
});

export default CropAvatarModal;
