import React from 'react';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeHigh,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons';

import styles from './style';

interface MuteProps {
  muted: boolean;
  changeMutedOption: () => void;
}

const Mute = React.memo((props: MuteProps) => {
  const { muted, changeMutedOption } = props;

  return (
    <Grid
      item
      xs={12}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      sx={styles.row}
    >
      {muted ? (
        <FontAwesomeIcon
          color="white"
          onClick={changeMutedOption}
          icon={faVolumeMute}
          size="lg"
        />
      ) : (
        <FontAwesomeIcon
          color="white"
          onClick={changeMutedOption}
          icon={faVolumeHigh}
          size="lg"
        />
      )}
    </Grid>
  )
});

export default Mute;
