import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

interface PlayProps {
  innerHeight: number;
  os: string;
  paused: boolean;
  tapVideo: () => void;
}

const Play = React.memo((props: PlayProps) => {
  const {
    innerHeight,
    os,
    paused,
    tapVideo,
  } = props;

  const getHomeMobileWidth = useCallback(() => {
    let h = '21.5rem';
    if (innerHeight < 660 && os === 'ios') {
      h = '21.25rem';
    } else if (innerHeight < 660 && os === 'android') {
      h = '17.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'ios') {
      h = '26.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && os === 'android') {
      h = '27rem';
    } else if (innerHeight >= 735 && os === 'ios') {
      h = '29rem';
    } else if (innerHeight >= 735 && os === 'android') {
      h = '29rem';
    }
    return h;
  }, [innerHeight, os]);


  const playContainerMemo = useMemo(() => ({
    zIndex: 1,
    width: '80%',
    position: 'absolute',
    height: getHomeMobileWidth(),
  }), [getHomeMobileWidth]);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      onClick={tapVideo}
      sx={playContainerMemo}>
      {paused && (
        <Grid
          xs={12}
          item
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <FontAwesomeIcon
            color="white"
            icon={faPlay}
            size="5x"
          />
        </Grid>
      )}
    </Grid>
  );
});

export default Play;
