import { User } from './user';

enum CommentType {
  'PARENT' = 'PARENT',
  'CHILD' = 'CHILD',
}

interface Comment {
  commentIds: string[];
  comments: Comment[];
  createdAt: number;
  id: string;
  isLiked: boolean;
  message: string;
  likes: number;
  receiverId: string;
  receiver: User;
  type: CommentType;
  userId: string;
  user: User;
  videoId: string;
}

export { CommentType };
export type { Comment };
