import React, { useCallback, useEffect, useState } from 'react';
import { useManualQuery, useMutation } from 'graphql-hooks';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material/';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAtom } from 'jotai';
import Close from '@mui/icons-material/Close';
import isEmpty from 'lodash/isEmpty';

import {
  Comment,
  UserRoles,
  Report,
  ReportStatus,
  ReportType,
} from '../../../types';
import { COMMENT } from '../../../graphql/comment/query';
import { REPORT_UPDATE_STATUS } from '../../../graphql/report/mutation';
import { Context, useAuth } from '../../../context/auth';
import { isReportUpdatedAtom } from '../../../atoms';
import styles from './style';

interface ReportCommentModalProps {
  isReportCommentModalVisible: boolean;
  handleIsReportCommentModalVisible: () => void;
  report: Report;
}

const ReportCommentModal: React.FC<ReportCommentModalProps> = React.memo((props) => {
  const {
    isReportCommentModalVisible,
    handleIsReportCommentModalVisible,
    report,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });
  const [reportUpdateStatus] = useMutation(REPORT_UPDATE_STATUS);
  const [comment] = useManualQuery(COMMENT);
  const [c, setC] = useState({} as Comment);
  const { user } = useAuth() as Context;
  const [, setIsReportUpdated] = useAtom(isReportUpdatedAtom);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const onReportComment = useCallback(async (status: ReportStatus) => {
    if (!isEmpty(c) && c.id && user.role === UserRoles.ADMIN && !isEmpty(report)) {
      const { data } = await reportUpdateStatus({
        variables: {
          id: report!.id,
          status,
        }
      })
      if (data.reportUpdateStatus) {
        setIsReportUpdated(true);
        handleIsReportCommentModalVisible();
      }
    }
  }, [
    handleIsReportCommentModalVisible,
    reportUpdateStatus,
    report,
    setIsReportUpdated,
    user.role,
    c,
  ]);

  const getData = async () => {
    setLoading(true);
    if (report && report.commentId && report.type === ReportType.COMMENT) {
      const { data } = await comment({
        variables: {
          id: report.commentId,
        }
      })
      if (data.comment) {
        setC(data.comment);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && !isEmpty(c) && (
        <Modal
          hideBackdrop
          open={isReportCommentModalVisible}
          onClose={handleIsReportCommentModalVisible}
          style={styles.modal}
        >
          <Box sx={styles.box}>
            <Grid container direction="row" py={0.5}>
              <Grid item xs={12} display="flex" justifyContent="center" mb={3}>
                <Typography variant="h6" color="black" fontWeight="medium">
                  Message écrit par {c.user.username} / {c.user.email}
                </Typography>
                <div
                  onClick={handleIsReportCommentModalVisible}
                  style={styles.closeRow as any}
                >
                  <IconButton
                    sx={styles.closeIcon}
                    component="span"
                  >
                    <Close />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
                <Typography variant="body2" color="black">
                  {c.message}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  onClick={() => onReportComment(ReportStatus.REJECTED)}
                  color="error"
                  fullWidth
                  sx={styles.rejectButton}
                >
                  <Typography fontFamily="Roboto" fontWeight="medium">
                    REJECT
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  onClick={() => onReportComment(ReportStatus.CLOSED)}
                  color="error"
                  fullWidth
                  sx={styles.closeButton}
                >
                  <Typography fontFamily="Roboto" fontWeight="medium">
                    CLOSE
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
    </>
  )
});

export default ReportCommentModal;
