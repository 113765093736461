export const GAME_BY_NAME = `
  query gameByName($name: String!) {
    gameByName(name: $name) {
      id
      name
      weapons
      modes
    }
  }
`;

export const GAMES_BY_CATEGORY = `
  query gamesByCategoryId($categoryId: ID!) {
    gamesByCategoryId(categoryId: $categoryId) {
      id
      weapons
      name
      modes
    }
  }
`;
