export const REPORT_UPDATE_STATUS = `
  mutation reportUpdateStatus($id: ID!, $status: ReportStatus!) {
    reportUpdateStatus(
      id: $id,
      status: $status
    ) {
      id
      status
    }
  }
`;
