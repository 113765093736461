export const CREATE_CODE = `
  mutation createCode($input: createCodeInput) {
    createCode(
      input: $input
    ) {
      id
      email
    }
  }
`;

export const VALIDATE_CODE = `
  mutation validateCode($input: validateCodeInput) {
    validateCode(
      input: $input
    ) 
  }
`;
