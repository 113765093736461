import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Avatar, Box, Grid, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputer } from '@fortawesome/free-solid-svg-icons';
import { faXbox, faPlaystation } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'graphql-hooks';
import ReactPlayer from 'react-player';
import isEmpty from 'lodash/isEmpty';

import {
  USER_REDIRECT_TO_STORE,
} from '../../graphql/user/mutation';
import { User, Video } from '../../types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './style';

const PostDesktop: React.FC<{
  user: User;
  video: Video,
}> = (props) => {
  const {
    user,
    video,
  } = props;
  const { t } = useTranslation();
  const vidRef = React.useRef(null) as any;
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [userRedirectToStore] = useMutation(USER_REDIRECT_TO_STORE);
  const onUserRedirectToStore = useCallback((input: {
    os: string;
    option: string;
  }) => userRedirectToStore({
    variables: {
      input,
    },
  }), [userRedirectToStore]);

  const onEnded = useCallback(() => {
    vidRef.current!.seekTo(0);
  }, [vidRef]);

  const startVideo = useCallback(() => {
    if (!playing) {
      setMuted(true);
      setPlaying(true);
    }
  }, [playing]);

  const getPlatformIcon = useCallback(() => {
    if (video.platform === 'PC') {
      return faComputer;
    } else if (video.platform === 'Xbox') {
      return faXbox;
    } else if (video.platform === 'Playstation') {
      return faPlaystation;
    }
  }, [video]);

  const killWord = useMemo(() => {
    if (video.kills === 1) {
      return "1 kill";
    } else if (video.kills === 42) {
      return "∞";
    }
    return `${video.kills} kills`;
  }, [video]);

  const heightPlayer = useMemo(() => {
    let height: any;
    if (video.height >= video.width) {
      height = 'auto';
    } else if (video.height < video.width) {
      height = '100%';
    }
    return height;
  }, [video]);

  const widthPlayer = useMemo(() => {
    let width: any;
    if (video.height > video.width) {
      width = '30%';
    } else if (video.height < video.width) {
      width = '98%';
    }
    return width;
  }, [video]);

  const footerStyle = useMemo(() => {
    return {
      height: '10%',
    } as any;
  }, []);

  const getGamePaddingTop = useCallback(() => {
    let paddingTop = '0.25rem';
    return paddingTop;
  }, []);

  const gameBoxSx = useMemo(() => ({
    position: 'absolute',
    paddingTop: getGamePaddingTop(),
  }), [getGamePaddingTop]);

  const redirectToAndroidStore = useCallback(
    () => onUserRedirectToStore({ option: 'android', os: user.os }),
    [onUserRedirectToStore, user.os],
  );

  if (video && isEmpty(video)) {
    return null;
  }

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={styles.fullHeight}
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ReactPlayer
          ref={vidRef}
          playsinline
          controls
          playing={playing}
          onReady={startVideo}
          muted={muted}
          onEnded={onEnded}
          url={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.path}`}
          type="video/mp4"
          height={heightPlayer}
          width={widthPlayer}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        display="flex"
        sx={footerStyle}
      >
        <Grid
          container
        >
          <Grid
            item
            sm={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              alt="profile"
              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.user.avatar} `}
              sx={styles.avatarProfile}
            />
          </Grid>
          <Grid
            item
            sm={3}
            display="flex"
          >
            <Grid
              container
              flexDirection="row"
            >
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={12}
              >
                <Typography
                  variant="body2"
                  align="center"
                  letterSpacing={1}
                  sx={styles.usernameTypo}
                >
                  {video.user.username}
                </Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
              >
                <Typography
                  variant="body2"
                  align="center"
                  sx={styles.description}
                >
                  {video.description}
                </Typography>
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                xs={12}
                mt={0}
              >
                <Grid container>
                  <Grid
                    item
                    xs={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FontAwesomeIcon
                      icon={getPlatformIcon() as IconProp}
                      style={styles.platformIcon}
                      color="white"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    display="flex"
                    ml={'0.55rem'}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography
                      color="black"
                      fontFamily="Russo One"
                      fontWeight="bold"
                      fontSize={"0.9rem"}
                      sx={styles.modeTypo}
                    >
                      {video.mode}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="white"
                      fontFamily="Russo One"
                      fontWeight="bold"
                      fontSize={"0.9rem"}
                      sx={styles.killTypo}
                    >
                      {killWord}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={7.85}
            display="flex"
          >
            <Grid
              container
              display="flex"
              justifyContent="flex-end"
            >
              <Grid
                item
                sm={1.5}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pr={0}
              >
                <Box
                  component="img"
                  alt=""
                  height={'3.9rem'}
                  src={
                    `${process.env.REACT_APP_AWS_CLOUDFRONT_URL!}weapons/v2/${video.weapon}.webp`.replace(/\s/g, '%20')
                  }
                  sx={styles.weaponBox}
                />
              </Grid>
              <Grid
                item
                sm={1}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  alt=""
                  height={'4.5rem'}
                  src={
                    `${process.env.REACT_APP_AWS_CLOUDFRONT_URL!}games/${video.gameName}.webp`.replace(/\s/g, '%20')
                  }
                  sx={gameBoxSx}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={styles.footer}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="white"
              fontWeight="bold"
              fontFamily="Anton"
              sx={styles.spacing}
            >
              {t('joinUs')}
            </Typography>
          </Grid>
          <Grid
            item
            sm={1.8}
            mt={0.5}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              href="https://play.google.com/store/apps/details?id=com.soskills"
              target="_blank"
              color="inherit"
              sx={styles.hover}
              onClick={redirectToAndroidStore}
            >
              <Box
                component="img"
                className={"android-button"}
                sx={styles.border}
                alt=""
                src={
                  user.language === 'en'
                    ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                    : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                }
              />
            </Link>
          </Grid>
          <Grid
            item
            sm={1.8}
            mt={0.5}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              href="https://apps.apple.com/fr/app/soskills/id1635127812"
              target="_blank"
              color="inherit"
              sx={styles.hover}
              onClick={() => onUserRedirectToStore({ option: 'ios', os: user.os })}
            >
              <Box
                component="img"
                className={user.language === 'en' ? "apple-en-button" : "apple-fr-button"}
                sx={styles.border}
                alt=""
                src={
                  user.language === 'en'
                    ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/en/badge-white.png`
                    : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/fr/badge-white.png`
                }
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(PostDesktop);
