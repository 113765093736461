const styles = {
  modal: {
    overflow: 'auto',
    display: 'block',
  },
  box: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: 500,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    borderRadius: 2,
    border: '2px solid black',
    boxShadow: 24,
    pt: 2,
    pb: 2,
    px: 4,
  },
  sendButton: {
    width: '50%',
    borderRadius: 1,
    textTransform: 'none',
  },
};

export default styles;
