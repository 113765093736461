import { SnackbarOrigin } from "@mui/material";

const styles = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
  alert: {
    width: '100%',
  },
  backdropSx: {
    color: '#fff',
    zIndex: (theme: any) => theme.zIndex.drawer + 1,
  },
  cancelButton: {
    width: '70%',
    borderRadius: 1,
    textTransform: 'none',
  },
  closeRow: {
    position: 'absolute',
    right: '0%',
    top: '0%',
  },
  closeIcon: {
    color: 'black',
  },
  container: {
    height: '85%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  descriptionSx: {
    color: 'black',
  },
  grab: {
    cursor: 'grab',
  },
  loadingButtonSx: {
    width: '95%',
    borderRadius: 1,
  },
  modal: {
    overflow: 'auto',
    height: '100%',
    display: 'block',
  },
  modeRow: {
    backgroundColor: 'black',
    borderWidth: 1,
    borderRadius: 4,
    cursor: 'grab',
  },
  nextButton: {
    width: '70%',
    borderRadius: 1,
    textTransform: 'none',
  },
  videoStyle: {
    height: 190,
    borderRadius: 5,
  },
};

export default styles;
