export const COMMENT = `
  query comment($id: ID!) {
    comment(id: $id) {
      id
      message
      user {
        id
        username
        email
      }
    }
  }
`;
