const styles = {
  avatarProfile: {
    width: '4.25rem',
    height: '4.25rem',
  },
  border: {
    borderRadius: 3,
  },
  description: {
    fontFamily: 'Inter',
    fontWeight: 'medium',
    fontSize: '0.9rem',
    color: 'white',
  },
  footer: {
    height: '13.5%',
  },
  fullHeight: {
    height: '100%',
  },
  hover: {
    '&:hover': {
      opacity: 0.7,
    },
  },
  killTypo: {
    marginLeft: '0.5rem',
    height: '1.2rem',
    backgroundColor: 'red',
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    borderColor: 'black',
    borderWidth: 1,
    borderRadius: 1,
  },
  modeTypo: {
    height: '1.2rem',
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 1,
  },
  platformIcon: {
    fontSize: '1.4rem',
  },
  spacing: {
    letterSpacing: '1.5px',
  },
  usernameTypo: {
    height: '1.5rem',
    fontFamily: 'Russo One',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: 'white',
  },
  weaponBox: {
    position: 'absolute',
    paddingTop: '0.25rem'
  },
};

export default styles;
