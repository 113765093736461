import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'graphql-hooks';
import {
  faVolumeHigh,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

import {
  USER_REDIRECT_TO_STORE,
} from '../../graphql/user/mutation';
import { User } from '../../types';
import styles from './style';

const Home: React.FC<{
  redirect?: boolean;
  isMobile: boolean;
  innerHeight: number;
  user: User;
}> = (props) => {
  const {
    redirect,
    innerHeight,
    isMobile,
    user,
  } = props;
  const { t } = useTranslation();
  const vidRef = React.useRef(null) as any;
  const gridRef = React.useRef(null) as any;
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [url] = useState(
    user.language === 'fr'
      ? isMobile
        ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/videos/HOME_FR_VALORANT_DARK.mp4`
        : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/videos/HOME_FR_VALORANT_DARK_WEBSITE.mp4`
      : isMobile
        ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/videos/HOME_EN_VALORANT_DARK.mp4`
        : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/videos/HOME_EN_VALORANT_DARK_WEBSITE.mp4`
  );
  const [userRedirectToStore] = useMutation(USER_REDIRECT_TO_STORE);
  const [isReady, setIsReady] = useState(false);

  const joinUsTrad = t('joinUs');
  const joinSoSkillsTrad = t('joinSoSkills');
  const joinSoSkillsPartTwoTrad = t('joinSoSkillsPartTwo');

  const getAndroidBadgeClassName = useCallback(() => {
    let str;

    if (user.language === 'fr') {
      if (user.os === 'ios') {
        if (innerHeight < 660) {
          str = 'android-fr-button-mobile-little-x2-for-ios';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'android-fr-button-mobile-little-for-ios';
        } else if (innerHeight >= 735) {
          str = 'android-fr-button-mobile-for-ios';
        }
      } else {
        if (innerHeight < 660) {
          str = 'android-fr-button-mobile-little-x2-for-android';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'android-fr-button-mobile-little-for-android';
        } else {
          str = 'android-fr-button-mobile-for-android';
        }
      }
    } else {
      if (user.os === 'ios') {
        if (innerHeight < 660) {
          str = 'android-en-button-mobile-little-x2-for-ios';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'android-en-button-mobile-little-for-ios';
        } else if (innerHeight >= 735) {
          str = 'android-en-button-mobile-for-ios';
        }
      } else {
        if (innerHeight < 660) {
          str = 'android-en-button-mobile-little-x2-for-android';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'android-en-button-mobile-little-for-android';
        } else {
          str = 'android-en-button-mobile-for-android';
        }
      }
    }
    return str;
  }, [user.language, user.os, innerHeight])

  const getAppleBadgeClassName = useCallback(() => {
    let str;

    if (user.language === 'fr') {
      if (user.os === 'ios') {
        if (innerHeight < 660) {
          str = 'apple-fr-button-mobile-little-x2-for-ios';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'apple-fr-button-mobile-little-for-ios';
        } else if (innerHeight >= 735) {
          str = 'apple-fr-button-mobile-for-ios';
        }
      } else {
        if (innerHeight < 660) {
          str = 'apple-fr-button-mobile-little-x2-for-android';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'apple-fr-button-mobile-little-for-android';
        } else {
          str = 'apple-fr-button-mobile-for-android';
        }
      }
    } else {
      if (user.os === 'ios') {
        if (innerHeight < 660) {
          str = 'apple-en-button-mobile-little-x2-for-ios';
        } else if (innerHeight >= 660 && innerHeight <= 734) {
          str = 'apple-en-button-mobile-little-for-ios';
        } else if (innerHeight >= 735) {
          str = 'apple-en-button-mobile-for-ios';
        }
      } else {
        if (innerHeight < 660) {
          str = 'apple-en-button-mobile-little-x2-for-android';
        } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'android') {
          str = 'apple-en-button-mobile-little-for-android';
        } else {
          str = 'apple-en-button-mobile-for-android';
        }
      }
    }
    return str;
  }, [user.language, user.os, innerHeight]);

  const getHomeMobileWidth = useCallback(() => {
    let h = '21.5rem';
    if (innerHeight < 660 && user.os === 'ios') {
      h = '21.25rem';
    } else if (innerHeight < 660 && user.os === 'android') {
      h = '17.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'ios') {
      h = '26.5rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'android') {
      h = '27rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      h = '29rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      h = '29rem';
    }
    return h;
  }, [innerHeight, user.os]);

  const getLeftHeightSide = useCallback(() => {
    let h = '29.5rem';
    if (innerHeight < 660 && user.os === 'ios') {
      h = '20rem';
    } else if (innerHeight < 660 && user.os === 'android') {
      h = '15rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'ios') {
      h = '24.35rem';
    } else if (innerHeight >= 660 && innerHeight <= 734 && user.os === 'android') {
      h = '25.25rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      h = '28.5rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      h = '27.5rem';
    }
    return h;
  }, [innerHeight, user.os]);

  const getMobileTitleSize = useCallback(() => {
    let size = '1.9rem';
    if (innerHeight < 660 && user.os === 'android') {
      size = '1.4rem';
    } else if (innerHeight < 660 && user.os === 'ios') {
      size = '1.45rem';
    } else if (innerHeight >= 660 && innerHeight <= 734) {
      size = '1.9rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      size = '2rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      size = '2rem';
    }
    return size;
  }, [innerHeight, user.os]);

  const getTitleMarginTop = useCallback(() => {
    let size = '0.5rem';
    if (innerHeight < 660 && user.os === 'android') {
      size = '1.5rem';
    } else if (innerHeight < 660 && user.os === 'ios') {
      size = '0.8rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'ios'
    ) {
      size = '1.8rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'android'
    ) {
      size = '1.05rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      size = '1.25rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      size = '0.9rem';
    }
    return size;
  }, [innerHeight, user.os]);

  const getMartinTopPlayer = useCallback(() => {
    let size = '1rem';
    if (innerHeight < 660 && user.os === 'android') {
      size = '0.6rem';
    } else if (innerHeight < 660 && user.os === 'ios') {
      size = '0.6rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'ios'
    ) {
      size = '2rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'android'
    ) {
      size = '0.5rem';
    } else if (innerHeight >= 735 && user.os === 'ios') {
      size = '1.25rem';
    } else if (innerHeight >= 735 && user.os === 'android') {
      size = '0.5rem';
    }
    return size;
  }, [user.os, innerHeight]);

  const getMobileHeightTitle = useCallback(() => {
    let h = '3.35rem';

    if (innerHeight < 660 && user.os === 'android') {
      h = '2.25rem';
    } else if (innerHeight < 660 && user.os === 'ios') {
      h = '2.45rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'ios'
    ) {
      h = '3.15rem';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && user.os === 'android'
    ) {
      h = '3rem';
    } else if (innerHeight >= 735) {
      h = '3.15rem';
    }
    return h;
  }, [innerHeight, user.os]);

  const onEnded = useCallback(() => {
    vidRef.current!.seekTo(0);
  }, [vidRef]);

  const onReady = useCallback(() => {
    setIsReady(true);
    if (!playing) {
      //onUserPlayHomeVideo();
      setMuted(true);
      setPlaying(true);
    }
    //gridRef.current.click();
  }, [playing]);

  const onUserRedirectToStore = useCallback((input: {
    os: string;
    option: string;
  }) => userRedirectToStore({
    variables: {
      input,
    },
  }), [userRedirectToStore]);

  const changeMutedOption = useCallback(() => {
    setMuted(!muted);
  }, [muted]);

  useEffect(() => {
    if (redirect && isMobile) {
      let fallLink = '';
      if (user.os === 'ios') {
        fallLink = 'itms-apps://apps.apple.com/id/app/soskills/id1635127812';
      } else {
        fallLink = 'https://play.google.com/store/apps/details?id=com.soskills';
      }
      document.location = fallLink;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  const itemRow = useMemo(() => ({
    height:
      isMobile
        ? getLeftHeightSide()
        : '40rem',
  }), [
    isMobile,
    getLeftHeightSide,
  ]);

  const reactPlayerRow = useMemo(() => ({
    marginTop: isMobile ? getMartinTopPlayer() : 0,
    height: isMobile ? getHomeMobileWidth() : '40rem',
    marginRight: isMobile ? 0 : '4rem',
  }), [isMobile, getMartinTopPlayer, getHomeMobileWidth]);

  const reactPlayerHeight = useMemo(() => isMobile ? getHomeMobileWidth() : '40rem', [isMobile, getHomeMobileWidth]);
  const reactPlayerStyle = useMemo(() => ({
    marginRight: isMobile ? 0 : '4rem',
  }), [isMobile]);

  const overlayImg = useMemo(() => ({
    position: 'absolute',
    height: isMobile ? getHomeMobileWidth() : '40rem',
    marginTop: isMobile ? getMartinTopPlayer() : 0,
    marginRight: isMobile ? 0 : '4rem',
  }), [isMobile, getHomeMobileWidth, getMartinTopPlayer]);

  const onUserRedirectToAppleStoreFc = useCallback(
    () => onUserRedirectToStore({ option: 'ios', os: user.os }),
    [user.os, onUserRedirectToStore],
  );
  const onUserRedirectToAndroidStoreFc = useCallback(
    () => onUserRedirectToStore({ option: 'android', os: user.os }),
    [onUserRedirectToStore, user.os],
  );

  const joinUsTypo = useMemo(() => ({
    fontFamily: 'Gasoek One',
    fontSize: getMobileTitleSize(),
    color: 'white',
  }), [getMobileTitleSize]);
  const joinUsGrid = useMemo(() => ({
    marginTop: getTitleMarginTop(),
    height: getMobileHeightTitle(),
  }), [getTitleMarginTop, getMobileHeightTitle]);

  const loop = useMemo(() => user.os === 'ios' && isMobile, [isMobile, user.os]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={styles.mainContainer}
    >
      <Grid
        container
        flexDirection="row"
        display="flex"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          sx={itemRow}
          alignItems="center"
          justifyContent="center"
        >
          {
            isMobile && playing && (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                sx={styles.soundGrid}
              >
                {muted ? (
                  <FontAwesomeIcon
                    color="white"
                    onClick={changeMutedOption}
                    icon={faVolumeMute}
                    size="lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    color="white"
                    onClick={changeMutedOption}
                    icon={faVolumeHigh}
                    size="lg"
                  />
                )}
              </Grid>
            )
          }
          <Grid
            style={reactPlayerRow}
            ref={gridRef}
          >
            <ReactPlayer
              ref={vidRef}
              playsinline
              loop={loop}
              playing={playing}
              onReady={onReady}
              muted={muted}
              onEnded={(user.os === 'android' || !isMobile) ? onEnded : () => { }}
              url={url}
              type="video/mp4"
              height={reactPlayerHeight}
              style={reactPlayerStyle}
            />
          </Grid>
          {
            !playing && !isReady && (
              <img
                alt=''
                src={
                  user.language === 'fr'
                    ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/images/HOME_FR_VALORANT_DARK.png`
                    : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/images/HOME_EN_VALORANT_DARK.png`
                }
                style={overlayImg as any}
              />
            )
          }
        </Grid>

        {
          !isMobile && (
            <Grid
              item
              xs={12}
              sm={5.4}
              display="flex"
              alignItems="center"
              sx={styles.joinRow}
            >
              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography
                    variant="h4"
                    color="white"
                    fontWeight="bold"
                    fontFamily="Anton"
                    sx={styles.spacing}
                  >
                    {joinUsTrad}
                  </Typography>
                </Grid>
                <Grid mt={2} item xs={12} sm={10.7} display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="body1"
                    color="white"
                    textAlign={'justify'}
                  >
                    {joinSoSkillsTrad}
                  </Typography>
                </Grid>
                <Grid mb={1} mt={2} item xs={12} sm={10.7} display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="body1"
                    color="white"
                    textAlign={'justify'}
                  >
                    {joinSoSkillsPartTwoTrad}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={2}
                  sm={4.25}
                  display="flex"
                  justifyContent="center"
                >
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.soskills"
                    target="_blank"
                    color="inherit"
                    sx={styles.linkOpacity}
                    onClick={onUserRedirectToAppleStoreFc}
                  >
                    <Box
                      component="img"
                      className={"android-button"}
                      alt=""
                      src={
                        user.language === 'en'
                          ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                          : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                      }
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  mt={2}
                  xs={6}
                  sm={4.25}
                  display="flex"
                  justifyContent="center"
                >
                  <Link
                    href="https://apps.apple.com/fr/app/soskills/id1635127812"
                    target="_blank"
                    color="inherit"
                    sx={styles.linkOpacity}
                    onClick={onUserRedirectToAppleStoreFc}
                  >
                    <Box
                      component="img"
                      className={user.language === 'en' ? "apple-en-button" : "apple-fr-button"}
                      alt=""
                      src={
                        user.language === 'en'
                          ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/en/badge-white.png`
                          : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/fr/badge-white.png`
                      }
                    />
                  </Link>
                </Grid>

              </Grid>
            </Grid>
          )
        }

        {
          isMobile && (
            <Grid
              item
              xs={11}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              sx={joinUsGrid}
            >
              <Typography
                align="center"
                sx={joinUsTypo}>
                {joinUsTrad}
              </Typography>
            </Grid>
          )
        }

        {
          isMobile && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={styles.badgeSx}
            >
              <Grid
                item
                display="flex"
                xs={6}
                justifyContent="flex-end"
                paddingRight={'1.5vw'}
              >
                <Link
                  href="https://play.google.com/store/apps/details?id=com.soskills"
                  target="_blank"
                  color="inherit"
                  sx={styles.linkOpacity}
                  onClick={onUserRedirectToAndroidStoreFc}
                >
                  <Box
                    component="img"
                    className={getAndroidBadgeClassName()}
                    sx={styles.borderBox}
                    alt=""
                    src={
                      user.language === 'en'
                        ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                        : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/android/en/badge-white.png`
                    }
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-start"
                paddingLeft={'1.5vw'}
              >
                <Link
                  href="https://apps.apple.com/fr/app/soskills/id1635127812"
                  target="_blank"
                  color="inherit"
                  sx={styles.linkOpacity}
                  onClick={onUserRedirectToAppleStoreFc}
                >
                  <Box
                    component="img"
                    className={getAppleBadgeClassName()}
                    sx={styles.borderBox}
                    alt=""
                    src={
                      user.language === 'en'
                        ? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/en/badge-white.png`
                        : `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}website/badges/apple/fr/badge-white.png`
                    }
                  />
                </Link>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
};

export default React.memo(Home);
