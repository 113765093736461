import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useManualQuery } from 'graphql-hooks';
import { useAtom } from 'jotai';
import SwipeableViews from 'react-swipeable-views';

import { VIDEOS_TOP_PAGINATION } from '../../graphql/video/query';
import { User, Video } from '../../types';
import { currentIndexAtom } from '../../atoms';
import Post from '../../components/Post/Post';
import styles from './style';

interface VideoPagination {
  videos: Video[];
  posts: JSX.Element[];
}

const HomeSwipe: React.FC<{
  isMobile: boolean,
  innerHeight: number;
  user: User;
}> = React.memo((props) => {
  const {
    innerHeight,
    user,
  } = props;
  const [videosTopPagination] = useManualQuery(
    VIDEOS_TOP_PAGINATION,
    { skipCache: true },
  );
  const [, setCurrentIndex] = useAtom(currentIndexAtom);
  const [videosPagination, setVideosPagination] = useState({
    videos: [],
    posts: [],
  } as VideoPagination);

  const getVideos = useCallback(async () => {
    const getVideoIds = (vs: Video[]): string[] => {
      if (vs && vs.length > 0) {
        return vs.map((v) => v.id);
      }
      return [];
    };

    const { data } = await videosTopPagination({
      variables: {
        input: {
          videoIds: getVideoIds(videosPagination.videos),
          limit: 2,
        },
      },
    }) as any;

    if (
      data
      && data.videosTopPagination
      && data.videosTopPagination.docs
      && data.videosTopPagination.docs.length > 0
    ) {
      const vs = [...videosPagination.videos, ...data.videosTopPagination.docs] as Video[];
      vs.forEach((v: Video, i: number) => {
        if (!videosPagination.posts[i]) {
          videosPagination.posts.push(
            <Post
              key={`video-${Math.round(Math.random() * 9000)}-${v.id}`}
              index={i}
              innerHeight={innerHeight}
              user={user}
              video={v}
            />
          );
        }
      });
      setVideosPagination({
        videos: vs,
        posts: videosPagination.posts,
      });
      if (vs && vs.length === 1) {
        getVideos();
      }
    };
  }, [
    videosTopPagination,
    videosPagination,
    user,
    innerHeight,
  ]);

  useEffect(() => {
    getVideos();
  }, []);

  const onChangeIndex = useCallback(async (index: number, indexLatest: number) => {
    setCurrentIndex(index);
    if (index === videosPagination.videos.length - 1) {
      await getVideos();
    }
  }, [
    videosPagination,
    getVideos,
    setCurrentIndex,
  ]);

  if (
    videosPagination &&
    !videosPagination.videos.length &&
    !videosPagination.posts.length
  ) {
    return null;
  }

  return (
    <SwipeableViews
      onChangeIndex={onChangeIndex as any}
      axis="y"
      resistance
      ignoreNativeScroll
      containerStyle={styles.fullHeight}
      slideStyle={styles.fullHeight}
      style={styles.fullHeight}
    >
      {videosPagination.posts}
    </SwipeableViews>
  );
});

export default HomeSwipe;
