const styles = {
  cursor: {
    cursor: 'pointer'
  },
  inputBase: {
    ml: 1,
    flex: 1,
  },
  paper: {
    p: '2px 4px',
    display: 'flex',
    width: 400,
  },
  searchIconButton: {
    p: '10px'
  },
};

export default styles;
