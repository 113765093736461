import React from 'react';
import {
  Box,
  Tab,
  Typography,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';

import { ProfileForm } from '../../../components/ProfileForm';
import { User } from '../../../types';
import Videos from '../../Videos/Videos';
import styles from './style';

interface ProfileTabProps {
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  onChangeVideoInput: (event: any) => Promise<void>;
  t: any;
  user: User;
  value: string;
  valueInputAddVideo: string;
}

const ProfileTab = React.memo((props: ProfileTabProps) => {
  const {
    handleChange,
    onChangeVideoInput,
    t,
    user,
    value,
    valueInputAddVideo,
  } = props;
  const myVideosTrad = t('myVideos');
  const myProfileTrad = t('myProfile');

  return (
    <Box
      sx={styles.mainBox}
      mt={2}
    >
      <TabContext value={value} >
        <Box sx={styles.box}>
          <TabList
            onChange={handleChange}
            sx={styles.tabList}
          >
            <Tab
              label={
                <Typography fontWeight="gold" color="black">
                  {myVideosTrad}
                </Typography>
              }
              value="1"
            />
            <Tab
              style={styles.blackTab}
              label={
                <Typography fontWeight="gold" color="black">
                  {myProfileTrad}
                </Typography>
              }
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Videos
            user={user}
            onChangeVideoInput={onChangeVideoInput}
            valueInputAddVideo={valueInputAddVideo}
          />
        </TabPanel>
        <TabPanel value="2">
          <ProfileForm user={user} />
        </TabPanel>
      </TabContext>
    </Box>
  );
})

export default ProfileTab;
