export const STAT_BY_USER_ID = `
  query statByUserId($userId: ID!) {
    statByUserId(userId: $userId) {
      id
      userId
      publications
      likes
      follows
      followers
    }
  }
`;
