const styles = {
  iconButton: {
    color: 'white',
  },
  signUpButton: {
    textTransform: 'none',
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.8,
    },
  },
  signInButton: {
    marginLeft: 1,
    textTransform: 'none',
  },
};

export default styles;
