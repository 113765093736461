import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { isMobileAtom } from '../../atoms';
import styles from './style';

const Contact = React.memo(() => {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);

  const contactTitleTrad = t('contactTitle');
  const contactDescriptionTrad = t('contactDescription');
  const contactEmailTrad = t('contactEmail');
  const contactEmail2Trad = t('contactEmail2');

  const onClick = useCallback(() => window.location.href = 'mailto:contact@soskills.io', []);

  return (
    <Grid
      container
      alignItems="center"
      sx={styles.container}
    >
      <Container
        maxWidth="sm"
        disableGutters={isMobile}
      >
        <Paper elevation={3} sx={styles.paper}>
          <Box mx={2} py={2}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                  {contactTitleTrad}
                </Typography>
              </Grid>
              <Grid item mt={4} sm={12}>
                <Typography variant="body2" fontWeight="bold" textAlign={'justify'}>
                  {contactDescriptionTrad}
                </Typography>
              </Grid>
              <Grid item mt={1} sm={12}>
                <Typography variant="body2" fontWeight="medium" textAlign={'justify'}>
                  {contactEmailTrad}
                  &nbsp; <a href="mailto:contact@soskills.io">contact@soskills.io</a> &nbsp;
                  {contactEmail2Trad}
                </Typography>
              </Grid>
              {/*<Grid item mt={2} sm={12}>
                <Typography variant="body2" fontWeight="bold" textAlign={'justify'}>
                  {t('supportDescription')}
                </Typography>
              </Grid>*/}
              {/*<Grid item mt={1} sm={12}>
                <Typography variant="body2" fontWeight="medium" textAlign={'justify'}>
                  {t('supportEmail')}
                  &nbsp; <a href="mailto:support@soskills.io">support@soskills.io</a> &nbsp;
                  {t('supportEmail2')}
                </Typography>
              </Grid>*/}
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                mt={2}
              >
                {/*<Grid item xs={6} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    onClick={() => window.location.href = "mailto:support@soskills.io"}
                    color="error"
                    //fullWidth
                    sx={{
                      width: '95%',
                      borderRadius: 1,
                      textTransform: 'none',
                    }}
                  >
                    <Typography fontWeight="bold" variant="body2" >
                      {'support@soskills.io'}
                    </Typography>
                  </Button>
                </Grid>*/}
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    onClick={onClick}
                    color="primary"
                    //fullWidth
                    sx={styles.contactButton}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      {'contact@soskills.io'}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Grid>
  );
});

export default Contact;
