import React, { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'graphql-hooks';
import { useTranslation } from "react-i18next";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import {
  NOTIFICATION_TO_COMMUNITY,
  NOTIFICATION_TO_USER,
} from '../../graphql/notification/mutation';
import { CustomInput } from '../CustomInput';
import { isEmpty } from 'lodash';
import styles from './style';

interface NotificationForm {
  message: string;
}

interface NotificationToCommunityProps {
  userId?: string;
  username?: string;
}

const NotificationToCommunity: React.FC<NotificationToCommunityProps> = React.memo((props: NotificationToCommunityProps) => {
  const { userId, username } = props;
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<NotificationForm>();
  const [notificationToCommunity] = useMutation(NOTIFICATION_TO_COMMUNITY);
  const [notificationToUser] = useMutation(NOTIFICATION_TO_USER);
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const [successMessage, setSuccessMessage] = useState({ active: false, msg: '' });

  const uploadFormSendTrad = t('uploadForm.send');
  const formErrorRequiredMessageTrad = t('form.error.required.message');

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onRegisterPressed = async (obj: NotificationForm) => {
    try {
      if (!isEmpty(obj) && obj.message && !userId) {
        const { data } = await notificationToCommunity({
          variables: {
            message: obj.message,
          }
        })
        if (data && data.notificationToCommunity) {
          setSuccessMessage({ active: true, msg: t('messageSent') });
        }
      } else if (!isEmpty(obj) && obj.message && userId) {
        const { data } = await notificationToUser({
          variables: {
            input: {
              message: obj.message,
              userId,
            }
          }
        })
        if (data && data.notificationToUser) {
          setSuccessMessage({ active: true, msg: t('messageSent') });
        }
      }
    } catch (e: any) {
      setErrorMessage({ active: true, msg: e.message });
    }
  };

  const onCloseErrorSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const onCloseSuccessSnack = useCallback(() => {
    setSuccessMessage({ active: false, msg: '' });
  }, []);

  const messageRules = useMemo(() => ({
    required: formErrorRequiredMessageTrad,
  }), [formErrorRequiredMessageTrad]);

  return (
    <Grid
      container
      direction="row"
    >
      <Grid container spacing={1}>
        {username && userId && (
          <Grid
            item
            xs={12}
            display="flex"
            py={1}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontWeight="bold">
              Message to {username}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomInput
            name="message"
            fullWidth
            label={'message'}
            control={control}
            placeholder={'message'}
            rules={messageRules}
          />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmit(onRegisterPressed)}
            sx={styles.uploadFormButton}
          >
            <Typography fontWeight="bold">
              {uploadFormSendTrad}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={errorMessage.active}
        autoHideDuration={3000}
        message={errorMessage.msg}
        onClose={onCloseErrorSnack}
      >
        <Alert severity="error" sx={styles.alert}>
          {errorMessage.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={styles.anchorOrigin}
        open={successMessage.active}
        autoHideDuration={3000}
        message={successMessage.msg}
        onClose={onCloseSuccessSnack}
      >
        <Alert severity="success" sx={styles.alert}>
          {successMessage.msg}
        </Alert>
      </Snackbar>
    </Grid >
  );
});

export default NotificationToCommunity;
