const styles = {
  description: {
    fontFamily: 'Inter',
    fontWeight: 'medium',
    fontSize: '0.7rem',
    color: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: '0.60rem',
    left: 0,
    right: 0,
    zIndex: 10
  },
  tiktokFooter: {
    position: 'absolute',
    bottom: '1.4rem',
    left: 0,
    right: 0,
    zIndex: 10
  },
  usernameTypo: {
    height: '1.5rem',
    fontFamily: 'Russo One',
    fontWeight: 'bold',
    fontSize: '0.95rem',
    letterSpacing: '0.075rem',
    color: 'white',
  },
  weaponBox: {
    position: 'absolute',
    paddingTop: '0.3rem'
  },
};

export default styles;
