import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Snackbar,
} from '@mui/material';
import { useNavigate, Link } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login';
import isEmpty from 'lodash/isEmpty';

import { Context, useAuth } from '../../context/auth';
import { CustomInput } from '../../components/CustomInput';
import { isMobileAtom } from '../../atoms';
import styles from './style';

interface SignInData {
  email: string;
  password: string;
}

interface SignUpArgs {
  email?: string;
  idToken?: string;
  password?: string;
  username?: string;
  dateOfBirth?: Date;
  provider?: string;
  userAppleId?: string;
}

const Login = () => {
  const {
    signIn,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
  } = useAuth() as Context;
  const {
    control,
    handleSubmit,
  } = useForm<SignInData>();
  const [isMobile] = useAtom(isMobileAtom);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({ active: false, msg: '' });
  const { t } = useTranslation();
  const { hostname, protocol, port } = window.location;
  const redirect_uri = protocol + "//" + hostname + (port ? ":" + port : "");
  const forgotPasswordLinkTrad = t('form.forgotPasswordLink');
  const connexionTrad = t('form.signIn');
  const errorSignInContentTrad = t('form.error.invalidCredentials');
  const emailTrad = t('email');
  const passwordTrad = t('Password');
  const formPasswordTrad = t('form.password');
  const formErrorRequiredPasswordTrad = t('form.error.required.password');
  const formErrorMinLengthPasswordTrad = t('form.error.minLength.password');
  const formErrorRequiredEmailTrad = t('form.error.required.email');
  const formErrorPatternEmail = t('form.error.pattern.email');
  const googleSignInTrad = t('googleSignIn');
  const formSignUpTrad = t('form.signUp');
  const formSignUpQuestionTrad = t('form.signUpQuestion');
  const appleSignInTrad = t('appleSignIn');
  const facebookSignInTrad = t('facebookSignIn');

  const loginGoogle = useGoogleLogin(
    {
      onSuccess: async (tokenResponse: any) => {
        const res = await signInWithGoogle(tokenResponse) as unknown as SignUpArgs;
        if (res && !isEmpty(res) && res.provider === 'Google') {
          navigate('/signup', { state: res, replace: true });
        }
      },
      flow: 'auth-code',
    }
  );

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onSubmit = useCallback(async (obj: SignInData) => {
    try {
      await signIn(obj.email, obj.password);
    } catch (e: any) {
      setErrorMessage({ active: true, msg: errorSignInContentTrad });
    }
  }, [errorSignInContentTrad, signIn]);

  const onCloseSnack = useCallback(() => {
    setErrorMessage({ active: false, msg: '' });
  }, []);

  const loginApple = useCallback(async (response: any) => {
    const res = await signInWithApple(response) as unknown as SignUpArgs;
    if (res && !isEmpty(res) && res.provider === 'Apple') {
      navigate('/signup', { state: res, replace: true });
    }
  }, [signInWithApple, navigate]);

  const loginOnEnter = useCallback(async () => {
    handleSubmit((data) => {
      if (data.email && data.password) {
        onSubmit(data);
      }
    })() as any;
  }, [handleSubmit, onSubmit]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        loginOnEnter();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit, loginOnEnter]);

  const emailRules = useMemo(() => ({
    required: formErrorRequiredEmailTrad,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: formErrorPatternEmail,
    },
  }), [formErrorPatternEmail, formErrorRequiredEmailTrad])

  const passwordRules = useMemo(() => ({
    required: formErrorRequiredPasswordTrad,
    minLength: {
      value: 8,
      message: formErrorMinLengthPasswordTrad,
    },
  }), [formErrorRequiredPasswordTrad, formErrorMinLengthPasswordTrad]);

  const facebookCallback = useCallback(async (response: any) => {
    const res = await signInWithFacebook(response) as unknown as SignUpArgs;
    if (res && !isEmpty(res) && res.provider === 'Facebook') {
      navigate('/signup', { state: res, replace: true });
    }
  }, [navigate, signInWithFacebook]);

  const facebookRender = useCallback((renderProps: any) => (
    <Button
      startIcon={
        <img
          alt=''
          src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}providers/facebook.webp`}
          width={20}
          height={20}
        />
      }
      variant="contained"
      onClick={renderProps.onClick}
      color="primary"
      fullWidth
      sx={styles.facebookButton}
    >
      <Typography fontWeight="bold">
        {facebookSignInTrad}
      </Typography>
    </Button>
  ), [facebookSignInTrad]);

  const appleRender = useCallback((renderProps: any) => (
    <Button
      startIcon={
        <img
          alt=''
          src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}providers/apple.webp`}
          width={20}
          height={20}
        />
      }
      variant="contained"
      onClick={renderProps.onClick}
      color="primary"
      fullWidth
      sx={styles.appleButton}
    >
      <Typography fontWeight="bold">
        {appleSignInTrad}
      </Typography>
    </Button>
  ), [appleSignInTrad]);

  const signupTypo = useMemo(() => ({
    marginLeft: isMobile ? '1vw' : '0.5vw',
  }), [isMobile]);

  const googleStartIcon = useMemo(() => (<img
    alt=''
    src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}providers/google.webp`}
    width={20}
    height={20}
  />), []);

  return (
    <Grid
      container
      alignItems="center"
      sx={styles.container}
    >
      <Container
        maxWidth="sm"
        disableGutters={isMobile}
      >
        <Paper
          elevation={3}
          sx={styles.paper}
        >
          <Box mx={2} my={2}>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <Typography variant="h4" align="center" fontFamily="Anton" color="#212121">
                  {connexionTrad}
                </Typography>
              </Grid>
              <Grid item mt={2} xs={12}>
                <CustomInput
                  name="email"
                  placeholder={emailTrad}
                  control={control}
                  label={'Email'}
                  rules={emailRules}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <CustomInput
                  name="password"
                  label={passwordTrad}
                  placeholder={formPasswordTrad}
                  control={control}
                  rules={passwordRules}
                  fullWidth
                  variant="outlined"
                  type="password"
                />
              </Grid>
              <Grid
                item
                mt={1}
                sm={12}
              >
                <Typography variant="body2" fontWeight="bold">
                  <Link to="/forgotpassword" style={styles.linkPassword}>
                    {forgotPasswordLinkTrad}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={1.5}
              >
                <Button
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  fullWidth
                  sx={styles.buttonConnexion}
                >
                  <Typography fontWeight="bold">
                    {connexionTrad}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                mt={1}
                xs={12}
              >
                <Button
                  startIcon={googleStartIcon}
                  variant="contained"
                  onClick={() => loginGoogle()}
                  color="primary"
                  fullWidth
                  sx={styles.googleButton}
                >
                  <Typography fontWeight="bold">
                    {googleSignInTrad}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                mt={1}
                xs={12}
              >
                <FacebookLogin
                  autoLoad={false}
                  isMobile={isMobile}
                  disableMobileRedirect={true}
                  redirectUri={redirect_uri}
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
                  callback={facebookCallback}
                  render={facebookRender}
                />
              </Grid>
              <Grid
                item
                mt={1}
                xs={12}
              >
                <AppleLogin
                  clientId="io.soskills.webapp"
                  redirectURI={redirect_uri}
                  responseType={'code id_token'}
                  responseMode={'form_post'}
                  usePopup={true}
                  callback={loginApple} // Catch the response
                  scope="email name"
                  //responseMode="query"
                  render={appleRender}
                />
              </Grid>
              <Grid
                item
                mt={1}
                mb={2}
                xs={12}
                flexDirection="row"
                display="flex"
                justifyContent="flex-end"
              >
                <Typography variant="body2" display="inline">
                  {formSignUpQuestionTrad}
                </Typography>
                <Typography variant="body2" fontWeight="bold" display="inline" sx={signupTypo}>
                  <Link to="/signup" style={styles.linkTypo}>
                    {formSignUpTrad}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Snackbar
          anchorOrigin={styles.anchorOrigin}
          open={errorMessage.active}
          autoHideDuration={3000}
          message={errorMessage.msg}
          onClose={onCloseSnack}
        >
          <Alert severity="error" sx={styles.alert}>
            {errorMessage.msg}
          </Alert>
        </Snackbar>
      </Container>
    </Grid>
  );
};

export default React.memo(Login);
