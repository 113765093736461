import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useManualQuery } from 'graphql-hooks';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { REPORTS_STAT } from '../../graphql/report/query';
import { USERS_STAT } from '../../graphql/user/query';
import { VIDEOS_STAT } from '../../graphql/video/query';
import { ReportStat, UserStat, VideoStat } from '../../types';
import { isMobileAtom } from '../../atoms';
import styles from './style';

const Dashboard = React.memo(() => {
  const [isMobile] = useAtom(isMobileAtom);
  const [reportStat, setReportStat] = useState({} as ReportStat);
  const [userStat, setUserStat] = useState({} as UserStat);
  const [videoStat, setVideoStat] = useState({} as VideoStat);
  const { t } = useTranslation();

  const [reportsStat] = useManualQuery(REPORTS_STAT);
  const [usersStat] = useManualQuery(USERS_STAT);
  const [videosStat] = useManualQuery(VIDEOS_STAT);

  const videoCreatedTrad = t('videoCreated');
  const videoCreatedByDayTrad = t('videoCreatedByDay');
  const videoCreatedByWeekTrad = t('videoCreatedByWeek');
  const userEnrolledTrad = t('userEnrolled');
  const userEnrolledByDayTrad = t('userEnrolledByDay');
  const userEnrolledByWeekTrad = t('userEnrolledByWeek');
  const reportCreatedTrad = t('reportCreated');
  const reportCreatedByDayTrad = t('reportCreatedByDay');
  const reportCreatedByWeekTrad = t('reportCreatedByWeek');

  const getData = useCallback(async () => {
    const { data } = await usersStat();
    const { data: dataVideo } = await videosStat();
    const { data: dataReport } = await reportsStat();
    if (data.usersStat) {
      setUserStat(data.usersStat);
    }
    if (dataVideo.videosStat) {
      setVideoStat(dataVideo.videosStat);
    }
    if (dataReport.reportsStat) {
      setReportStat(dataReport.reportsStat);
    }
  }, [reportsStat, usersStat, videosStat]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paperMemo = useMemo(() => ({
    backgroundColor: '#88C9F3',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);
  const paperUserEnrolledByDay = useMemo(() => ({
    backgroundColor: '#88C9F3',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);
  const paperVideoCreatedByWeek = useMemo(() => ({
    backgroundColor: '#5FA7D5',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);
  const paperUserEnrolledByWeek = useMemo(() => ({
    backgroundColor: '#5FA7D5',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);
  const paperVideoCreated = useMemo(() => ({
    backgroundColor: '#2B81B8',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);
  const paperUserEnrolled = useMemo(() => ({
    backgroundColor: '#2B81B8',
    width: '95%',
    height: isMobile ? '11vh' : '9vh',
  }), [isMobile]);

  return (
    <Grid
      container
      direction="row"
      rowSpacing={2}
    >
      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperMemo}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {videoCreatedByDayTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {videoStat.videoCreatedByDay}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperUserEnrolledByDay}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {userEnrolledByDayTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {userStat.userEnrolledByDay}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {!isMobile && (
        <Grid
          item
          xs={6}
          sm={4}
          display="flex"
          justifyContent="center"
        >
          <Paper elevation={4} sx={styles.paperReportCreatedByDay}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={styles.height}
            >
              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  align="center"
                  fontWeight="bold"
                  color="white"
                >
                  {reportCreatedByDayTrad}
                </Typography>
              </Grid>

              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="Anton"
                  color="white"
                  align="center"
                >
                  {reportStat.reportCreatedByDay}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperVideoCreatedByWeek}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {videoCreatedByWeekTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {videoStat.videoCreatedByWeek}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperUserEnrolledByWeek}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {userEnrolledByWeekTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {userStat.userEnrolledByWeek}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {!isMobile && (
        <Grid
          item
          xs={6}
          sm={4}
          display="flex"
          justifyContent="center"
        >
          <Paper elevation={4} sx={styles.paperReportCreatedByWeek}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={styles.height}
            >
              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  align="center"
                  fontWeight="bold"
                  color="white"
                >
                  {reportCreatedByWeekTrad}
                </Typography>
              </Grid>

              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="Anton"
                  color="white"
                  align="center"
                >
                  {reportStat.reportCreatedByWeek}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperVideoCreated}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {videoCreatedTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {videoStat.videoCreated}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sm={4}
        display="flex"
        justifyContent="center"
      >
        <Paper elevation={4} sx={paperUserEnrolled}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.height}
          >
            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                align="center"
                fontWeight="bold"
                color="white"
              >
                {userEnrolledTrad}
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="Anton"
                color="white"
                align="center"
              >
                {userStat.userEnrolled}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {!isMobile && (
        <Grid
          item
          xs={6}
          sm={4}
          display="flex"
          justifyContent="center"
        >
          <Paper elevation={4} sx={styles.paperReportCreated}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={styles.height}
            >
              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  align="center"
                  fontWeight="bold"
                  color="white"
                >
                  {reportCreatedTrad}
                </Typography>
              </Grid>

              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="Anton"
                  color="white"
                  align="center"
                >
                  {reportStat.reportCreated}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

    </Grid>
  )
});

export default Dashboard;
