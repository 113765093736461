import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { isMobileAtom } from '../../atoms';
import styles from './style';

const About = React.memo(() => {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);

  const nextAboutTrad = t('nextAbout');
  const personalIntroTrad = t('personalIntro');
  const personalSpeakerTrad = t('personalSpeaker');
  const introAboutTrad = t('introAbout');
  const introWebsiteTrad = t('introWebSite').replace('soskills.io', window.location.hostname);
  const addVideoWebsiteLuTrad = t('addVideoWebsiteLu');
  const editVideoWebsiteLuTrad = t('editVideoWebsiteLu');
  const introAppTrad = t('introApp');
  const viewVideosCommunityLuTrad = t('viewVideosCommunityLu');
  const filterLuTrad = t('filterLu');
  const subscribeLuTrad = t('subscribeLu');
  const searchUsersLuTrad = t('searchUsersLu');
  const likeCommentLuTrad = t('likeCommentLu');
  const rankingLuTrad = t('rankingLu');
  const ambitionLuTrad = t('ambitionLu');

  return (
    <Container
      maxWidth="md"
      disableGutters={isMobile}
      sx={styles.container}
    >
      <Grid
        container
        alignItems="center"
        pt={5}
      >
        <Grid container flexDirection="row" >
          <Grid
            pb={4}
            item
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="h2" color="#212121" sx={styles.title}>
              SoSkills
            </Typography>
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="center" pb={5}>
          <Grid item xs={12}>
            <Typography
              variant='body2'
              sx={styles.lineHeight}
              textAlign={'justify'}
              color="white"
            >
              {personalIntroTrad}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} mb={2}>
            <Typography
              variant='body2'
              fontStyle="italic"
              color="white"
              sx={styles.lineHeight}
              textAlign={'justify'}
            >
              “ {personalSpeakerTrad} “
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" mb={1}>
            <Typography
              variant="body2"
              color="white"
            >
              ***
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="body2"
              color="white"
              sx={styles.lineHeight}
              textAlign={'justify'}
            >
              {introAboutTrad}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography
              variant="body2"
              color="white"
              textAlign="justify"
              sx={styles.lineHeight}
            >
              {introWebsiteTrad}
            </Typography>
            <ul style={styles.margin}>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {addVideoWebsiteLuTrad}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {editVideoWebsiteLuTrad}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={styles.lineHeight}
              textAlign={'justify'}
              color="white"
            >
              {introAppTrad}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={styles.margin}>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {viewVideosCommunityLuTrad}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {filterLuTrad}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {subscribeLuTrad}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {searchUsersLuTrad}
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  {likeCommentLuTrad}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {rankingLuTrad}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} mt={1} sx={styles.lineHeight}>
            <Typography
              variant="body2"
              color="white"
            >
              {ambitionLuTrad}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1} sx={styles.lineHeight}>
            <Typography
              variant="body2"
              color="white"
            >
              {nextAboutTrad}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container >
  );
});

export default About;
